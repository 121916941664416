import { State } from './reducers/rootReducer'
import { defaultState as pendingRequestsDefaultState } from './reducers/pendingRequestsReducer'

export const STORAGE = window.sessionStorage
export const STORAGE_NAME = 'micon-redux-state'

export const setPersistedState = (state: State): void => {
    STORAGE.setItem(STORAGE_NAME, JSON.stringify(state))
}

// must return undefined instead of null
// otherwise the redux store throws an error on init
export const getPersistedState = (): State | undefined => {
    const persistedState = STORAGE.getItem(STORAGE_NAME)

    if (persistedState) {
        const parsedPersistedState = JSON.parse(persistedState)

        delete parsedPersistedState.router
        delete parsedPersistedState.trackingIsReady
        delete parsedPersistedState.languageIsLoaded

        return ({
            ...parsedPersistedState,
            pendingRequests: pendingRequestsDefaultState,
        })
    }

    return undefined
}
