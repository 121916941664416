import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import VehicleCode from '../../entities/VehicleCode'
import Modal, { ModalClosingButton } from '../modal'
import style from './vehicleCodeModal.css'
import { Spinner } from '../../base-components/spinner'

export type Props = {
    vehicleCode: VehicleCode
    openModalState: [boolean, Function]
    isLoading: boolean
    brand: string
}

const VehicleCodeModal: FunctionComponent<Props> = (props) => {
    const {
        vehicleCode,
        openModalState,
        isLoading,
        brand,
    } = props
    const { t } = useTranslation()
    const [modalIsOpen, setModalIsOpen] = openModalState

    if (!modalIsOpen) {
        return null
    }

    const closeHandler = (): void => {
        setModalIsOpen(false)
    }

    const vehicleCodeOrSpinner = isLoading
        ? (
            <div className={style.spinnerContainer}>
                <Spinner />
            </div>
        )
        : (
            <p className={style.vehicleCode}>
                {vehicleCode}
            </p>
        )

    return (
        <Modal onClose={closeHandler}>
            <ModalClosingButton onClick={closeHandler} />
            <section className={style.container}>
                <h2 className={style.headline}>{t(`vehicleCode.${brand}.create.title`)}</h2>
                {vehicleCodeOrSpinner}
            </section>
        </Modal>
    )
}

export default VehicleCodeModal
