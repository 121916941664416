import { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useOnMount from '../../hooks/useOnMount'

export type Props = RouteComponentProps<{ pr3: string }> & {
    initFromFastConfigurationEntryRoute: () => void
}

const FastConfigurationEntryPage: FunctionComponent<Props> = (props) => {
    const { initFromFastConfigurationEntryRoute } = props

    useOnMount(() => {
        initFromFastConfigurationEntryRoute()
    })

    return null
}

export default FastConfigurationEntryPage
