import { createSelector } from 'reselect'
import FilterOption from '../../../entities/FilterOption'
import modelsSelector from './modelsSelector'
import modelFilterListSelector from './modelFilterListSelector'
import filtersSelector from '../filtersSelector'

const modelFilterOptionsSelector = createSelector(
    modelFilterListSelector,
    modelsSelector,
    filtersSelector,
    (filterList, models, appliedFilters): FilterOption[] => {
        if (!filterList) {
            return []
        }

        const filterOptions = filterList
            .map((filter) => {
                const values = new Set()

                models.forEach((model) => {
                    const modelAttribute = model.attributes
                        .find((attribute) => attribute.id === filter.attribute)

                    if (modelAttribute) {
                        values.add(modelAttribute.value)
                    }
                })

                return {
                    attribute: filter.attribute,
                    type: filter.type,
                    values: Array.from(values) as string[],
                    activeValues: appliedFilters[filter.attribute] || [],
                }
            })
            .filter((filterOption) => filterOption.values.length > 0)

        return filterOptions
    },
)

export default modelFilterOptionsSelector
