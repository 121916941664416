import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'
import EquipmentGroup from '../../entities/EquipmentGroup'
import Cartbox from '../cartbox'
import EquipmentFamiliesTables from '../equipment-families-tables'
import useScrollToElement from '../../hooks/useScrollToElement'
import TextFilter from '../equipment-filter'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import AnchorNavigation from '../anchor-navigation'
import AnchorNavigationContext from '../anchor-navigation/anchorNavigationContext'
import useVisibleAnchorContext from '../../hooks/useVisibleAnchorContext'
import style from './equipmentSelection.css'
import LockIcon from '../../assets/media/lock.svg'

export type Props = {
    equipmentGroup: EquipmentGroup | undefined
    setTextFilter: (textToFilter: string) => void
    isLoading: boolean
}

const EquipmentSelection: FunctionComponent<Props> = (props) => {
    const {
        equipmentGroup,
        setTextFilter,
        isLoading,
    } = props
    const { t } = useTranslation()

    useOnMount(() => {
        setTextFilter('')
    })

    useScrollToElement()

    const visibleAnchorContextState = useVisibleAnchorContext()

    if (!equipmentGroup || !equipmentGroup.equipmentFamilies) {
        return null
    }

    const anchorNavigationLabel = t('equipmentRange.selectLabel')
    const anchorNavigationOptions = equipmentGroup.equipmentFamilies.map((equipmentFamily) => ({
        hashtag: equipmentFamily.id,
        title: equipmentFamily.name,
    }))

    return (
        <div className="contentWithCartboxContainer">
            <div className="contentWithCartbox">
                <h1>{t(`navigation.${equipmentGroup.id}`)}</h1>
                <LoadingIndicatorWrapper isLoading={isLoading}>
                    <AnchorNavigationContext.Provider value={visibleAnchorContextState}>
                        <div className="filterBar">
                            <AnchorNavigation
                                options={anchorNavigationOptions}
                                label={anchorNavigationLabel}
                            />
                            <TextFilter setFilter={setTextFilter} />
                        </div>
                        <p className={style.disclaimer}>
                            <small>
                                {t('equipmentSelection.mandatoryDisclaimer')}
                            </small>
                            <LockIcon />
                        </p>
                        <EquipmentFamiliesTables
                            equipmentFamilies={equipmentGroup.equipmentFamilies}
                        />
                    </AnchorNavigationContext.Provider>
                </LoadingIndicatorWrapper>
            </div>
            <Cartbox />
        </div>
    )
}

export default EquipmentSelection
