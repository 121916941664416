import { Middleware } from 'redux'
import { Action, getType, isActionOf } from 'typesafe-actions'
import { apiFailure, apiRequest } from '../../actions/core/api.actions'
import { createNotification } from '../../actions/app/notification.actions'
import { fetchEntryDataAsync } from '../../actions/app/entryData.actions'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'
import { loadConfigurationByVehicleCodeAsync } from '../../actions/app/vehicleCode.actions'
import {
    addFailedRequestActionToState,
    removeFailedRequestActionFromState,
} from '../../actions/app/failedRequests.actions'
import failedRequestsSelector from '../../selectors/failedRequestsSelector'
import { fetchCarlineDataAsync } from '../../actions/app/carlineData.actions'
import { fetchEfficiencyDataAsync } from '../../actions/app/efficiencyData.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import {
    postFastConfigurationWithOrderKeyAsync,
    putFastConfigurationAsync,
} from '../../actions/app/configuration.actions'

const errorMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    const getNotificationMessage = (type: string, message: string, causedBy: Action): string => {
        if (message === 'SESSION_EXPIRED') {
            return 'error.sessionExpired'
        }

        if (causedBy.type === getType(loadConfigurationByVehicleCodeAsync.request)) {
            if (message === 'VEHICLE_CODE_NOT_FOUND') {
                return 'vehicleCode.audi.notFound'
            }

            if (message === 'VEHICLE_CODE_NOT_VALID') {
                return 'vehicleCode.audi.notValid'
            }
        }

        if (causedBy.type === getType(putFastConfigurationAsync.request)
            || causedBy.type === getType(postFastConfigurationWithOrderKeyAsync.request)) {
            if (message === 'CONFIGURATION_NOT_BUILDABLE') {
                return 'configuration.notBuildable'
            }
        }

        if (type === 'fetch') {
            return 'error.fetch'
        }

        return 'error.general'
    }

    if (isActionOf(apiRequest, action)) {
        const { getState, dispatch } = store
        const { causedBy } = action.meta
        const failedRequests = failedRequestsSelector(getState())

        if (failedRequests.some((failedRequest) => failedRequest.type === causedBy.type)) {
            dispatch(removeFailedRequestActionFromState(causedBy))
        }
    }

    if (isActionOf(apiFailure, action)) {
        const { dispatch } = store
        const { type, error } = action.payload
        const { causedBy } = action.meta

        dispatch(createNotification({
            type: 'error',
            message: getNotificationMessage(type, error.message, causedBy),
        }))

        if (error.message === 'SESSION_EXPIRED') {
            dispatch(resetConfigurationProcess())
            dispatch(fetchEntryDataAsync.request(undefined, undefined))
        }

        const requestsToRetry: string[] = [
            fetchEntryDataAsync.request,
            fetchCarlineDataAsync.request,
            fetchEfficiencyDataAsync.request,
            fetchVisualizationAsync.request,
            fetchCartAsync.request,
            fetchEquipmentGroupsAsync.request,
        ].map((requestToRetry) => getType(requestToRetry))

        if (requestsToRetry.includes(causedBy.type)) {
            dispatch(addFailedRequestActionToState(action.meta.causedBy))
        }
    }
}

export default errorMiddleware
