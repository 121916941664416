import { createSelector } from 'reselect'
import selectedEquipmentGroupsSelector from './selectedEquipmentGroupsSelector'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import filterEquipmentsOfGroups from './helpers/filterEquipmentsOfGroups'

const overviewEquipmentGroupsSelector = createSelector(
    selectedEquipmentGroupsSelector,
    (selectedEquipmentGroups): EquipmentGroup[] => filterEquipmentsOfGroups(
        selectedEquipmentGroups,
        (equipment) => equipment.isStandard || equipment.replacedBy == null,
    ),
)

export default overviewEquipmentGroupsSelector
