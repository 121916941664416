import { createSelector } from 'reselect'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import configurationDataSelector from './configuration/configurationDataSelector'

const conflictItemsSelector = createSelector(
    configurationDataSelector,
    (configurationData): ConflictItem[] => {
        if (
            configurationData.conflictInformation === undefined
            || configurationData.conflictInformation.conflictSteps.length === 0
        ) {
            return []
        }

        const { conflictItems } = configurationData.conflictInformation.conflictSteps[0]

        return conflictItems.map((conflictItem) => ({
            ...conflictItem,
            equipment: {
                ...conflictItem.equipment,
                selected: conflictItem.conflictType === 'REMOVE',
                selectable: true,
            },
        }))
    },
)

export default conflictItemsSelector
