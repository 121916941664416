import { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useOnMount from '../../hooks/useOnMount'

export type Props = RouteComponentProps<{ context: string }> & {
    initFromEntryRoute: (context: string) => void
}

const EntryPage: FunctionComponent<Props> = (props) => {
    const { initFromEntryRoute, match } = props

    useOnMount(() => {
        const { context } = match.params

        initFromEntryRoute(context)
    })

    return null
}

export default EntryPage
