import { createAction, createAsyncAction } from 'typesafe-actions'
import EntryData from '../../../entities/EntryData'

export const fetchEntryDataAsync = createAsyncAction(
    'CMD / EntryData / fetch request',
    'EVT / EntryData / fetch success',
    'EVT / EntryData / fetch failure',
)<undefined, EntryData, Error>()

export const setEntryDataState = createAction('DOC / EntryData / set state')<EntryData>()

export const resetEntryDataState = createAction('DOC / EntryData / reset state')()
