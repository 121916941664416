import React, { FunctionComponent, ReactElement } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './priceModeSwitch.css'
import { PriceMode } from '../../redux/actions/app/priceMode.actions'
import { PurchaseMode } from '../../redux/actions/app/purchaseMode.actions'

export type Props = {
    availablePriceModes: PriceMode[]
    changePriceMode: (priceMode: PriceMode) => void
    priceMode: PriceMode
    purchaseMode: PurchaseMode
}

const PriceModeSwitch: FunctionComponent<Props> = (props) => {
    const {
        availablePriceModes,
        changePriceMode,
        priceMode,
        purchaseMode,
    } = props
    const { t } = useTranslation()

    if (availablePriceModes.length <= 1) {
        return null
    }

    const createButtonElement = (buttonPriceMode: PriceMode): ReactElement => {
        const isDisabled = priceMode === buttonPriceMode
        const label = t(`priceModeSwitch.${purchaseMode}.${buttonPriceMode}`)

        const clickHandler = (): void => {
            changePriceMode(buttonPriceMode)
        }

        return (
            <button
                className={style.button}
                disabled={isDisabled}
                onClick={clickHandler}
                type="button"
                key={buttonPriceMode}
            >
                {label}
            </button>
        )
    }

    const buttons = availablePriceModes.map(createButtonElement)

    return (
        <label className={style.container}>
            {buttons}
        </label>
    )
}

export default PriceModeSwitch
