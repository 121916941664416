import React, { FunctionComponent } from 'react'
import Equipment from '../../entities/Equipment'
import style from './foresightedBuildabilityTriggerEquipment.css'

export type Props = {
    equipment: Equipment | null
}

const ForesightedBuildabilityTriggerEquipment: FunctionComponent<Props> = (props) => {
    const { equipment } = props

    if (equipment == null) {
        return null
    }

    const { name, price } = equipment

    return (
        <div className={style.container}>
            <span>{name}</span>
            <span className={style.price}>{price}</span>
        </div>
    )
}

export default ForesightedBuildabilityTriggerEquipment
