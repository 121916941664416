import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import {
    fetchTechnicalDataAsync,
    resetTechnicalDataState,
    setTechnicalDataState,
} from '../../actions/app/technicalData.actions'
import configurationStringSelector from '../../selectors/configuration/configurationStringSelector'
import { getTechnicalDataApiUrl } from '../../../constants/apiUrls'

const technicalDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchTechnicalDataAsync.request, action)) {
        const configurationString = action.payload === undefined
            ? configurationStringSelector(getState())
            : action.payload

        dispatch(resetTechnicalDataState())
        dispatch(apiRequest({
            url: getTechnicalDataApiUrl(configurationString),
            method: 'GET',
            successAction: fetchTechnicalDataAsync.success,
            failureAction: fetchTechnicalDataAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchTechnicalDataAsync.success, action)) {
        const technicalData = action.payload

        dispatch(setTechnicalDataState(technicalData))
    }
}

export default technicalDataMiddleware
