import { createSelector } from 'reselect'
import FilterOption from '../../../entities/FilterOption'
import carlinesSelector from './carlinesSelector'
import carlineFilterListSelector from './carlineFilterListSelector'
import filtersSelector from '../filtersSelector'

const carlineFilterOptionsSelector = createSelector(
    carlineFilterListSelector,
    carlinesSelector,
    filtersSelector,
    (filterList, carlines, appliedFilters): FilterOption[] => {
        const filterOptions = filterList
            .map((filter) => {
                const values = carlines.reduce((accumulator, carline) => {
                    const carlineAttributes = carline.attributes[filter.attribute]

                    if (carlineAttributes === undefined) {
                        return accumulator
                    }

                    carlineAttributes.forEach((carlineAttribute) => {
                        if (
                            carlineAttribute !== undefined
                            && !accumulator.includes(carlineAttribute)
                        ) {
                            accumulator.push(carlineAttribute)
                        }
                    })

                    return accumulator
                }, [] as (string | number)[])

                return {
                    attribute: filter.attribute,
                    type: filter.type,
                    values,
                    activeValues: appliedFilters[filter.attribute] || [],
                }
            })
            .filter((filterOption) => filterOption.values.length > 0)

        return filterOptions
    },
)

export default carlineFilterOptionsSelector
