import {
    ChangeEventHandler,
    Dispatch,
    SetStateAction,
    useState,
} from 'react'

const useFormInput = (initialValue: string): [
    string,
    ChangeEventHandler<HTMLInputElement>,
    Dispatch<SetStateAction<string>>,
] => {
    const [value, setValue] = useState<string>(initialValue)

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(event.target.value)
    }

    return [
        value,
        handleChange,
        setValue,
    ]
}

export default useFormInput
