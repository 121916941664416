import { createAction, createAsyncAction } from 'typesafe-actions'
import CheckoutPayload from '../../../entities/CheckoutPayload'

export const fetchCheckoutPayloadAsync = createAsyncAction(
    'EVT / CheckoutPayload / fetch request',
    'EVT / CheckoutPayload / fetch success',
    'EVT / CheckoutPayload / fetch failure',
)<undefined, CheckoutPayload, Error>()

export const setCheckoutPayloadState = createAction('DOC / CheckoutPayload / set state')<CheckoutPayload>()
