import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import initRouterParamsSelector, { Params } from '../../selectors/initRouterParamsSelector'
import initParametersSelector from '../../selectors/initParametersSelector'
import { setInitParametersToState } from '../../actions/app/initParameters.actions'
import { fetchEntryDataAsync, resetEntryDataState } from '../../actions/app/entryData.actions'
import currentPathnameSelector from '../../selectors/router/currentPathnameSelector'
import { ROUTE_CARLINES, ROUTE_CHECK } from '../../../constants/routes'
import { resetCartState } from '../../actions/app/cart.actions'
import { resetCarlineSelectionState } from '../../actions/app/carlineSelection.actions'
import { resetModelDataState } from '../../actions/app/models.actions'
import { resetConfigurationDataState } from '../../actions/app/configuration.actions'
import { resetEquipmentGroupsState } from '../../actions/app/equipmentGroups.actions'
import { resetFilters } from '../../actions/app/filter.actions'
import {
    init,
    initFromEntryRoute,
    initFromFastConfigurationEntryRoute,
} from '../../actions/core/init.actions'
import { createNotification, resetNotificationsState } from '../../actions/app/notification.actions'
import i18n from '../../../i18n/i18n'
import { vehicleCodeChanged } from '../../actions/app/vehicleCode.actions'
import failedRequestsSelector from '../../selectors/failedRequestsSelector'
import { applyBrand } from '../../actions/app/brand.actions'

const entryContextToInitParamMap = {
    leasing: {
        contextId: 'accx-de-mfc:de',
    },
    kauf: {
        contextId: 'accx-de-mfc-kauf:de',
    },
    fkt: {
        contextId: 'accx-de-fkt:de',
    },
    pdw: {
        contextId: 'accx-de-pdw:de',
    },
    zweit_pdw: {
        contextId: 'accx-de-pdw:de',
        zweit_pdw: true,
    },
}

const mapRouterParamsToInitParams = (routerParams: Params): { features: string; betrnr: string; car_group: string; vehicleCode: string; return_url: any; contextId: string; zweit_pdf: string; zweit_pdw: boolean; token: string } => ({
    contextId: routerParams.mandant ?? routerParams.context,
    token: routerParams.id_token,
    zweit_pdf: routerParams.zweit_pdf,
    car_group: routerParams.agis_fnr,
    features: routerParams.features,
    vehicleCode: routerParams.vehicle_code,
    betrnr: routerParams.betrnr,
    zweit_pdw: routerParams.zweit_pdw,
    return_url: routerParams.return_url || routerParams.agis_return_url,
})

const initMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(init, action)) {
        const { getState, dispatch } = store
        const failedRequests = failedRequestsSelector(getState())

        failedRequests.forEach((failedRequest) => {
            dispatch(failedRequest)
        })

        dispatch(applyBrand())
        dispatch(resetNotificationsState())

        const initRouterParams = initRouterParamsSelector(getState())
        const initStateParams = initParametersSelector(getState())
        const currentPathname = currentPathnameSelector(getState())

        if (currentPathname === ROUTE_CHECK) {
            return
        }

        if (!initRouterParams.context && !initStateParams.contextId) {
            return
        }

        if (initRouterParams.context && !initStateParams.contextId) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(setInitParametersToState(initParameters))
            dispatch(fetchEntryDataAsync.request(undefined, undefined))

            return
        }

        if (initStateParams.vehicleCode !== initRouterParams.vehicle_code) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(setInitParametersToState(initParameters))
            dispatch(vehicleCodeChanged())
        }

        if (!initRouterParams.context && initStateParams.contextId) {
            return
        }

        if (initStateParams.contextId !== initRouterParams.context) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(resetEntryDataState())
            dispatch(resetCartState())
            dispatch(resetCarlineSelectionState())
            dispatch(resetModelDataState())
            dispatch(resetConfigurationDataState())
            dispatch(resetEquipmentGroupsState())
            dispatch(resetFilters())
            dispatch(setInitParametersToState(initParameters))

            dispatch(fetchEntryDataAsync.request(undefined, undefined))

            dispatch(createNotification({
                type: 'info',
                message: i18n.t('notifications.context-changed'),
            }))
        }
    }

    if (isActionOf(initFromEntryRoute, action)) {
        const { dispatch, getState } = store
        const initRouterParams = initRouterParamsSelector(getState())
        const intiParamsByEntryContext = entryContextToInitParamMap[action.payload]

        if (intiParamsByEntryContext === undefined) {
            // eslint-disable-next-line no-alert
            alert('No valid entry parameter provided')
            return
        }

        const initParameters = {
            ...mapRouterParamsToInitParams(initRouterParams),
            ...intiParamsByEntryContext,
        }

        dispatch(setInitParametersToState(initParameters))
        dispatch(fetchEntryDataAsync.request(undefined, undefined))
        dispatch(push(ROUTE_CARLINES))
    }

    if (isActionOf(initFromFastConfigurationEntryRoute, action)) {
        const {
            getState,
            dispatch,
        } = store
        const initRouterParams = initRouterParamsSelector(getState())

        const initParameters = {
            ...mapRouterParamsToInitParams(initRouterParams),
            prString: initRouterParams.pr3,
        }

        dispatch(setInitParametersToState(initParameters))
        dispatch(fetchEntryDataAsync.request(undefined, undefined))
    }
}

export default initMiddleware
