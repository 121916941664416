import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'
import { resetCartState } from '../../actions/app/cart.actions'
import { resetCarlineSelectionState } from '../../actions/app/carlineSelection.actions'
import { resetModelDataState } from '../../actions/app/models.actions'
import { resetConfigurationDataState } from '../../actions/app/configuration.actions'
import { resetEquipmentGroupsState } from '../../actions/app/equipmentGroups.actions'
import { resetFilters } from '../../actions/app/filter.actions'
import {resetLeasingState} from '../../actions/app/leasing.actions'

const resetMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(resetConfigurationProcess, action)) {
        const { dispatch } = store

        dispatch(resetCartState())
        dispatch(resetCarlineSelectionState())
        dispatch(resetModelDataState())
        dispatch(resetConfigurationDataState())
        dispatch(resetEquipmentGroupsState())
        dispatch(resetFilters())
        dispatch(resetLeasingState())
        dispatch(push('/'))
    }
}

export default resetMiddleware
