import { createSelector } from 'reselect'
import configurationDataSelector from './configurationDataSelector'

const foresightedBuildabilityIsResolvableSelector = createSelector(
    configurationDataSelector,
    (configurationData): boolean => {
        if (
            configurationData.conflictInformation == null
            || configurationData.conflictInformation.hiddenFields == null
        ) {
            return false
        }

        return !configurationData.conflictInformation.hiddenFields.includes('CANCEL')
    },
)

export default foresightedBuildabilityIsResolvableSelector
