import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Notification from '../../entities/Notification'
import CancelIcon from '../../assets/media/cancel.svg'
import style from './notificationList.css'

export type Props = {
    notifications: Notification[]
    resetNotifications: Function
    removeNotification: (notification: Notification) => void
}

const NotificationList: FunctionComponent<Props> = (props) => {
    const {
        notifications,
        removeNotification,
    } = props
    const { i18n, t } = useTranslation()

    if (notifications.length === 0) {
        return null
    }

    const listElements = notifications.map((notification) => {
        const { type, message, closing } = notification
        const listElementClassName = `${style.listItem} ${closing ? style.listItemClosing : ''}`
        const wrapperClassName = `${style.wrapper} ${style[`wrapper-${type}`]}`

        const clickHandler = (): void => {
            removeNotification(notification)
        }

        const translatedMessage = i18n.exists(message)
            ? t(message)
            : message

        return (
            <li key={message} className={listElementClassName}>
                <div className={wrapperClassName}>
                    <p className={style.message}>{translatedMessage}</p>
                    <button
                        onClick={clickHandler}
                        className={style.button}
                        type="button"
                    >
                        <CancelIcon className={style.icon} />
                    </button>
                </div>
            </li>
        )
    })

    return (
        <div className={style.container}>
            <ul className={style.list}>
                {listElements}
            </ul>
        </div>
    )
}

export default NotificationList
