import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import {
    fetchSummaryAsync,
    resetSummaryState,
    setSummaryState,
} from '../../actions/app/summary.actions'
import { getConfigurationSummaryApiUrl } from '../../../constants/apiUrls'

const summaryMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchSummaryAsync.request, action)) {
        dispatch(apiRequest({
            url: getConfigurationSummaryApiUrl(),
            method: 'GET',
            successAction: fetchSummaryAsync.success,
            failureAction: fetchSummaryAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchSummaryAsync.success, action)) {
        const summary = action.payload

        dispatch(setSummaryState(summary))
    }

    if (isActionOf(fetchSummaryAsync.failure, action)) {
        dispatch(resetSummaryState())
    }
}

export default summaryMiddleware
