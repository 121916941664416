import { useQuery } from '@tanstack/react-query'
import { apiClient } from '.'
import { FullConfigurationResponse } from './Api'

export const useGetConfiguration = (code: string) => {
    const {
        data,
        error,
    } = useQuery<FullConfigurationResponse>({
        enabled: !!code,
        queryKey: ['configuration', code],
        queryFn: async () => {
            const result = await apiClient.configuration.getConfiguration(code)

            if (!result.ok) {
                throw new Error(result.statusText)
            }

            return result.data
        },
    })

    return {
        data,
        error,
    }
}
