import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { stringify } from 'query-string'
import { fetchEntryDataAsync, setEntryDataState } from '../../actions/app/entryData.actions'
import { apiRequest } from '../../actions/core/api.actions'
import { getEntryApiUrl } from '../../../constants/apiUrls'
import initParametersSelector from '../../selectors/initParametersSelector'
import { fetchCarlineDataAsync } from '../../actions/app/carlineData.actions'
import { applyBrand } from '../../actions/app/brand.actions'

const entryDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchEntryDataAsync.request, action)) {
        const state = getState()
        const params = initParametersSelector(state)
        const paramsWithoutContextAndFeatures = {
            ...params,
        }

        delete paramsWithoutContextAndFeatures.contextId
        delete paramsWithoutContextAndFeatures.features

        const requestParams = stringify(paramsWithoutContextAndFeatures, {
            skipNull: true,
        })

        dispatch(apiRequest({
            url: getEntryApiUrl(params.contextId, requestParams),
            method: 'GET',
            successAction: fetchEntryDataAsync.success,
            failureAction: fetchEntryDataAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchEntryDataAsync.success, action)) {
        dispatch(setEntryDataState(action.payload))
        dispatch(applyBrand())
        dispatch(fetchCarlineDataAsync.request(undefined, undefined))
    }
}

export default entryDataMiddleware
