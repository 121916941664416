import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Checkout'
import checkoutIsEnabledSelector from '../../redux/selectors/checkoutIsEnabledSelector'
import { fetchCheckoutPayloadAsync } from '../../redux/actions/app/checkoutPayload.actions'
import checkoutPayloadSelector from '../../redux/selectors/checkoutPayloadSelector'

type StateProps = Pick<Props, 'checkoutIsEnabled' | 'checkoutPayload'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    checkoutIsEnabled: checkoutIsEnabledSelector(state),
    checkoutPayload: checkoutPayloadSelector(state),
})

type DispatchProps = Pick<Props, 'fetchCheckoutData'>

const mapDispatchToProps: DispatchProps = {
    fetchCheckoutData: fetchCheckoutPayloadAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
