import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import { ROUTE_CARLINES } from '../../constants/routes'
import ArrowLeftIcon from '../../assets/media/arrow-left.svg'
import style from './notFound.css'

const NotFound: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <article>
            <h1>404 - Page Not Found</h1>
            <p>
                <Link to={ROUTE_CARLINES} className={style.homeLink}>
                    <ArrowLeftIcon />
                    {t('homeButtonLabel')}
                </Link>
            </p>
        </article>
    )
}

export default NotFound
