import { connect, MapStateToProps } from 'react-redux'
import { deselectEquipment, selectEquipment } from '../../redux/actions/app/equipmentGroups.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'
import { Props } from './EquipmentTable'
import equipmentInformationSelector from '../../redux/selectors/equipments/equipmentInformationSelector'

type StateProps = Pick<Props, 'equipmentInformation'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    equipmentInformation: equipmentInformationSelector(state),
})

type DispatchProps = Pick<Props, 'selectEquipment' | 'deselectEquipment' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    selectEquipment,
    deselectEquipment,
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
