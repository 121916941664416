import { Markup } from 'interweave'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { GroupedEntry } from '../../../api'
import useTranslation from '../../../hooks/useTranslation'
import { Table, Td, Tr } from '../../table'

export type Props = {
    enties: GroupedEntry[]
    isStandard?: boolean
}

const Row = styled(Tr)`
    display: flex;
    align-items: space-between;
`

const NameColumn = styled(Td)`
    flex: 1;
    padding: var(--space-xs);
`

const PriceColumn = styled(Td)`
    text-align: right;
    align-items: center;
    white-space: nowrap;
    padding: var(--space-xs);
`

const IdMarkup = styled(Markup)`
    margin-left: var(--space-xs);
    color: var(--color-grey-50);
    font-family: var(--audi-type-wide);
    font-weight: 200;
    font-size: .75em;
    text-align: right;
`

export const EquipmentsTable: FunctionComponent<Props> = (props) => {
    const { enties = [], isStandard } = props
    const { t } = useTranslation()

    const rows = enties.map((entry) => {
        const { id, name, price } = entry

        const priceElement = isStandard ? t('standard-equipment-price') : <Markup content={price} />

        return (
            <Row key={id}>
                <NameColumn>
                    <Markup tagName="span" content={name.join(', ')} />
                    <IdMarkup tagName="span" content={id}/>
                </NameColumn>
                <PriceColumn>{priceElement}</PriceColumn>
            </Row>
        )
    })

    return (
        <Table>
            <tbody>{rows}</tbody>
        </Table>
    )
}
