import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { createVehicleCodeAsync } from '../../actions/app/vehicleCode.actions'
import {
    createPdf, pdfCreationFailed, pdfGotConfiguration, pdfGotVehicleCode,
} from '../../actions/app/pdf.actions'
import { getConfigurationByVehicleCode } from '../../actions/app/configuration.actions'
import { createNotification } from '../../actions/app/notification.actions'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'
import config from '../../../config'

import getTranslatedConfiguration from './helpers/getTranslatedConfiguration'
import getApiOptions from '../../../getApiOptions'

const pdfMiddleware = (
    networkRequest: typeof fetch,
    console: Console,
): Middleware => (store) => (next) => (action) => { /* eslint-disable-line consistent-return */
    next(action)
    const { dispatch } = store
    const pdfServiceUrl = config.PDF_SERVICE_URL

    if (isActionOf(createPdf, action)) {
        dispatch(createVehicleCodeAsync.request(
            undefined,
            {
                onSuccess: pdfGotVehicleCode,
                onFailure: pdfCreationFailed,
            },
        ))

        dispatch(addPendingRequest(pdfServiceUrl, { causedBy: createPdf() }))
    }

    if (isActionOf(pdfGotVehicleCode, action)) {
        const { vehicleCode } = action.payload

        dispatch(getConfigurationByVehicleCode.request(vehicleCode, {
            onSuccess: pdfGotConfiguration,
            onFailure: pdfCreationFailed,
        }))
    }

    if (isActionOf(pdfGotConfiguration, action)) {
        const configuration = action.payload

        const translatedConfiguration = getTranslatedConfiguration(configuration)

        const handleError = (type) => (error) => {
            dispatch(pdfCreationFailed({
                failureAction: pdfCreationFailed,
                error,
                type,
                url: pdfServiceUrl,
            }, {
                causedBy: action,
            }))
        }

        const handleResponse = (response: Response): void => {
            response.blob()
                .then((resBlob) => {
                    const pdfBlob = new Blob([resBlob], { type: 'application/pdf' })
                    const objUrl = URL.createObjectURL(pdfBlob)
                    const link = document.createElement('a')

                    link.href = objUrl

                    link.download = `${configuration.audiCodeSection.audiCode}.pdf`
                    link.click()
                    setTimeout(() => {
                        window.URL.revokeObjectURL(objUrl)
                    }, 250)

                    dispatch(removePendingRequest(pdfServiceUrl, { causedBy: createPdf() }))
                })
                .catch((e) => {
                    console.error(e)

                    const error = new Error(response.statusText)

                    handleError('response')(error)
                })
        }

        const options: RequestInit = getApiOptions({
            method: 'POST',
            headers: [],
            body: JSON.stringify(translatedConfiguration),
        })

        return networkRequest(encodeURI(pdfServiceUrl), options)
            .then(handleResponse)
            .catch(handleError('fetch'))
    }

    if (isActionOf(pdfCreationFailed, action)) {
        dispatch(removePendingRequest(pdfServiceUrl, { causedBy: createPdf() }))

        console.error(action.payload)

        dispatch(createNotification({
            type: 'error',
            message: 'error.pdf',
        }))
    }
}

export default pdfMiddleware
