import { createReducer } from 'typesafe-actions'
import {
    setConfiguredLeasingRate,
    setLeasingModalIsActiveState,
    setLeasingModalShouldBeShownState,
    setLeasingMatrixState,
    resetLeasingState,
} from '../actions/app/leasing.actions'
import LeasingMatrix from '../../entities/LeasingMatrix'
import LeasingRate from '../../entities/LeasingRate'

export type LeasingState = {
    modalShouldBeShown: boolean
    modalIsActive: boolean
    matrix: LeasingMatrix
    configuredRate: LeasingRate | null
}

export const defaultState: LeasingState = {
    modalShouldBeShown: false,
    modalIsActive: false,
    matrix: [],
    configuredRate: null,
}

const leasingReducer = createReducer(defaultState)
    .handleAction(setLeasingModalShouldBeShownState, (state, action): LeasingState => ({
        ...state,
        modalShouldBeShown: action.payload,
    }))
    .handleAction(setLeasingMatrixState, (state, action): LeasingState => ({
        ...state,
        matrix: action.payload,
    }))
    .handleAction(setLeasingModalIsActiveState, (state, action): LeasingState => ({
        ...state,
        modalIsActive: action.payload,
    }))
    .handleAction(setConfiguredLeasingRate, (state, action): LeasingState => ({
        ...state,
        configuredRate: action.payload,
    }))
    .handleAction(resetLeasingState, () => defaultState)

export default leasingReducer
