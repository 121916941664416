import { connect, MapStateToProps } from 'react-redux'
import { changePriceMode } from '../../redux/actions/app/priceMode.actions'
import { Props } from './PriceModeSwitch'
import priceModeSelector from '../../redux/selectors/priceModeSelector'
import purchaseModeSelector from '../../redux/selectors/purchaseModeSelector'
import availablePriceModesSelector from '../../redux/selectors/availablePriceModesSelector'

type StateProps = Pick<Props, 'priceMode' | 'purchaseMode' | 'availablePriceModes'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    priceMode: priceModeSelector(state),
    purchaseMode: purchaseModeSelector(state),
    availablePriceModes: availablePriceModesSelector(state),
})

type DispatchProps = Pick<Props, 'changePriceMode'>

const mapDispatchToProps: DispatchProps = {
    changePriceMode,
}

export default connect(mapStateToProps, mapDispatchToProps)
