import { Middleware } from 'redux'
import { ActionCreator, isActionOf } from 'typesafe-actions'
import getApiOptions, { HTTPMethod } from '../../../getApiOptions'
import { apiRequest, apiSuccess, apiFailure } from '../../actions/core/api.actions'
import ErrorType from '../../../entities/ErrorType'

export type ApiRequestOptions = {
    url: string
    method: HTTPMethod
    headers?: HeadersInit
    body?: BodyInit
    successAction: ActionCreator
    failureAction: ActionCreator
}

const apiMiddleware = (
    networkRequest: typeof fetch,
    console: Console,
): Middleware => (store) => (next) => (action) => { // eslint-disable-line consistent-return
    next(action)
    const { dispatch } = store

    if (isActionOf(apiRequest, action)) {
        const {
            url,
            method,
            headers,
            body,
            successAction,
            failureAction,
        } = action.payload
        const { causedBy } = action.meta
        const options: RequestInit = getApiOptions({
            method,
            headers,
            body,
        })

        const handleError = (type: ErrorType) => (error: Error) => {
            dispatch(apiFailure({
                failureAction,
                error,
                type,
                url,
            }, { causedBy }))
        }

        const handleSuccess = (data: object | undefined): void => {
            dispatch(apiSuccess({
                successAction,
                data,
                url,
            }, { causedBy }))
        }

        const handleResponse = (response: Response): void => {
            if (response.status === 204) {
                handleSuccess(null)
                return
            }

            response.json()
                .then((data) => {
                    if (!response.ok) {
                        const error = new Error(data.message)

                        handleError('json')(error)
                        return
                    }

                    handleSuccess(data)
                })
                .catch(handleError('json'))
        }

        return networkRequest(encodeURI(url), options)
            .then(handleResponse)
            .catch(handleError('fetch'))
    }

    if (isActionOf(apiSuccess, action)) {
        const { successAction, data } = action.payload
        const { meta } = action

        dispatch(successAction(data, meta))
    }

    if (isActionOf(apiFailure, action)) {
        const { failureAction, error } = action.payload

        console.error(error)

        dispatch(failureAction(error))
    }
}

export default apiMiddleware
