import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import {
    postFastConfigurationWithOrderKeyAsync,
    fetchConfigurationAsync,
    getConfigurationByVehicleCode,
    putConfigurationAsync,
    resetConfigurationAsync,
    setConfigurationDataState,
    putFastConfigurationAsync,
    setConfigurationRescueConflictAccepted,
} from '../../actions/app/configuration.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import { apiRequest } from '../../actions/core/api.actions'
import {
    getAddConfigurationUrl,
    getConfigurationByVehiclecodeApiUrl,
    getConfigurationUrl, getPutFastConfigurationApiUrl,
    getRemoveConfigurationUrl,
    getResetConfigurationApiUrl,
    loadConfigurationByOrderKey,
} from '../../../constants/apiUrls'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'
import selectedCarlineIdSelector from '../../selectors/carlines/selectedCarlineIdSelector'
import { selectCarline } from '../../actions/app/carlineSelection.actions'
import modeIsLeasingSelector from '../../selectors/feature-scope/modeIsLeasingSelector'
import configuredModelIdSelector from '../../selectors/models/configuredModelIdSelector'
import { setLeasingModalShouldBeShownState } from '../../actions/app/leasing.actions'
import { ROUTE_OVERVIEW } from '../../../constants/routes'
import { fetchEntryDataAsync } from '../../actions/app/entryData.actions'
import initParametersSelector from '../../selectors/initParametersSelector'
import { resetVehicleCodeInState } from '../../actions/app/initParameters.actions'
import rescueConflictAcceptedSelector
    from '../../selectors/configuration/rescueConflictAcceptedSelector'
import { resetVehicleCodeStatus } from '../../actions/app/vehicleCode.actions'

const configurationDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchConfigurationAsync.request, action)) {
        dispatch(apiRequest({
            url: getConfigurationUrl(),
            method: 'GET',
            successAction: fetchConfigurationAsync.success,
            failureAction: fetchConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchConfigurationAsync.success, action)) {
        const configurationData = action.payload

        dispatch(setConfigurationDataState(configurationData))

        const selectedCarlineId = selectedCarlineIdSelector(getState())

        if (!selectedCarlineId) {
            dispatch(selectCarline(configurationData.symbolicCarlineId, {
                forwardToModelPage: false,
            }))
        }
    }

    if (isActionOf(fetchEntryDataAsync.success, action)) {
        const initStateParams = initParametersSelector(getState())

        if (initStateParams.prString) {
            dispatch(postFastConfigurationWithOrderKeyAsync.request(undefined, undefined))
        }
    }

    if (isActionOf(putConfigurationAsync.request, action)) {
        const { id, method } = action.payload
        const getUrl = method === 'add'
            ? getAddConfigurationUrl
            : getRemoveConfigurationUrl

        dispatch(apiRequest({
            url: getUrl(id),
            method: 'PATCH',
            successAction: putConfigurationAsync.success,
            failureAction: putConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(postFastConfigurationWithOrderKeyAsync.request, action)) {
        const { initParameters } = getState()

        dispatch(apiRequest({
            url: loadConfigurationByOrderKey(decodeURI(initParameters.prString)),
            method: 'POST',
            successAction: postFastConfigurationWithOrderKeyAsync.success,
            failureAction: postFastConfigurationWithOrderKeyAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(putFastConfigurationAsync.request, action)) {
        dispatch(apiRequest({
            url: getPutFastConfigurationApiUrl(),
            method: 'PUT',
            body: JSON.stringify(action.payload),
            successAction: putFastConfigurationAsync.success,
            failureAction: putFastConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(postFastConfigurationWithOrderKeyAsync.success, action)) {
        dispatch(fetchConfigurationAsync.request(undefined, undefined))
        dispatch(fetchCartAsync.request(undefined, undefined))
        dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
        dispatch(fetchVisualizationAsync.request(undefined, undefined))
        dispatch(push(ROUTE_OVERVIEW))
    }

    if (isActionOf(putFastConfigurationAsync.success, action)) {
        dispatch(fetchConfigurationAsync.request(undefined, undefined))
        dispatch(fetchCartAsync.request(undefined, undefined))
        dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
        dispatch(fetchVisualizationAsync.request(undefined, undefined))
        dispatch(push(ROUTE_OVERVIEW))
    }

    if (isActionOf(putConfigurationAsync.success, action)) {
        const configurationData = action.payload
        const modeIsLeasing = modeIsLeasingSelector(getState())
        const configuredModelId = configuredModelIdSelector(getState())
        const rescueConflictAccepted = rescueConflictAcceptedSelector(getState())

        if (
            modeIsLeasing
            && configurationData.modelId !== ''
            && configurationData.modelId !== configuredModelId
        ) {
            dispatch(setLeasingModalShouldBeShownState(true))
        }

        dispatch(setConfigurationDataState(configurationData))

        if (!['CONFLICT', 'MODEL_CONFLICT'].includes(configurationData.configurationState) && configurationData.modelId !== '') {
            dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
            dispatch(fetchCartAsync.request(undefined, undefined))
            dispatch(fetchVisualizationAsync.request(undefined, undefined))
        }

        if(rescueConflictAccepted) {
            dispatch(setConfigurationRescueConflictAccepted(false))
            dispatch(resetVehicleCodeStatus())
            dispatch(resetVehicleCodeInState())
            dispatch(fetchConfigurationAsync.request(undefined, undefined))
            dispatch(push(ROUTE_OVERVIEW))
        }
    }

    if (isActionOf(resetConfigurationAsync.request, action)) {
        dispatch(apiRequest({
            url: getResetConfigurationApiUrl(),
            method: 'POST',
            successAction: action.meta?.onSuccess || resetConfigurationAsync.success,
            failureAction: action.meta?.onFailure || resetConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(resetConfigurationAsync.success, action)) {
        dispatch(resetConfigurationProcess())
    }

    if (isActionOf(getConfigurationByVehicleCode.request, action)) {
        const vehicleCode = action.payload

        dispatch(apiRequest({
            url: getConfigurationByVehiclecodeApiUrl(vehicleCode),
            method: 'GET',
            successAction: action.meta?.onSuccess || getConfigurationByVehicleCode.success,
            failureAction: action.meta?.onFailure || getConfigurationByVehicleCode.failure,
        }, {
            causedBy: action,
        }))
    }
}

export default configurationDataMiddleware
