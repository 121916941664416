import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { DisplayMode } from '../../entities/CarlineGroup'
import GridIcon from '../../assets/media/grid.svg'
import ListIcon from '../../assets/media/list.svg'
import style from './carlineSelectionToggleButton.css'

export type Props = {
    displayMode: DisplayMode
    setDisplayMode: (displayMode: DisplayMode) => void
}

const CarlineSelectionToggleButton: FunctionComponent<Props> = (props) => {
    const { displayMode, setDisplayMode } = props
    const { t } = useTranslation()

    const iconElement = displayMode === 'list'
        ? <GridIcon className={style.icon} />
        : <ListIcon className={style.icon} />
    const buttonLabel = displayMode === 'list'
        ? t('carlineSelection.carView')
        : t('carlineSelection.listView')

    const toggleDisplayMode = (): void => {
        const newDisplayMode = displayMode === 'list' ? 'grid' : 'list'

        setDisplayMode(newDisplayMode)
    }

    return (
        <button onClick={toggleDisplayMode} className={style.button} type="button" data-cy="view-toggle">
            {buttonLabel}
            {iconElement}
        </button>
    )
}

export default CarlineSelectionToggleButton
