import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import camelCase from 'lodash.camelcase'
import useTranslation from '../../hooks/useTranslation'
import Prices from '../../entities/Prices'
import VatIncludedSwitch from '../vat-inclusion-switch'
import PriceModeSwitch from '../price-mode-switch'
import style from './cartboxPrices.css'

export type Props = {
    prices: Prices
    isHidden?: boolean
    mode: string
}

const CartboxPrices: FunctionComponent<Props> = (props) => {
    const { prices, isHidden = false, mode } = props
    const { t, i18n } = useTranslation()

    if(isHidden) {
        return null
    }

    const rowElements = prices.map((price) => {
        const { id, value, isSum } = price
        const title = i18n.exists(`price.${camelCase(id)}_${mode}`)
            ? t(`price.${camelCase(id)}_${mode}`)
            : t(`price.${camelCase(id)}`)

        const isSumClassName = isSum ? style.rowSum : ''

        return (
            <div key={id} className={`${style.row} ${isSumClassName}`}>
                <span className={style.priceTitle}>
                    {title}
                </span>
                <span className={style.price}>
                    <Markup content={value} />
                </span>
            </div>
        )
    })

    return (
        <div className={style.container} data-cy="cart-pricing">
            <PriceModeSwitch />
            {rowElements}
            <VatIncludedSwitch />
        </div>
    )
}

export default CartboxPrices
