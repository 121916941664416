import React, { FunctionComponent, FormEventHandler } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useFormInput from '../../hooks/useFormInput'
import Modal, { ModalClosingButton } from '../modal'
import style from './fastConfigurationModal.css'
import { Input } from '../form'
import FastConfigurationKey from '../../entities/FastConfigurationKey'
import { Table, Td, Tr } from '../table'

export type Props = {
    putFastConfiguration: Function
    activeState: [boolean, (isActive: boolean) => void]
};

const FastConfigurationModal: FunctionComponent<Props> = (props) => {
    const { putFastConfiguration, activeState } = props
    const { t } = useTranslation()
    const [modalIsActive, setModalIsActive] = activeState

    const [model, setModel] = useFormInput('')
    const [exteriorColor, setExteriorColor] = useFormInput('')
    const [interiorColor, setInteriorColor] = useFormInput('')
    const [equipments, setEquipments] = useFormInput('')

    const buildTranslationKey = (key: string): string => `fastConfigurationModal.${key}`

    if (!modalIsActive) {
        return null
    }

    const closeModal = (): void => {
        setModalIsActive(false)
    }

    const closeButtonClickHandler = (): void => {
        closeModal()
    }

    const submitHandler: FormEventHandler = (event) => {
        event.preventDefault()

        const fastConfigurationKey: FastConfigurationKey = {
            model,
            exteriorColor,
            interiorColor,
            equipments,
        }

        putFastConfiguration(fastConfigurationKey)
        closeModal()
    }

    return (
        <Modal onClose={closeModal}>
            <ModalClosingButton onClick={closeButtonClickHandler} />
            <div className={style.container}>
                <h2 className={style.title}>
                    {t(buildTranslationKey('title'))}
                </h2>
                <h4>
                    {t(buildTranslationKey('disclaimer'))}
                </h4>
                <form onSubmit={submitHandler}>
                    <Table>
                        <Tr>
                            <Td className={style.noBorder}>
                                <div className={style.explanationContainer}>
                                    <h4 className={style.explanationHeading}>
                                        {t(buildTranslationKey('modelTitle'))}
                                    </h4>
                                    <span className={style.explanation}>
                                        {t(buildTranslationKey('modelExplanation'))}
                                    </span>
                                </div>
                            </Td>
                            <Td className={style.noBorder}>
                                <Input
                                    value={model}
                                    onChange={setModel}
                                    className={style.input}
                                    placeholder=""
                                    required
                                    minLength={1}
                                />
                            </Td>
                        </Tr>
                        <Tr>
                            <Td className={style.noBorder}>
                                <div className={style.explanationContainer}>
                                    <h4 className={style.explanationHeading}>
                                        {t(buildTranslationKey('exteriorTitle'))}
                                    </h4>
                                    <span className={style.explanation}>
                                        {t(buildTranslationKey('exteriorExplanation'))}
                                    </span>
                                </div>
                            </Td>
                            <Td className={style.noBorder}>
                                <Input
                                    value={exteriorColor}
                                    onChange={setExteriorColor}
                                    className={style.input}
                                    placeholder=""
                                    required
                                    minLength={1}
                                />
                            </Td>
                        </Tr>
                        <Tr>
                            <Td className={style.noBorder}>
                                <div className={style.explanationContainer}>
                                    <h4 className={style.explanationHeading}>
                                        {t(buildTranslationKey('interiorTitle'))}
                                    </h4>
                                    <span className={style.explanation}>
                                        {t(buildTranslationKey('interiorExplanation'))}
                                    </span>
                                </div>
                            </Td>
                            <Td className={style.noBorder}>
                                <Input
                                    value={interiorColor}
                                    onChange={setInteriorColor}
                                    className={style.input}
                                    placeholder=""
                                    required
                                    minLength={1}
                                />
                            </Td>
                        </Tr>
                        <Tr>
                            <Td className={style.noBorder}>
                                <div className={style.explanationContainer}>
                                    <h4 className={style.explanationHeading}>
                                        {t(buildTranslationKey('equipmentsTitle'))}
                                    </h4>
                                    <span className={style.explanation}>
                                        {t(buildTranslationKey('equipmentsExplanation'))}
                                    </span>
                                </div>
                            </Td>
                            <Td className={style.noBorder}>
                                <Input
                                    value={equipments}
                                    onChange={setEquipments}
                                    className={style.input}
                                    placeholder=""
                                    required={false}
                                    minLength={0}
                                />
                            </Td>
                        </Tr>
                    </Table>
                    <div className={style.submitContainer}>
                        <button className={style.submitButton} type="submit">
                            {t(buildTranslationKey('submitButtonText'))}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default FastConfigurationModal
