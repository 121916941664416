import React, { FunctionComponent, useEffect, useRef } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'
import style from './checkout.css'
import CheckoutPayload from '../../entities/CheckoutPayload'

export type Props = {
    checkoutIsEnabled: boolean
    fetchCheckoutData: Function
    checkoutPayload: CheckoutPayload
}

const Checkout: FunctionComponent<Props> = (props) => {
    const { checkoutIsEnabled, fetchCheckoutData, checkoutPayload } = props
    const { t } = useTranslation()
    const formRef = useRef<HTMLFormElement>()

    useOnMount(() => {
        if (checkoutIsEnabled) {
            fetchCheckoutData()
        }
    })

    useEffect(() => {
        if (checkoutPayload.targetUrl !== '') {
            formRef.current.submit()
        }
    }, [checkoutPayload.targetUrl])

    const inputElements = Object.entries(checkoutPayload.checkoutBody).map(([name, value]) => (
        <input
            key={name}
            type="hidden"
            value={value === null ? '' : value.toString()}
            name={name}
        />
    ))

    return (
        <section className={style.container}>
            <h1>
                {t('checkout.heading')}
            </h1>
            <p>
                {t('checkout.infoText')}
            </p>
            <form action={checkoutPayload.targetUrl} method="POST" ref={formRef}>
                {inputElements}
            </form>
        </section>
    )
}

export default Checkout
