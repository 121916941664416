import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import { fetchVisualizationAsync, setVisualizationState } from '../../actions/app/visualization.actions'
import { getVisualizationApiUrl } from '../../../constants/apiUrls'
import isAveStringFramePostMessageHackEnabledSelector from '../../selectors/feature-scope/isAveStringFramePostMessageHackEnabledSelector'

declare global {
    interface Window {
        aveCarConfiguration: string
    }
}

const visualizationMiddleware = (window: Window): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchVisualizationAsync.request, action)) {
        dispatch(apiRequest({
            url: getVisualizationApiUrl(),
            method: 'GET',
            successAction: fetchVisualizationAsync.success,
            failureAction: fetchVisualizationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchVisualizationAsync.success, action)) {
        const visualization = action.payload

        dispatch(setVisualizationState(visualization))

        if (isAveStringFramePostMessageHackEnabledSelector(getState())) {
            if (window.parent !== window) {
                window.parent.postMessage({
                    key: 'prCode',
                    prCode: visualization.aveString,
                }, '*')
            }

            // eslint-disable-next-line no-param-reassign
            window.aveCarConfiguration = visualization.aveString
        }
    }
}

export default visualizationMiddleware
