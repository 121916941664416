/* eslint-disable quotes, quote-props */ // remove it and it will break!
declare const APP_VERSION: string
declare const REACT_VERSION: string
declare const REACT_DOM_VERSION: string
declare const API_URL: string
declare const TRANSLATION_SERVICE_URL: string
declare const TRACKING_URL: string
declare const PDF_SERVICE_URL: string

export default Object.freeze({
    "APP_VERSION": APP_VERSION,
    "REACT_VERSION": REACT_VERSION,
    "REACT_DOM_VERSION": REACT_DOM_VERSION,
    STAGE: process.env.SPACE === 'live' ? 'production' : 'development',
    "API_URL": API_URL,
    "TRANSLATION_SERVICE_URL": TRANSLATION_SERVICE_URL,
    "TRACKING_URL": TRACKING_URL,
    "PDF_SERVICE_URL": PDF_SERVICE_URL,
})
