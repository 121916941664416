import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CartboxPrices'
import cartPricesSelector from '../../redux/selectors/cart/cartPricesSelector'
import modeSelector from '../../redux/selectors/modeSelector'

type StateProps = Pick<Props, 'prices' | 'mode'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    prices: cartPricesSelector(state),
    mode: modeSelector(state),
})

export default connect(mapStateToProps)
