import { connect, MapStateToProps } from 'react-redux'
import { Props } from './RepurchaseExcludedModal'
import modeSelector from '../../redux/selectors/modeSelector'

type StateProps = Pick<Props, 'mode'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    mode: modeSelector(state),
})

export default connect(mapStateToProps)
