import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import SummaryData from '../../entities/SummaryData'
import Cartbox from '../cartbox'
import OverviewEquipmentGroups from '../overview-equipment-groups'
import DisclaimerText from '../disclaimer-text'
import style from './overviewView.css'
import { OrderKey } from '../../entities/ConfigurationResponse'
import OrderKeyView from '../order-key-view'
import SummaryTable from '../summary-table'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import OverviewModals from './OverviewModals'
import GeneralInformation from '../../entities/GeneralInformation'
import EquipmentInformation from '../../entities/EquipmentInformation'
import { Link } from 'react-router-dom'

export type Props = {
    summary: SummaryData
    carlineTitle: string
    loadSummaryData: Function
    orderKey: OrderKey
    isLoading: boolean
    generalInformation: GeneralInformation[]
    equipmentInformation: EquipmentInformation
    winterTyreUrl: string
}

const OverviewView: FunctionComponent<Props> = (props) => {
    const {
        summary,
        carlineTitle,
        loadSummaryData,
        orderKey,
        isLoading,
        generalInformation,
        equipmentInformation,
        winterTyreUrl,
    } = props
    const { t } = useTranslation()

    const recommendations = equipmentInformation?.recommendations
    const isRepurchaseExcluded = recommendations && recommendations.required && !recommendations.selected

    const winterTyreAnchorElement = (winterTyreUrl) ? (
        <Link className={style.winterTyreAnchor} to={winterTyreUrl}>{t('overview.winterTyreButtonLabel')}</Link>
    ) : null

    return (
        <>
            <div className="contentWithCartboxContainer">
                <div className="contentWithCartbox">
                    <LoadingIndicatorWrapper isLoading={isLoading}>
                        <h1>
                            {t('overview.heading')}
                        </h1>
                        <h2 className={style.subTitle}>
                            {carlineTitle}
                        </h2>
                        <SummaryTable summary={summary} loadSummaryData={loadSummaryData} />
                        <OrderKeyView orderKey={orderKey} />
                        <OverviewEquipmentGroups />
                    </LoadingIndicatorWrapper>
                    <DisclaimerText remarks={summary.remarks} />
                </div>
                <Cartbox />
            </div>
            <OverviewModals repurchaseExcluded={isRepurchaseExcluded} generalInformation={generalInformation} generalInfoAdditionalElement={winterTyreAnchorElement}/>
        </>
    )
}

export default OverviewView
