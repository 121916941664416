import React, { useState, FunctionComponent } from 'react'
import style from './foldableSection.css'
import ArrowDownSmall from '../../assets/media/arrow-down-small.svg'
import ArrowUpSmall from '../../assets/media/arrow-up-small.svg'

type Props = {
    title: string
    content: React.ReactNode
    toggleTitle?: string
}

const FoldableSection: FunctionComponent<Props> = ({title, content, toggleTitle}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => setIsOpen(!isOpen)

    return (
        <section>
            <h2 className={style.title} onClick={toggleOpen} title={toggleTitle}>
                {title}
                {isOpen ? <ArrowUpSmall className={style.icon}/> : <ArrowDownSmall className={style.icon}/>}
            </h2>
            {isOpen && content}
        </section>
    )
}

export default FoldableSection
