import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-http-backend'
import config from '../config'

const i18nOptions: InitOptions = {
    fallbackLng: false,
    ns: ['micon-app'],
    defaultNS: 'micon-app',
    interpolation: {
        escapeValue: false,
    },
}
const backendOptions = {
    loadPath: `${config.TRANSLATION_SERVICE_URL}/translations/{{lng}}/{{ns}}`,
}

i18n.use(XHR)
    .use(initReactI18next)

i18n.on('failedLoading', (lng, ns, msg) => {
    i18n.changeLanguage('en')

    const errorMsg = `
            i18n: Cannot load translation file "${lng}/${ns}"

            ${msg}
        `

    // eslint-disable-next-line no-alert
    alert(errorMsg)
})

i18n.init({
    ...i18nOptions,
    backend: backendOptions,
})

export default i18n
