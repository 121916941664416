import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    adoptConflictSolution,
    dontAdoptConflictSolution,
    cancelConflictSolution,
} from '../../actions/app/conflictSolution.actions'
import { apiRequest } from '../../actions/core/api.actions'
import { getConflictSolutionResolveUrl } from '../../../constants/apiUrls'
import { putConfigurationAsync } from '../../actions/app/configuration.actions'

const conflictSolutionMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(adoptConflictSolution, action)) {
        dispatch(apiRequest({
            url: getConflictSolutionResolveUrl('ACCEPT'),
            method: 'PATCH',
            successAction: putConfigurationAsync.success,
            failureAction: putConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(dontAdoptConflictSolution, action)) {
        dispatch(apiRequest({
            url: getConflictSolutionResolveUrl('REJECT'),
            method: 'PATCH',
            successAction: putConfigurationAsync.success,
            failureAction: putConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(cancelConflictSolution, action)) {
        dispatch(apiRequest({
            url: getConflictSolutionResolveUrl('CANCEL'),
            method: 'PATCH',
            successAction: putConfigurationAsync.success,
            failureAction: putConfigurationAsync.failure,
        }, {
            causedBy: action,
        }))
    }
}

export default conflictSolutionMiddleware
