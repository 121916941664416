import { PriceMode } from '../../../actions/app/priceMode.actions'
import { PriceViewFlag } from '../../../../entities/PriceViewFlag'

const getPriceViewFlag = (priceMode: PriceMode, vatIncluded: boolean): PriceViewFlag => {
    const priceModeMap: Record<PriceMode, string> = {
        full: 'PRICE',
        rate: 'RATE',
    }

    const vat = vatIncluded ? 'BRUTTO' : 'NETTO'

    return `${priceModeMap[priceMode]}_${vat}` as PriceViewFlag
}

export default getPriceViewFlag
