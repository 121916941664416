import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import EquipmentGroup from '../../entities/EquipmentGroup'
import urlifyGroupName from '../../helpers/urlifyGroupName'
import OverviewEquipmentTable from './OverviewEquipmentTable'
import style from './overviewEquipmentGroups.css'
import FoldableSection from '../../base-components/foldable-section/FoldableSection'

const RECOMMENDATIONS_PLACEHOLDER = 'recommendations'

export type Props = {
    selectedEquipmentGroups: EquipmentGroup[]
    deselectEquipment: Function
    openInfoModal: Function
}

const removeDuplicatedRecommendations = (equipmentGroups: EquipmentGroup[]) => {
    const seen = new Set()

    equipmentGroups.forEach(group => {
        if (group.id.includes(RECOMMENDATIONS_PLACEHOLDER)) {
            group.equipmentFamilies.forEach(family => {
                family.equipments = family.equipments.filter(equipment => !seen.has(equipment.id) && seen.add(equipment.id))
            })
        }
    })

    return equipmentGroups
}

const OverviewEquipmentGroups: FunctionComponent<Props> = (props) => {
    const { selectedEquipmentGroups, deselectEquipment, openInfoModal } = props
    const { t } = useTranslation()

    const filteredEquipmentGroups = removeDuplicatedRecommendations(selectedEquipmentGroups)

    return (
        <>
            {filteredEquipmentGroups.map((equipmentGroup) => {
                if (!equipmentGroup) {
                    return null
                }
                const url = `${urlifyGroupName(equipmentGroup.name)}`
                const { equipmentFamilies } = equipmentGroup

                if (!equipmentFamilies) {
                    return null
                }

                const equipments = equipmentFamilies
                    .flatMap((equipmentFamily) => equipmentFamily.equipments)

                const content = (equipments.length === 0) ? (
                    <p>
                        <Link to={url} className={style.fallbackLink}>
                            {t('overviewSelection.fallbackLink')}
                        </Link>
                    </p>
                ) : (
                    <OverviewEquipmentTable
                        equipments={equipments}
                        url={url}
                        deselectEquipment={deselectEquipment}
                        openInfoModal={openInfoModal}
                    />
                )

                return (
                    <FoldableSection
                        key={equipmentGroup.id}
                        title={t(`navigation.${equipmentGroup.id}`)}
                        content={content}
                    />
                )
            })}
        </>
    )
}

export default OverviewEquipmentGroups
