import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import RepurchaseExcludedModal from '../repurchase-excluded-modal'
import GeneralInformationModal from '../general-information-modal'
import GeneralInformation from '../../entities/GeneralInformation'

type Props = {
    repurchaseExcluded: boolean
    generalInformation: GeneralInformation[]
    generalInfoAdditionalElement?: ReactElement
}

const OverviewModals: FunctionComponent<Props> = (props) => {
    const { repurchaseExcluded, generalInformation, generalInfoAdditionalElement} = props

    const repurchaseExcludedModalState = useState(repurchaseExcluded)
    const [
        repurchaseExcludedModalIsVisible,
        setRepurchaseExcludedModalIsVisible,
    ] = repurchaseExcludedModalState

    const generalInformationModalState = useState(repurchaseExcludedModalIsVisible)
    const setGeneralInformationModalIsVisible = generalInformationModalState[1]

    useEffect(() => {
        setRepurchaseExcludedModalIsVisible(repurchaseExcluded)
    }, [repurchaseExcluded, setRepurchaseExcludedModalIsVisible])

    useEffect(() => {
        setGeneralInformationModalIsVisible(!repurchaseExcludedModalIsVisible)
    }, [repurchaseExcludedModalIsVisible, setGeneralInformationModalIsVisible])

    return (
        <>
            <GeneralInformationModal
                information={generalInformation}
                isVisibleState={generalInformationModalState}
            >
                {generalInfoAdditionalElement}
            </GeneralInformationModal>
            <RepurchaseExcludedModal isVisibleState={repurchaseExcludedModalState}/>
        </>
    )
}

export default OverviewModals
