import styled, { keyframes } from 'styled-components'

const auiLoaderRotation = keyframes`
    0% {
        transform: rotate(-90deg);
        animation-timing-function: cubic-bezier(0.69, 0.01, 0.83, 0.56);
    }

    50% {
        transform: rotate(-45deg);
        animation-timing-function: cubic-bezier(0.21, 0.55, 0.43, 1);
    }

    100% {
        transform: rotate(630deg);
    }
`

const auiLoaderStroke = keyframes`
    0% {
        stroke-dasharray: 0, 138;
        animation-timing-function: cubic-bezier(0.69, 0.01, 0.83, 0.56);
    }

    50% {
        stroke-dasharray: 92, 46;
        animation-timing-function: cubic-bezier(0.21, 0.55, 0.43, 1);
    }

    100% {
        stroke-dasharray: 0, 138;
    }
`

export const Icon = styled.svg<{ maxheight: string; maxwidth: string }>`
    height: 100%;
    max-width: ${(props) => props.maxwidth};
    max-height: ${(props) => props.maxheight};
    animation: ${auiLoaderRotation} 2s infinite;
    transform-origin: 50% 50%;
`

export const SpinnerCircle = styled.circle`
    animation: ${auiLoaderStroke} 2s infinite;
    stroke: var(--color-red);
`
