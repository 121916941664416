import { createSelector } from 'reselect'
import carlineGroupsSelector from './carlineGroupsSelector'
import filtersSelector from '../filtersSelector'
import CarlineGroup from '../../../entities/CarlineGroup'
import carlineFilterListSelector from './carlineFilterListSelector'

const filteredCarlineGroupsSelector = createSelector(
    carlineGroupsSelector,
    filtersSelector,
    carlineFilterListSelector,
    (carlineGroups, appliedFilters, carlineFilterList): CarlineGroup[] => {
        const activeFilters = Object.entries(appliedFilters)

        return carlineGroups.map((carlineGroup) => {
            const { carlines } = carlineGroup

            const filteredCarlines = carlines.filter((carline) => {
                const filterMatches = activeFilters
                    .find(([activeFilterKey, activeFilterValues]) => {
                        if (activeFilterValues.length === 0) {
                            return false
                        }

                        const carlineAttributes = carline.attributes[activeFilterKey]

                        if (carlineAttributes === undefined) {
                            return true
                        }

                        const activeCarlineFilter = carlineFilterList.find((carlineFilter) => (
                            carlineFilter.attribute === activeFilterKey
                        ))

                        // TODO remove after DCCAPP-153 is done
                        if (activeCarlineFilter === undefined) {
                            return false
                        }

                        switch (activeCarlineFilter.type) {
                            case 'max':
                                return carlineAttributes.some((carlineAttribute) => {
                                    const maxFilterValue = Math
                                        .max(...activeFilterValues as number[])

                                    return carlineAttribute > maxFilterValue
                                })
                            default: {
                                const activeFilterMatches = activeFilterValues
                                    .find((activeFilterValue) => (
                                        carlineAttributes.includes(activeFilterValue as string)
                                    ))

                                return !activeFilterMatches
                            }
                        }
                    })

                return !filterMatches
            })

            return {
                ...carlineGroup,
                carlines: filteredCarlines,
            }
        })
    },
)

export default filteredCarlineGroupsSelector
