import { createAction, createAsyncAction } from 'typesafe-actions'

export const loadTrackingScriptAsync = createAsyncAction(
    'CMD / Tracking / load script request',
    'EVT / Tracking / load script success',
    'EVT / Tracking / load script failure',
)()

export const setTrackingIsReadyState = createAction('DOC / Tracking / set is ready state')<boolean>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushTrackingEvent = createAction('CMD / Tracking / push event')<any>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setTrackingState = createAction('DOC / Tracking / set state')<any>()
