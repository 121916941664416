import { createSelector } from 'reselect'
import equipmentGroupsSelector from './equipmentGroupsSelector'
import {
    EQUIPMENT_GROUP_STATUS_NONE,
    EQUIPMENT_GROUP_STATUS_OK,
} from '../../../entities/EquipmentGroup'

const everyEquipmentGroupStatusIsOkSelector = createSelector(
    equipmentGroupsSelector,
    (equipmentGroups): boolean => (
        equipmentGroups.every((equipmentGroup) => (
            equipmentGroup.status === EQUIPMENT_GROUP_STATUS_OK
            || equipmentGroup.status === EQUIPMENT_GROUP_STATUS_NONE
        ))
    ),
)

export default everyEquipmentGroupStatusIsOkSelector
