import React, { FunctionComponent, useState } from 'react'
import RenderImage from '../../entities/RenderImage'
import { getLoadingClassName } from '../loading-indicator-wrapper'
import GalleryModal from '../gallery-modal'
import EnlargeIcon from '../../assets/media/enlarge.svg'
import style from './renderImagesPreview.css'
import RenderImagePlaceholder from '../../assets/media/car-silhouette41.svg'

export type Props = {
    renderImages: RenderImage[] | null
    activeRenderImgIdx: number
    setActiveRenderImgIdx: Function
    isLoading: boolean
    isHidden: boolean
}

const RenderImagesPreview: FunctionComponent<Props> = (props) => {
    const {
        renderImages,
        activeRenderImgIdx,
        setActiveRenderImgIdx,
        isLoading,
        isHidden,
    } = props

    const modalActiveState = useState(false)
    const setModalIsActive = modalActiveState[1]

    if (isHidden || renderImages === null) {
        return null
    }

    const activeImg = renderImages[activeRenderImgIdx]
    const showPlaceholder = (
        renderImages.length === 0
        || activeRenderImgIdx === null
        || activeImg?.url['400'] === undefined
    )

    const img = showPlaceholder
        ? <RenderImagePlaceholder className={style.placeholderImg} />
        : <img src={activeImg?.url['400']} className={style.img} alt="Audi" width={400} height={240} data-cy="cart-gallery" />

    const thumbnailElements = renderImages.map((image, index) => {
        const onMouseEnter = (): void => {
            setActiveRenderImgIdx(index)
        }

        return (
            <li
                key={image.id}
                className={style.thumbnail}
                onMouseEnter={onMouseEnter}
            />
        )
    })

    const openModal = (): void => {
        setModalIsActive(true)
    }

    const images = renderImages.map((renderImage) => ({
        src: renderImage?.url['900'],
        width: 900,
        height: 540,
    }))
    const thumbnails = renderImages.map((renderImage) => ({
        src: renderImage?.url['100'],
        width: 100,
        height: 60,
    }))

    return (
        <>
            <div className={`${style.container} ${getLoadingClassName(isLoading)}`}>
                <button onClick={openModal} className={style.button} type="button">
                    <EnlargeIcon />
                </button>
                {img}
                <ul className={style.thumbnailList}>
                    {thumbnailElements}
                </ul>
            </div>
            <GalleryModal
                modalActiveState={modalActiveState}
                images={images}
                thumbnails={thumbnails}
                activeImgIdx={activeRenderImgIdx}
            />
        </>
    )
}

export default RenderImagesPreview
