import { createSelector } from 'reselect'
import modelsWithSelectedSelector from './modelsWithSelectedSelector'
import filtersSelector from '../filtersSelector'
import Model from '../../../entities/Model'
import modelFilterListSelector from './modelFilterListSelector'

const filteredModelsSelector = createSelector(
    modelsWithSelectedSelector,
    filtersSelector,
    modelFilterListSelector,
    (models, appliedFilters, modelFilterList): Model[] => {
        const activeFilters = Object.entries(appliedFilters)

        return models.filter((model) => {
            const filterMatches = activeFilters.some(([activeFilterKey, activeFilterValues]) => {
                if (activeFilterValues.length === 0) {
                    return false
                }

                const activeModelFilter = modelFilterList
                    .find((modelFilter) => modelFilter.attribute === activeFilterKey)

                // TODO remove after DCCAPP-153 is done
                if (activeModelFilter === undefined) {
                    return false
                }

                const modelAttribute = model.attributes
                    .find((attribute) => attribute.id === activeFilterKey)

                if (modelAttribute === undefined) {
                    return true
                }

                switch (activeModelFilter.type) {
                    case 'max': {
                        return modelAttribute.value > activeFilterValues[0]
                    }
                    default: {
                        const activeFilterMatches = activeFilterValues
                            .includes(modelAttribute.value)

                        return !activeFilterMatches
                    }
                }
            })

            return !filterMatches
        })
    },
)

export default filteredModelsSelector
