import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import { apiRequest } from '../../actions/core/api.actions'
import {
    deselectModel, fetchModelsAsync, selectModel, setModelDataState,
} from '../../actions/app/models.actions'
import { getModelsApiUrl } from '../../../constants/apiUrls'
import { putConfigurationAsync } from '../../actions/app/configuration.actions'
import { openInfoModal } from '../../actions/app/infoModal.actions'
import { ROUTE_CARLINES } from '../../../constants/routes'
import selectedCarlineIdSelector from '../../selectors/carlines/selectedCarlineIdSelector'

const modelDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchModelsAsync.request, action)) {
        const carlineId = selectedCarlineIdSelector(getState())

        dispatch(apiRequest({
            url: getModelsApiUrl(carlineId),
            method: 'GET',
            successAction: fetchModelsAsync.success,
            failureAction: fetchModelsAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchModelsAsync.success, action)) {
        const modelData = action.payload

        dispatch(setModelDataState(modelData))
    }

    if (isActionOf(fetchModelsAsync.failure, action)) {
        dispatch(push(ROUTE_CARLINES))
    }

    if (isActionOf(selectModel, action)) {
        const modelId = action.payload

        if (action.meta && action.meta.infoModal) {
            dispatch(openInfoModal({
                ...action.meta.infoModal,
                onClose: selectModel(modelId, undefined),
            }))
        } else {
            dispatch(putConfigurationAsync.request({
                id: modelId,
                method: 'add',
            }))
        }
    }

    if (isActionOf(deselectModel, action)) {
        dispatch(putConfigurationAsync.request({
            id: action.payload,
            method: 'remove',
        }))
    }
}

export default modelDataMiddleware
