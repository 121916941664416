import React, { FunctionComponent } from 'react'
import Modal from '../modal'
import style from './loadingModal.css'
import { Spinner } from '../../base-components/spinner'

export type Props = {
    isVisible: boolean
}

const LoadingModal: FunctionComponent<Props> = (props) => {
    const { isVisible } = props

    if (isVisible === false) {
        return null
    }

    return (
        <Modal
            containerClassName={style.modalContainer}
            contentClassName={style.modalContent}
            isFixed={false}
        >
            <Spinner />
        </Modal>
    )
}

export default LoadingModal
