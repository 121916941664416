import { connect, MapStateToProps } from 'react-redux'
import technicalDataSelector from '../../redux/selectors/technicalDataSelector'
import { fetchTechnicalDataAsync } from '../../redux/actions/app/technicalData.actions'
import { Props } from './TechnicalDataTab'
import technicalDataRequestIsPendingSelector from '../../redux/selectors/pending-request/technicalDataRequestIsPendingSelector'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'

type StateProps = Pick<Props, 'technicalData' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    technicalData: technicalDataSelector(state),
    isLoading: technicalDataRequestIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'loadTechnicalData' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    loadTechnicalData: fetchTechnicalDataAsync.request,
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
