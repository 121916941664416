import { ModelAttribute } from '../../../../entities/Model'

export const extractEngineAttributes = (engineType: string, attributes: ModelAttribute[]): ModelAttribute[] => {

    if (engineType === 'hybrid') {
        return [
            'consumption', 'consumption_weighted_combined', 'consumption_electric_weighted_combined',
            'emission-co2-weighted-combined',
            'efficiency-class', 'efficiency-class-sustaining',
        ].map(id => attributes.find((attr) => attr.id === id)).filter(Boolean)
    }

    if (engineType === 'electrical') {
        return [
            'consumption_electric_combined',
            'emission',
            'efficiency-class',
        ].map(id => attributes.find((attr) => attr.id === id)).filter(Boolean)
    }

    return [
        'consumption',
        'emission',
        'efficiency-class',
    ].map(id => attributes.find((attr) => attr.id === id)).filter(Boolean)

}
