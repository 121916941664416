import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './footer.css'

const Footer: FunctionComponent = () => {
    const { t } = useTranslation()

    const disclaimerLinks = [
        {
            label: t('footer.imprint'),
            url: 'https://weare.audi/501_mfc/web/guest/impressum',
        },
        {
            label: t('footer.dataProtection'),
            url: 'https://weare.audi/501_mfc/web/guest/datenschutz',
        },
        {
            label: t('footer.legalInformation'),
            url: 'https://weare.audi/501_mfc/web/guest/agbs',
        },
    ]

    const disclaimerLinkElements = disclaimerLinks.map((disclaimerLink) => (
        <li key={disclaimerLink.label} className={style.disclaimerLinkListItem}>
            <a href={disclaimerLink.url}>
                {disclaimerLink.label}
            </a>
        </li>
    ))

    return (
        <footer className={style.footer}>
            <ul className={style.disclaimerLinkList}>
                {disclaimerLinkElements}
            </ul>
        </footer>
    )
}

export default Footer
