import { connect } from 'react-redux'
import { Props } from './EntryPage'
import { initFromEntryRoute } from '../../redux/actions/core/init.actions'

type DispatchProps = Pick<Props, 'initFromEntryRoute'>

const mapDispatchToProps: DispatchProps = {
    initFromEntryRoute,
}

export default connect(undefined, mapDispatchToProps)
