import { createSelector } from 'reselect'
import { API_URL_TECHNICAL_DATA } from '../../../constants/apiUrls'
import pendingRequestsSelector from '../pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const technicalDataRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_TECHNICAL_DATA),
)

export default technicalDataRequestIsPendingSelector
