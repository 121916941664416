import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import { apiRequest } from '../../actions/core/api.actions'
import {
    createVehicleCodeAsync,
    loadConfigurationByVehicleCodeAsync,
    setVehicleCodeState,
    setVehicleCodeStatus,
    vehicleCodeChanged,
} from '../../actions/app/vehicleCode.actions'
import { ROUTE_OVERVIEW } from '../../../constants/routes'
import {
    loadConfigurationByVehiclecodeApiUrl,
    getCreateVehiclecodeApiUrl,
} from '../../../constants/apiUrls'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import { fetchConfigurationAsync } from '../../actions/app/configuration.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import { fetchEntryDataAsync } from '../../actions/app/entryData.actions'
import initParametersSelector from '../../selectors/initParametersSelector'
import sessionIdIsSetSelector from '../../selectors/sessionIdIsSetSelector'
import { resetVehicleCodeInState } from '../../actions/app/initParameters.actions'

const vehicleCodeMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(createVehicleCodeAsync.request, action)) {
        dispatch(apiRequest({
            url: getCreateVehiclecodeApiUrl(),
            method: 'POST',
            successAction: action.meta?.onSuccess || createVehicleCodeAsync.success,
            failureAction: action.meta?.onFailure || createVehicleCodeAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(createVehicleCodeAsync.success, action)) {
        const { vehicleCode } = action.payload

        dispatch(setVehicleCodeState(vehicleCode))
    }

    if (isActionOf(loadConfigurationByVehicleCodeAsync.request, action)) {
        const vehicleCode = action.payload

        dispatch(apiRequest({
            url: loadConfigurationByVehiclecodeApiUrl(vehicleCode),
            method: 'POST',
            successAction: loadConfigurationByVehicleCodeAsync.success,
            failureAction: loadConfigurationByVehicleCodeAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchEntryDataAsync.success, action)) {
        const initStateParams = initParametersSelector(getState())

        if (initStateParams.vehicleCode !== undefined) {
            dispatch(loadConfigurationByVehicleCodeAsync.request(initStateParams.vehicleCode))
        }
    }

    if (isActionOf(vehicleCodeChanged, action)) {
        const initStateParams = initParametersSelector(getState())
        const sessionIdIsSet = sessionIdIsSetSelector(getState())

        if (initStateParams.vehicleCode !== undefined && sessionIdIsSet) {
            dispatch(loadConfigurationByVehicleCodeAsync.request(initStateParams.vehicleCode))
        }
    }

    if (isActionOf(loadConfigurationByVehicleCodeAsync.success, action)) {
        const { status, rescueInfo } = action.payload

        if (status === 'OK') {
            dispatch(resetVehicleCodeInState())
            dispatch(fetchConfigurationAsync.request(undefined, undefined))
            dispatch(fetchCartAsync.request(undefined, undefined))
            dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
            dispatch(fetchVisualizationAsync.request(undefined, undefined))
            dispatch(push(ROUTE_OVERVIEW))
        } else {
            dispatch(setVehicleCodeStatus({ status, rescueInfo, code: (action as any).meta?.causedBy.payload }))
        }
    }
}

export default vehicleCodeMiddleware
