import { createSelector } from 'reselect'
import { API_URL_CARLINES } from '../../../constants/apiUrls'
import pendingRequestsSelector from '../pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const carlinesRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_CARLINES),
)

export default carlinesRequestIsPendingSelector
