import { createSelector } from 'reselect'
import leasingMatrixSelector from './leasingMatrixSelector'
import configuredLeasingRateIdSelector from './configuredLeasingRateIdSelector'

const leasingMatrixRowIdxOfConfiguredRateIdSelector = createSelector(
    leasingMatrixSelector,
    configuredLeasingRateIdSelector,
    (leasingMatrix, configuredLeasingRateId): number => {
        if (!leasingMatrix || leasingMatrix.length === 0) {
            return -1
        }

        // eslint-disable-next-line max-len
        const index = leasingMatrix.findIndex((row) => row.rates.some((rate) => rate.id === configuredLeasingRateId))

        if (index !== -1) {
            return index
        }

        if (configuredLeasingRateId) {
            return -1
        }

        const defaultDuration = leasingMatrix.find((row) => row.duration === 6)

        return defaultDuration ? leasingMatrix.indexOf(defaultDuration) : 0
    },
)

export default leasingMatrixRowIdxOfConfiguredRateIdSelector
