import { Middleware } from 'redux'
import apiUiMiddleware from './apiUiMiddleware'
import entryDataMiddleware from './entryDataMiddleware'
import carlineDataMiddleware from './carlineDataMiddleware'
import modelDataMiddleware from './modelDataMiddleware'
import cartMiddleware from './cartMiddleware'
import equipmentMiddleware from './equipmentMiddleware'
import equipmentFilterMiddleware from './equipmentFilterMiddleware'
import summaryMiddleware from './summaryMiddleware'
import technicalDataMiddleware from './technicalDataMiddleware'
import renderImagesMiddleware from './renderImagesMiddleware'
import vehicleCodeMiddleware from './vehicleCodeMiddleware'
import priceModeMiddleware from './priceModeMiddleware'
import purchaseModeMiddleware from './purchaseModeMiddleware'
import sidebarMiddleware from './sidebarMiddleware'
import visualizationMiddleware from './visualizationMiddleware'
import configurationDataMiddleware from './configurationDataMiddleware'
import carlineSelectionMiddleware from './carlineSelectionMiddleware'
import infoModalMiddleware from './infoModalMiddleware'
import vatIncludedMiddleware from './vatIncludedMiddleware'
import errorMiddleware from './errorMiddleware'
import notificationMiddleware from './notificationMiddleware'
import checkoutPayloadMiddleware from './checkoutPayloadMiddleware'
import initMiddleware from './initMiddleware'
import abortMiddleware from './abortMiddleware'
import pdfMiddleware from './pdfMiddleware'
import conflictSolutionMiddleware from './conflictSolutionMiddleware'
import resetMiddleware from './resetMiddleware'
import filterMiddleware from './filterMiddleware'
import scrollTopMiddleware from './scrollTopMiddleware'
import efficiencyDataMiddleware from './efficiencyDataMiddleware'
import i18nMiddleware from './i18nMiddleware'
import brandMiddleware from './brandMiddleware'
import leasingMiddleware from './leasingMiddleware'

const appMiddleware: Middleware[] = [
    initMiddleware,
    i18nMiddleware,
    apiUiMiddleware,
    errorMiddleware,
    scrollTopMiddleware(window),
    brandMiddleware,
    notificationMiddleware,
    entryDataMiddleware,
    carlineDataMiddleware,
    carlineSelectionMiddleware,
    abortMiddleware,
    configurationDataMiddleware,
    modelDataMiddleware,
    cartMiddleware,
    equipmentMiddleware,
    equipmentFilterMiddleware,
    summaryMiddleware,
    technicalDataMiddleware,
    infoModalMiddleware,
    renderImagesMiddleware,
    checkoutPayloadMiddleware,
    pdfMiddleware(fetch, console),
    vehicleCodeMiddleware,
    priceModeMiddleware,
    purchaseModeMiddleware,
    sidebarMiddleware,
    visualizationMiddleware(window),
    efficiencyDataMiddleware,
    vatIncludedMiddleware,
    leasingMiddleware,
    conflictSolutionMiddleware,
    resetMiddleware,
    filterMiddleware,
]

export default appMiddleware
