import React, { FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import FastConfigurationIcon from '../../assets/media/fast-configuration.svg'
import style from './fastConfigurationButton.css'
import FastConfigurationModal from '../fast-configuration-modal'

export type Props = {
}

const FastConfigurationButton: FunctionComponent<Props> = () => {
    const modalActiveState = useState(false)
    const { t } = useTranslation()

    const clickHandler = (): void => {
        const setModalIsActive = modalActiveState[1]

        setModalIsActive(true)
    }

    return (
        <>
            <button
                onClick={clickHandler}
                className={style.button}
                type="button"
                data-cy="enter-vehicle-code-button"
            >
                <FastConfigurationIcon className={style.icon} />
                {t('fastConfigurationModal.title')}
            </button>
            <FastConfigurationModal activeState={modalActiveState} />
        </>
    )
}

export default FastConfigurationButton
