import React, { FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import VehicleCodeModal from '../vehicle-code-modal'
import CodeIcon from '../../assets/media/code.svg'
import CartboxButton from '../cartbox-button'

export type Props = {
    createVehicleCode: Function
    configurationIsBuildable: boolean
    brand: string
}

const VehicleCodeButton: FunctionComponent<Props> = (props) => {
    const { createVehicleCode, configurationIsBuildable, brand } = props
    const { t } = useTranslation()
    const openModalState = useState(false)
    const setOpenModalState = openModalState[1]

    const clickHandler = (): void => {
        createVehicleCode()
        setOpenModalState(true)
    }

    return (
        <>
            <CartboxButton
                onClick={clickHandler}
                disabled={!configurationIsBuildable}
                icon={<CodeIcon />}
                label={t(`vehicleCode.${brand}.create.buttonLabel`)}
                dataCy="cart-button-vehicle-code"
            />
            <VehicleCodeModal openModalState={openModalState} />
        </>
    )
}

export default VehicleCodeButton
