import { createSelector } from 'reselect'
import configurationIsBuildableSelector from './configuration/configurationIsBuildableSelector'
import everyEquipmentGroupStatusIsOkSelector from './equipments/everyEquipmentGroupStatusIsOkSelector'

const checkoutIsEnabledSelector = createSelector(
    configurationIsBuildableSelector,
    everyEquipmentGroupStatusIsOkSelector,
    (configurationIsBuildable, everyEquipmentGroupStatusIsOk): boolean => (
        everyEquipmentGroupStatusIsOk && configurationIsBuildable
    ),
)

export default checkoutIsEnabledSelector
