import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Modal from '../modal'
import style from './repurchaseExcludedModal.css'

export type Props = {
    isVisibleState: [boolean, Function]
    mode: string
}

const RepurchaseExcludedModal: FunctionComponent<Props> = (props) => {
    const {
        isVisibleState,
        mode,
    } = props
    const { t } = useTranslation()
    const [modalIsVisible, setModalIsVisible] = isVisibleState

    if (!modalIsVisible) {
        return null
    }

    const closeModal = (): void => {
        setModalIsVisible(false)
    }

    return (
        <Modal contentClassName={style.container}>
            <h2 className={style.title}>
                {t('buybackExclusion.title')}
            </h2>
            <Trans i18nKey={`buybackExclusion.text.${mode}`}>
                <p>string0</p>
                <p>string1</p>
                <p>string2</p>
            </Trans>
            <ul className={style.buttonList}>
                <li>
                    <Link to="/recommendations" className={style.button}>
                        {t('buybackExclusion.buttonAcceptLabel')}
                    </Link>
                </li>
                <li>
                    <button type="button" className={style.button} onClick={closeModal}>
                        {t('buybackExclusion.buttonDeclineLabel')}
                    </button>
                </li>
            </ul>
        </Modal>
    )
}

export default RepurchaseExcludedModal
