import { createSelector } from 'reselect'
import selectedCarlineIdSelector from './selectedCarlineIdSelector'
import configuredCarlineSymbolicIdSelector from './configuredCarlineSymbolicIdSelector'

const carlineSelectConfigMismatchSelector = createSelector(
    selectedCarlineIdSelector,
    configuredCarlineSymbolicIdSelector,
    (selectedCarlineId, configuredCarlineSymbolicId): boolean => (
        (!!configuredCarlineSymbolicId && selectedCarlineId !== configuredCarlineSymbolicId)
    ),
)

export default carlineSelectConfigMismatchSelector
