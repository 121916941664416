import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Navigation'
import abortUrlIsSetSelector from '../../redux/selectors/url/abortUrlIsSetSelector'
import sideNavLinksSelector from '../../redux/selectors/sideNavLinksSelector'
import modeSelector from '../../redux/selectors/modeSelector'

type StateProps = Pick<Props, 'navLinks' | 'abortIsEnabled' | 'mode'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    navLinks: sideNavLinksSelector(state),
    abortIsEnabled: abortUrlIsSetSelector(state),
    mode: modeSelector(state),
})

export default connect(mapStateToProps)
