import { createSelector } from 'reselect'
import { API_URL_MODELS } from '../../../constants/apiUrls'
import pendingRequestsSelector from '../pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const modelsRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_MODELS),
)

export default modelsRequestIsPendingSelector
