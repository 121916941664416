import { connect, MapStateToProps } from 'react-redux'
import filteredCarlineGroupsSelector from '../../redux/selectors/carlines/filteredCarlineGroupsSelector'
import carlineSelectionIsLoadingSelector from '../../redux/selectors/is-loading/carlineSelectionIsLoadingSelector'
import { Props } from './CarlineSelection'
import carlineGroupsDisplayModeSelector from '../../redux/selectors/carlines/carlineGroupsDisplayModeSelector'
import { setDisplayMode } from '../../redux/actions/app/carlineSelection.actions'

type StateProps = Pick<Props, 'carlineGroups' | 'displayMode' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    carlineGroups: filteredCarlineGroupsSelector(state),
    displayMode: carlineGroupsDisplayModeSelector(state),
    isLoading: carlineSelectionIsLoadingSelector(state),
})

type DispatchProps = Pick<Props, 'setDisplayMode'>

const mapDispatchToProps: DispatchProps = {
    setDisplayMode,
}

export default connect(mapStateToProps, mapDispatchToProps)
