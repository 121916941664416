import { createSelector } from 'reselect'
import leasingSelector from './leasingSelector'
import LeasingMatrix from '../../../entities/LeasingMatrix'

const leasingMatrixSelector = createSelector(
    leasingSelector,
    (leasing): LeasingMatrix => leasing.matrix,
)

export default leasingMatrixSelector
