import React, { FunctionComponent, ReactNode } from 'react'
import { Table, Td, Tr } from '../table'
import CheckIcon from '../../assets/media/check.svg'
import CancelIcon from '../../assets/media/cancel.svg'
import style from './check.css'
import config from '../../config'

const Check: FunctionComponent = () => {
    const createIconElement = (variable: string | undefined): ReactNode => (!variable
        ? <CancelIcon className={style.cancel} />
        : <CheckIcon className={style.check} />)

    /* eslint-disable @typescript-eslint/no-explicit-any */
    return (
        <article className={style.container}>
            <h1>Work up and running</h1>
            <p>
                Version:
                {' '}
                {config.APP_VERSION}
            </p>
            <h2>Environment variables</h2>
            <Table className={style.table}>
                <tbody>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(config.API_URL)}
                        </Td>
                        <Td className={style.td}>
                            API_URL
                        </Td>
                        <Td className={style.td}>
                            {config.API_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(config.TRANSLATION_SERVICE_URL)}
                        </Td>
                        <Td className={style.td}>
                            TRANSLATION_SERVICE_URL
                        </Td>
                        <Td className={style.td}>
                            {config.TRANSLATION_SERVICE_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(config.TRACKING_URL)}
                        </Td>
                        <Td className={style.td}>
                            TRACKING_URL
                        </Td>
                        <Td className={style.td}>
                            {config.TRACKING_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(config.PDF_SERVICE_URL)}
                        </Td>
                        <Td className={style.td}>
                            PDF_SERVICE_URL
                        </Td>
                        <Td className={style.td}>
                            {config.PDF_SERVICE_URL}
                        </Td>
                    </Tr>
                </tbody>
            </Table>
        </article>
    )
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

export default Check
