import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import {
    selectCarline,
    setActiveCarlineGroupId,
    setActiveCarlineGroupIdState,
    setDisplayMode,
    setDisplayModeState,
    setFiltersAreVisibleState,
    setSelectedCarlineState,
    toggleFiltersAreVisible,
} from '../../actions/app/carlineSelection.actions'
import { fetchModelsAsync } from '../../actions/app/models.actions'
import filtersAreVisibleSelector from '../../selectors/filtersAreVisibleSelector'
import { applyFilter } from '../../actions/app/filter.actions'
import { ROUTE_MODELS } from '../../../constants/routes'
import modeIsLeasingSelector from '../../selectors/feature-scope/modeIsLeasingSelector'
import {
    setLeasingMatrixState,
    setLeasingModalShouldBeShownState,
    showLeasingModal,
} from '../../actions/app/leasing.actions'
import leasingModalShouldBeShownSelector from '../../selectors/leasing/leasingModalShouldBeShownSelector'

const carlineSelectionMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(selectCarline, action)) {
        dispatch(setSelectedCarlineState(action.payload))

        const modeIsLeasing = modeIsLeasingSelector(getState())

        if (modeIsLeasing) {
            dispatch(setLeasingModalShouldBeShownState(true))
        }

        const leasingModalShouldBeShown = leasingModalShouldBeShownSelector(getState())

        if (modeIsLeasing && leasingModalShouldBeShown) {
            dispatch(showLeasingModal())
            dispatch(setLeasingMatrixState([]))
            dispatch(setLeasingModalShouldBeShownState(false))
            dispatch(push(ROUTE_MODELS))

            return
        }

        dispatch(fetchModelsAsync.request(action.payload))

        if (action.meta.forwardToModelPage) {
            dispatch(push(ROUTE_MODELS))
        }
    }

    if (isActionOf(setActiveCarlineGroupId, action)) {
        dispatch(setActiveCarlineGroupIdState(action.payload))
    }

    if (isActionOf(setDisplayMode, action)) {
        dispatch(setDisplayModeState(action.payload))
    }

    if (isActionOf(toggleFiltersAreVisible, action)) {
        const filtersAreVisible = filtersAreVisibleSelector(getState())

        dispatch(setFiltersAreVisibleState(!filtersAreVisible))
    }

    if (isActionOf(applyFilter, action)) {
        const filtersAreVisible = filtersAreVisibleSelector(getState())

        if (!filtersAreVisible) {
            dispatch(setFiltersAreVisibleState(true))
        }
    }
}

export default carlineSelectionMiddleware
