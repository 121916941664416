import { connect } from 'react-redux'
import { Props } from './FastConfigurationEntryPage'
import { initFromFastConfigurationEntryRoute } from '../../redux/actions/core/init.actions'

type DispatchProps = Pick<Props, 'initFromFastConfigurationEntryRoute'>

const mapDispatchToProps: DispatchProps = {
    initFromFastConfigurationEntryRoute,
}

export default connect(undefined, mapDispatchToProps)
