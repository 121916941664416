import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import NewConfigurationIcon from '../../assets/media/new-configuration-small.svg'
import CartboxButton from '../cartbox-button'

export type Props = {
    resetConfiguration: Function
}

const ResetConfigurationButton: FunctionComponent<Props> = (props) => {
    const { resetConfiguration } = props
    const { t } = useTranslation()

    const clickHandler = (): void => {
        resetConfiguration()
    }

    return (
        <CartboxButton
            onClick={clickHandler}
            icon={<NewConfigurationIcon />}
            label={t('configuration.reset')}
            dataCy="cart-button-new-config"
        />
    )
}

export default ResetConfigurationButton
