import { createSelector } from 'reselect'
import configurationDataSelector from './configurationDataSelector'
import Equipment from '../../../entities/Equipment'

const foresightedBuildabilityTriggerEquipmentSelector = createSelector(
    configurationDataSelector,
    (configurationData): Equipment | null => {
        if (configurationData.conflictInformation === undefined) {
            return null
        }

        return configurationData.conflictInformation.trigger
    },
)

export default foresightedBuildabilityTriggerEquipmentSelector
