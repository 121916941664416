import { Middleware } from 'redux'
import apiMiddleware from './apiMiddleware'
import persistingMiddleware from './persistingMiddleware'

const coreMiddleware: Middleware[] = [
    persistingMiddleware,
    apiMiddleware(fetch, console),
]

export default coreMiddleware
