import { createSelector } from 'reselect'
import summarySelector from './summarySelector'
import GeneralInformation from '../../entities/GeneralInformation'

const generalInformationSelector = createSelector(
    summarySelector,
    (summary): GeneralInformation[] => summary.generalInformation,
)

export default generalInformationSelector
