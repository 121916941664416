import { useTranslation as useI18nTranslation, UseTranslationResponse, UseTranslationOptions } from 'react-i18next'
import i18n from 'i18next'

const useTranslation = (options?: UseTranslationOptions): UseTranslationResponse<'micon-app'> => useI18nTranslation('micon-app', {
    ...options,
    useSuspense: false,
})

export const translate = (key: string, params?: {}): string | null => {
    const result = i18n.t(key, params)

    return result === key ? null : result
}

export default useTranslation
