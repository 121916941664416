import EquipmentFamily from './EquipmentFamily'

export const EQUIPMENT_GROUP_STATUS_OK = 'OK'
export const EQUIPMENT_GROUP_STATUS_INCOMPLETE = 'INCOMPLETE'
export const EQUIPMENT_GROUP_STATUS_ERRONEOUS = 'ERRONEOUS'
export const EQUIPMENT_GROUP_STATUS_NONE = 'NONE'

export type EquipmentGroupsStatus = typeof EQUIPMENT_GROUP_STATUS_OK |
    typeof EQUIPMENT_GROUP_STATUS_INCOMPLETE |
    typeof EQUIPMENT_GROUP_STATUS_ERRONEOUS |
    typeof EQUIPMENT_GROUP_STATUS_NONE

type EquipmentGroup = {
    id: string
    name: string
    status: EquipmentGroupsStatus
    equipmentFamilies: EquipmentFamily[]
}

export default EquipmentGroup
