import { connect, MapStateToProps } from 'react-redux'
import { Props } from './FastConfigurationModal'
import { putFastConfigurationAsync } from '../../redux/actions/app/configuration.actions'

type StateProps = Pick<Props, never>

const mapStateToProps: MapStateToProps<StateProps, {}> = () => ({
})

type DispatchProps = Pick<Props, 'putFastConfiguration'>

const mapDispatchToProps: DispatchProps = {
    putFastConfiguration: putFastConfigurationAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
