import React, { FunctionComponent, ReactElement } from 'react'
import style from './cartboxEmissionConsumption.css'
import { CartEmissionConsumption } from '../../entities/CartEmissionConsumption'

export type Props = {
    emissionConsumption: CartEmissionConsumption[]
}

const CartboxEmissionConsumption: FunctionComponent<Props> = (props) => {
    const { emissionConsumption: emissionConsumptionEntries } = props

    if (emissionConsumptionEntries.length === 0) {
        return null
    }

    const getBlockEntries = (entries: CartEmissionConsumption['entries']): ReactElement => (
        <>
            {entries.map((entry) => (
                <dl className={style.blockEntry} key={entry.key}>
                    <dt className={style.entryKey}>{entry.key}</dt>
                    <dd className={style.entryValue}>{entry.value}</dd>
                </dl>
            ))}
        </>
    )

    const emissionConsumptionBlocks = emissionConsumptionEntries.map((emissionConsumptionEntry) => (
        <li key={emissionConsumptionEntry.headline}>
            <h5 className={style.headline}>
                {emissionConsumptionEntry.headline}
            </h5>
            {getBlockEntries(emissionConsumptionEntry.entries)}
        </li>
    ))

    return (
        <ul className={style.container}>
            {emissionConsumptionBlocks}
        </ul>
    )
}

export default CartboxEmissionConsumption
