/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LeasingMatrix {
  duration?: number;
  rates?: LeasingRate[];
}

export interface LeasingRate {
  /** @example "8vf_2020_240_6" */
  id?: string;
  /** @example "250.0 EUR" */
  rateAmount?: string;
  /** @example "2000.000 km" */
  mileagePerDuration?: string;
  /** @example "8000 km" */
  mileage?: string;
}

export type LeasingMatrixResponse = LeasingMatrix[];

export interface InitSessionResponse {
  sessionId: string;
  /** @example "de" */
  language?: string;
  country?: string;
  featureScopes: string[];
  priceViewFlags?: PriceViewFlag[];
  additional?: KeyValue[];
  /** @example {"abortUrl":"https://my-int.man-mn.com?configuration_process=canceled","orderUrl":"https://my-int.man-mn.com?configuration_process=finished&audicode="} */
  links?: KeyValue[];
  /**
   * view filters for differents pages like carline or model
   * @example [{"attribute":"engine-type","type":"multiple"},{"attribute":"gear-type","type":"multiple"},{"attribute":"emission-max","type":"max"}]
   */
  viewFilters?: Record<string, Filter[]>;
  /** returns a list of attributes to show on model page */
  viewAttributes?: string[];
}

export interface KeyValue {
  /** @example "ID" */
  id?: string;
  /** @example "VALUE" */
  value?: string;
}

export interface Filter {
  attribute?: string;
  type?: string;
}

export interface CarlinesResponse {
  carlineGroups?: CarlineGroup[];
}

export interface CarlineGroup {
  /** @example "a3" */
  id: string;
  /** @example "Audi A3" */
  name?: string;
  media?: Media[];
  carlines?: Carline[];
}

export interface Carline {
  /**
   * carline id
   * @example "50210-2020"
   */
  id: string;
  /** @example "A3 Sportback" */
  name?: string;
  /** @example "50210" */
  mbvHandbook: string;
  /** @example "2020" */
  mbvModelYear: string;
  media?: Media[];
  /** @example {"engine-type":["diesel","gas"],"gear-type":["automatic"]} */
  attributes?: Record<string, string[]>;
}

export interface ModelsResponse {
  models?: Model[];
  remarks?: Remark[];
}

export interface Model {
  /**
   * The unique model id.
   * @example "8vfanc-0-2020"
   */
  id: string;
  /**
   * model id for display, including version and extensions
   * @example "8VFANC\0"
   */
  mbvId: string;
  /** @example "30 TFSI" */
  name?: string;
  /**
   * The mbv list price with currency
   * @example "20697.12 EUR"
   */
  price?: string;
  media?: Media[];
  attributes?: Attribute[];
  /** indicates whether item needs to be selected or not */
  forced?: boolean;
}

export interface Attribute {
  /** @example "engine-type" */
  id: string;
  /** @example "109" */
  value: any;
  /** @example "cm/kg" */
  unit?: string;
  /** @example "109 cm/kg" */
  formattedValue?: string;
  /** @example "REMARK_1" */
  nameRemarkRefId?: string;
  /** @example null */
  valueRemarkRefId?: string;
}

export interface TechnicalDataResponse {
  technicalData?: Section[];
  remarks?: Remark[];
}

export interface Section {
  /** @example "wltp" */
  id: string;
  /** @example "Verbrauchs- und Emissionswerte nach WLTP" */
  headline: string;
  /** @example "https://www.audi.de/de/brand/de/neuwagen/layer/wltp-lp.html" */
  link?: string;
  remarkRefId?: string;
  entry?: Entry[];
}

export interface Entry {
  /** @example "wltp-combined-consumption" */
  id: string;
  /** @example "WLTP Verbrauch kombiniert" */
  name: string;
  /** @example "9,1 l/100 km" */
  value: string;
  /** @example "REMARK_1" */
  nameRemarkRefId?: string;
  valueRemarkRefId?: string;
  media?: Media[];
}

export interface Remark {
  /** @example "REMARK_1" */
  id?: string;
  /** @example "remark.wltp.text" */
  key?: string;
}

export interface EfficiencyDataResponseRaw {
  /**
   * energy label
   * @example "A"
   */
  energyEfficiencyClass?: string;
  /** a list of vehicle/configuration fuel types */
  fueltypes?: FuelType[];
}

/** @example {"efficiencyGroups":[{"id":"nedc","headline":"Verbrauchs- und Emissionswerte nach NEFZ","efficiencyNodes":[{"id":"nedc-consumption-combined","label":"kombiniert","value":"1,4 l/100km"},{"id":"nedc-co2-combined","label":"\n\t\t\t\tCO<sub>2</sub>-Emissionen kombiniert\n\t\t\t","value":"30 g/km"},{"id":"nedc-electric-consumption-combined","label":"Stromverbrauch kombiniert","value":"13.0 kWh/100 km"},{"id":"emission-class","label":"Emissionsklasse","value":"Euro 6d-ISC-FCM"}]},{"id":"wltp","headline":"Verbrauchs- und Emissionswerte nach WLTP","efficiencyNodes":[{"id":"wltp-consumption-weighted-combined","label":"Kombiniert","value":"1,0 l/100km"},{"id":"wltp-co2-weighted-combined","label":"CO<sub>2</sub>-Emissionen kombiniert","value":"24 g/km"},{"id":"wltp-electric-range-equiv-all-el-combined","label":"Rein elektrische Reichweite (kombiniert in km)","value":"67 km"},{"id":"wltp-electric-range-equiv-all-el-city","label":"Rein elektrische Reichweite (innerorts in km, EAER City nach WLTP","value":"75 km"}]}]} */
export interface EfficiencyDataResponseFormatted {
  /** the formatted efficiecy data */
  efficiencyGroups?: EfficiencyGroup[];
}

export interface EfficiencyGroup {
  id?: string;
  headline?: string;
  efficiencyNodes?: EfficiencyNode[];
}

export interface EfficiencyNode {
  id?: string;
  label?: string;
  value?: string;
}

/** contains full informations about certain fuel of vehicle */
export interface FuelType {
  /**
   * provides a list of consumption informations
   * @example [{"id":"WLTP_COMBINED:WLTP_CONSUMPTION","value":"2,1","unit":"l/100km"},{"id":"COMBINED:VERBRAUCH","value":"2,0","unit":"l/100 km"}]
   */
  consumptions?: Item[];
  /**
   * provides a list of emissions informations
   * @example [{"id":"WLTP_COMBINED:WLTP_CO2","value":"49","unit":"g/km"},{"id":"COMBINED:CO2","value":"46","unit":"g/km"}]
   */
  emissions?: Item[];
  id: string;
  /**
   * fuel name
   * @example "Benzin(Benzin)"
   */
  name: string;
}

/** passenger car energy consumption labelling ordinance */
export interface EnvkvData {
  /**
   * provides a list with consumption yearly costs. suitable for bivalent/hybrid vehicles
   * @example [{"label":"fuel","price":{"amount":580,"currencyCode":"EUR"}},{"label":"electro","price":{"amount":1057,"currencyCode":"EUR"}}]
   */
  consumptionCostList?: EnvkvInfo[];
  /**
   * provides a list with current fuel costs. suitable for bivalent/hybrid vehicles
   * @example {"label":"fuel","price":{"amount":1.45,"currencyCode":"EUR"}}
   */
  fuelCostList?: EnvkvInfo[];
  /**
   * deprecated. replaced by Efficiency.fueltypes
   * @example "Benzin"
   */
  fuelType?: string;
  /** miliage for the calculation of energy label */
  milageData?: MilageData;
  /**
   * pollution badge
   * @example "Euro 6d-TEMP-EVAP-ISC"
   */
  pollutionBadge?: Item;
  /**
   * yearly tax informations
   * @example {"price":{"amount":40,"currencyCode":"EUR"}}
   */
  yearlyTax?: EnvkvInfo;
}

/**
 * miliage for the calculation of energy label
 * @example {"unit":"km","milage":"20000"}
 */
export interface MilageData {
  formatted?: string;
  infoText?: string;
  milage?: string;
  unit?: string;
}

export interface EnvkvInfo {
  label?: string;
  price?: EnvkvPriceItem;
}

export interface EnvkvPriceItem {
  /** @format double */
  amount?: number;
  currencyCode?: string;
  formatted?: string;
  type?: string;
}

export interface Item {
  id: string;
  name?: string;
  unit?: string;
  value?: string;
  remarkIds?: string[];
}

/**
 * the flag indicates the current price view e.g. price or rate in brutto or netto
 * @example "PRICE_BRUTTO"
 */
export enum PriceViewFlag {
  PRICE_BRUTTO = "PRICE_BRUTTO",
  RATE_BRUTTO = "RATE_BRUTTO",
  PRICE_NETTO = "PRICE_NETTO",
  RATE_NETTO = "RATE_NETTO",
  PRICE_OTR = "PRICE_OTR",
  PRICE_WLTP = "PRICE_WLTP",
}

export interface CartResponse {
  /** the flag indicates the current price view e.g. price or rate in brutto or netto */
  priceViewFlag?: PriceViewFlag;
  /** @example "Audi A3 Sportback 30 TDI 85(116) kW(CV) 6 vel." */
  cartName?: string;
  /** @example [{"id":"modelPrice","value":"30.000,00 EUR","isSum":false},{"id":"colorsPrice","value":"560,00 EUR","isSum":false},{"id":"equipmentsPrice","value":"2.000,00 EUR","isSum":false},{"id":"totalPrice","value":"32.560,00 EUR","isSum":true}] */
  prices?: CartPrice[];
}

export interface CartPrice {
  /**
   * is the sum of all selected equipments except AOZ with currency
   * @example "totalPrice"
   */
  id?: string;
  /**
   * is the sum of all selected equipments except AOZ with currency
   * @example "32.560,00 EUR"
   */
  value?: string;
  /** tbd */
  isSum?: boolean;
}

export interface EquipmentResponse {
  equipmentGroups?: EquipmentGroup[];
}

export interface EquipmentGroup {
  /** @example "exterior" */
  id?: string;
  /**
   * Name of the mbv lasche
   * @example "exterior.exterior"
   */
  name?: string;
  status?: "OK" | "INCOMPLETE" | "ERRORNEOUS" | "NONE";
  equipmentFamilies?: EquipmentFamily[];
  /**
   * Used for general info in summary if no winter wheels were selected but they are mandatory for tenant
   * @example "Please add winter wheels to your configuration"
   */
  info?: string;
}

export interface EquipmentFamily {
  /** @example "Infotainment/Technology" */
  id?: string;
  /** @example "Infotainment" */
  name?: string;
  equipments?: Equipment[];
}

export interface Equipment {
  /**
   * The mbv id. Internal id
   * @example "F14-0C0C"
   */
  id: string;
  /**
   * The name
   * @example "Monsoon Gray Metallic"
   */
  name: string;
  /**
   * The eva key. Display id
   * @example "0C0C"
   */
  evaKey?: string;
  /** the item is selected */
  selected?: boolean;
  /** the force a conflict if select the item */
  conflicted?: boolean;
  /** true means the item is not changeable, e.g. mandatory equipment or standard equipment */
  selectable?: boolean;
  /** true means the item is hidden */
  stealth?: boolean;
  /** true means the item is a standard equipment */
  isStandard?: boolean;
  /** true means the item is a package */
  isPackage?: boolean;
  /**
   * the prnr that replaced this equipment
   * @example "GWQSWQS"
   */
  replacedBy?: string;
  /**
   * The mbv list price including the currency
   * @example "3.650,00 EUR"
   */
  price?: string;
  /**
   * the maximal count of this equipment, only available for accessories
   * @example 4
   */
  count?: number;
  media?: Media[];
  /** The content of the package. */
  content?: PackageContent[];
  /**
   * e.g. the conleos color code if an exclusive exterior color is selected
   * @example {"conleos_color_code":"XYZ"}
   */
  additionalInformation?: object;
  /** indicates whether item needs to be selected or not */
  forced?: boolean;
}

export interface PackageContent {
  /**
   * name
   * @example "tbd"
   */
  name: string;
  /**
   * The id
   * @example "tbd"
   */
  id: string;
}

export interface Media {
  /**
   * unique id of the media
   * @example "ak_headline"
   */
  id?: string;
  /**
   * label of the media
   * @example "A1 Sportback Abmessungen aussen"
   */
  label?: string;
  /**
   * text, image, audio, video
   * @example "image"
   */
  type?: string;
  /** if forced, this medium must be displayed. if its model or prnr  is selected this will usually be a text containing some kind of warning. */
  forced?: boolean;
  /** The text can only be set if the property path is missing */
  text?: string;
  /** The path to the image can only be set if the property text is missing */
  path?: string;
  /** efficiency label information e.g. wheel-label */
  efficiencyLabel?: {
    /** @example "label-image" */
    id?: string;
    /** @example "https://performance.oneapi.volkswagen.com/relas/render/tyrelabel/tbd/20211231?language=ch-de" */
    path?: string;
  }[];
}

export interface StateConfigurationResponse {
  /**
   * the configuration string contains the selected model, interior, exterior and equipment if selected
   * @example "8VFANC\0|F14 2Y2Y|F56     EI|MCOCC45|MRADC7P|MASGEB4"
   */
  configurationString?: string;
  /**
   * the id of the carline that contains the selected model
   * @example "50210-2020"
   */
  carlineId?: string;
  /**
   * the id of the selected model
   * @example "8vfanc-0-2020"
   */
  modelId?: string;
  orderKey?: OrderKeyResponse;
  /** @example "CONFLICT" */
  configurationState?: "INCOMPLETE" | "CONFLICT" | "BUILDABLE" | "MODEL_CONFLICT" | "NOT_REPAIRABLE" | "UNKNOWN";
  /**
   * indicates whether configuration is repairable (vbbk has no valid solutions)
   * @example true
   */
  notRepairable?: boolean;
  /** the conflict item, only if the configuration state is CONFLICT */
  conflictInformation?: {
    conflictSteps?: ConflictStep[];
    /** indicates a conflict tree as resolvable and the configuration is buildable after accepting the config changes, if the field value is 'false', the conflict solution is not adoptable */
    resolvable?: boolean;
    /** total price of every selected conflict step item */
    total?: string;
    hiddenFields?: string[];
    trigger?: Equipment;
  };
}

export interface OrderKeyResponse {
  combined?: string;
  model?: string;
  exterior?: string;
  interior?: string;
  equipments?: string;
}

export interface FastConfigurationKey {
  model?: string;
  exteriorColor?: string;
  interiorColor?: string;
  equipments?: string;
}

export interface ConflictItem {
  /**
   * ADD or REMOVE the ID to solve the conflict step
   * @example "ADD"
   */
  conflictType?: "ADD" | "REMOVE";
  /**
   * field is relevant for the foresighted buildability only
   * @example true
   */
  selected?: boolean;
  equipment?: Equipment;
  /** indicates whether item needs to be selected or not */
  forced?: boolean;
}

export interface ConflictStep {
  /** list of conflict items per conflict step */
  conflictItems?: ConflictItem[];
}

export interface ConfigurationSummary {
  informations?: ConfigurationSummaryInformation[];
  remarks?: Remark[];
  generalInformation?: SummaryInformation[];
}

export interface CheckoutPayload {
  /**
   * url that the frontend posts to
   * @example "https://pre.weareaudi.com/501_mfc/o/Jump/"
   */
  targetUrl?: string;
  checkoutBody?: CheckoutBody;
}

export interface CheckoutBody {
  ziel_app?: string;
  zielgr?: string;
  context?: string;
  weitervermarktung?: boolean;
  kd_vorhanden?: boolean;
  cus_mandatory?: boolean;
  zweit_pdw?: boolean;
  bestellt_fuer?: string;
  erwerbsart?: string;
  car_group?: number;
  return_url_mfc?: string;
  return_url_mfc_mot1?: string;
  return_url_mfc_mot2?: string;
  return_url_mfc_conx?: string;
  keep_alive_url_mfc?: string;
  cus_type?: number;
  kommissionsnummer?: string;
  kommissionsjahr?: string;
  token?: string;
  tokenSignature?: string;
  "portal-id"?: string;
  "app-id"?: string;
  audicode?: string;
  quell_app?: string;
  keep_alive_url_cc?: string;
  keep_alive_url_cars?: string;
  return_url_cc?: string;
  return_url_cars?: string;
  d_trace?: number;
}

export interface SummaryInformation {
  key?: string;
  payload?: SummaryInformationPayload;
}

export interface SummaryInformationPayload {
  value?: string;
}

export interface ConfigurationSummaryInformation {
  /** @example "EEC-Class" */
  label?: string;
  /** @example "B" */
  value?: string;
  image?: string;
  /** @example "REMARK_1" */
  labelRemarkRefId?: string;
  /** @example null */
  valueRemarkRefId?: string;
}

export interface FullConfigurationResponse {
  accessories?: EntriesSection;
  audiCodeSection?: AudiCodeSection;
  eec?: EEC;
  exterior?: GroupedEntries;
  finalDisclaimers?: {
    headline?: string;
    disclaimers?: string[];
  }[];
  finance?: {
    headline?: string;
    footnotes?: string[];
    parameters?: GroupedLabelValue[];
    productName?: string;
  };
  /** @example "Hinweis:" */
  footnoteHeadline?: string;
  /** @example "Zusammenfassung" */
  summaryHeadline?: string;
  header?: ConfigurationHeader;
  interior?: GroupedEntries;
  mandatories?: EntriesSection;
  media?: Image[];
  /** @example "Motor" */
  modelHeadline?: string;
  modelName?: {
    /** @example "4A2AGA\1-50750\2020" */
    id?: string;
    /** @example ["50 TDI quattro tiptronic","Leistung: 210(286) kW(PS)"] */
    name?: string[];
    /** @example "59.290,00 EUR" */
    price?: string;
  };
  options?: EntriesSection;
  /** @example [{"label":"Rate (mit PrivatLeasing)","value":"z. B. mtl. 531,77 EUR"}] */
  otherPrices?: LabelValue[];
  standards?: EntriesSection;
  tableHeader?: {
    /** @example "Beschreibung" */
    description?: string;
    /** @example "Gesamtpreis" */
    priceLabel?: string;
    /** @example "Produktnr." */
    productNo?: string;
  };
  modelSummaryTechdata?: LabelValue[];
  technicalData?: {
    footnotes?: string[];
    headline?: string;
    lines?: GroupedLabelValue[];
  };
  totalPrice?: LabelValue;
  tyreInfos?: {
    pageHeadline?: string;
    infoHeadline?: string;
    dataHeadline?: string;
    multipleHeadline?: string;
    multipleText?: string;
    regularTyre?: string;
    extraTyre?: string;
    aozTyre?: string;
    tyreInfoData?: TyreInfoData[];
  };
  replacedByPrefix?: string;
}

export interface EntriesSection {
  footnotes?: string[];
  headline?: string;
  lines?: GroupedEntries[];
}

export interface GroupedEntries {
  headline?: string;
  entries?: GroupedEntry[];
  image?: {
    exteriorImage?: Image;
    interiorImage?: Image;
  };
}

export interface GroupedEntry {
  id?: string;
  image?: Image;
  name?: string[];
  price?: string;
  replacedBy?: string;
}

export interface ConfigurationHeader {
  /** @example "DE" */
  countryCode?: string;
  /** @example "2020-03-06" */
  date?: string;
  /** @example "de" */
  language?: string;
  /** @example "A6 Limousine" */
  name?: string;
  /** @example "Seite" */
  page?: string;
  /** @example "Audi Dealer Car Configurator" */
  title?: string;
  /** @example "AUDI" */
  brand?: string;
}

export interface EEC {
  /** @example [{"label":"Kraftstoffverbrauch kombiniert","value":"5,6 l/100 km "},{"label":"CO<sub>2</sub>-Emission kombiniert","value":"146 g/km  (Euro 6d-TEMP-EVAP-ISC)"},{"label":"Verbrauch innerorts","value":"6,1 l/100 km "},{"label":"Verbrauch ausserorts","value":"5,2 l/100 km "}] */
  consumptionLines?: LabelValue[];
  /** @example "Auf der Grundlage der gemessenen CO<sub>2</sub>-Emissionen unter Berücksichtigung der Masse des Fahrzeugs ermittelt." */
  disclaimer?: string;
  /** @example "A" */
  eecClass?: string;
  /** @example "CO<sub>2</sub>-Effizienz<br>nach NEFZ" */
  label?: string;
  /** @example "http://mediaservice.audi.com/media//b2c/v6-common/img-HEAD.zip/v6/new_eec/eec-label-none.png" */
  efficiencyImage?: string;
  /** @example [{"label":"Jahressteuer für dieses Fahrzeug","value":"459 EUR"},{"label":"Energieträgerkosten bei einer Laufleistung von 20.000 km:","value":""},{"label":"Kraftstoffkosten (Diesel schwefelfrei) bei einem Kraftstoffpreis von 1,28 Euro/Liter","value":"1.434 EUR"}] */
  priceLines?: LabelValue[];
}

export interface TyreInfoData {
  header?: string;
  isRegularTyre?: boolean;
  isExtraTyre?: boolean;
  isAozTyre?: boolean;
  tyreInfos?: TyreInfos[];
}

export interface TyreInfos {
  prnr?: string;
  name?: string;
  tyreLabels?: TyreLabels[];
}

export interface TyreLabels {
  label?: string;
  pdf?: string;
}

export interface GroupedLabelValue {
  entries?: LabelValue[];
  headline?: string;
}

export interface LabelValue {
  label?: string;
  value?: string;
}

export interface AudiCodeSection {
  headline?: string;
  /** @example "ABCDEFGH" */
  audiCode?: string;
  whatIsAudiCodeHeadline?: string;
  whatIsAudiCodeText?: string;
  /** @example "www.audi.de/A0YE8P3E" */
  audiUrl?: string;
}

export interface RenderResponse {
  /** the selected view, will change with every equipment change if the equipment change triggers a new view */
  selectedView?: "exterior" | "interior" | "wheels-closeup" | "lights-closeup" | "exterior-straight_back" | "dashboard";
  /**
   * the ave string for the miwi
   * @example "50400,8WCBPG,2020,Q0Q0,YM,PR9,W1E,Y09,Y46,Z35,E0A,6H0,1PA,7IA,0P0,4BF,04A,1D0,4UF,QE0,6XK,EA0,5SL,6TS,0K4,1X0,9WC,A9D,6K8,1KD,J2D,1LA,6FA,0UA,NY0,B0A,1S2,0VC,2H0,C87,0C0,IT3,IU2,JE3,IW3,1BA,3S0,3FA,GT0,6F0,2G1,7AA,5MA,7M1,7X1,7W0,0FA,6A0,VL1,VF0,8K4,FP1,UH1,5L0,7G0,F0X,9G7,0G7,0YF,0JG,T5H,8T6,VC0,5A8,G1C,6Y1,GH0,6V1,A51,5J0,6NJ,3ND,9AK,8EX,KS0,U5A,4L6,K8B,QK1,6SS,0M1,4H3,0Y1,QR8,4D0,GB1,5ZF,1Z1,A60,1A2,5C0,3G0,KA0,KK3,6W6,5K5,3GA,QQ0,FU0,L0L,QZ7,3H0,8G1,7P0,1XW,AV1,8RM,7HA,NM1,6E1,FM0,9S7,8Z5,IX1,M3I,9K0,7UG,FZ1,GP1,FQ1,AQ0,F43,9JA,ER1,7K1,HZ1,V0A,1G8,4X3,A00,2KR,8VG,FK0,QL1,4E2,QI6,2K5,2F0,9P6,N2M,3L3,4A3,0LW,7L8,8Y1,SB5,5XK,7Y0,6I0,4KC,3Y4,FA0,8X0,3M3,6JD,4I7,B01,5D1,NT0,Q0D,1VG,1SA,1EX,2Z8,1T3,Q1A,9ZE,EW0,7S0,7E0,4GF,2WA,0TD,4ZE,6EP,3B3,0RZ,VH0,9M0"
   */
  aveString?: string;
  renderImages?: Image[];
}

export interface Image {
  /** @example "dashboard" */
  id?: string;
  /** @example {"1280":"https://mediaservice.audi.com/media/live/50000/xc/4a2adg-1/2020/14+2y2y/aaue0a/aer0p0/ahv1d4/ata1x0/awv6k8/bav1lc/bbo6fa/dar3s0/dei3fa/eph9x0/fsp5l0/gmot98/hes5j0/hsw8it/kark8b/kasqk1/ksuka6/kzv6w6/lia8g5/rad42v/sbr8sq/sctql1/sfs2k1/spu7y0/ssh4kc/szu0na/tyz2z8/zie4zc.png"} */
  url: object;
}

export interface VehicleCode {
  /** @example "ABCDEFGH" */
  vehicleCode?: string;
}

export interface VehicleCodeResponse {
  /**
   * State of the given vehicle code
   * @example "OK"
   */
  status: "OK" | "NOT_VALID" | "RESCUE";
  rescueInfo?: {
    /** is the given vehicle code repairable */
    repairable?: boolean;
    /** has model version changed e.g. during model year change? */
    softModelChange?: boolean;
    /**
     * a list of unknown items and cannot be determined with the current catalog data
     * @example ["MRAD123"]
     */
    unknownItems?: string[];
    /**
     * a list of known items which have to be add to the stored configuration to make the stored configuration buildable
     * @example ["MVOS456"]
     */
    itemsToAdd?: string[];
    /**
     * a list of known items which have to be removed from the stored configuration to make the stored configuration buildable
     * @example ["MSIB678"]
     */
    itemsToRemove?: string[];
  };
}

export interface FinancingObject {
  finance?: Finance;
}

export interface Finance {
  toBeDefined?: string;
}

export interface CarstoreResponse {
  carstores?: CarstoreSlot[];
}

export interface CarstoreSlot {
  /**
   * the slot id for the configuration in the carstore slot
   * @example "01"
   */
  slotId: string;
  /**
   * the vehicle code for the configuration in the carstore slot
   * @example "ABCDEFGH"
   */
  vehicleCode: string;
  /**
   * the model name for the configuration in the carstore slot
   * @example "Audi R8 Coupe V10 Quattro 419(570) kW(PS) S tronic"
   */
  modelName?: string;
  /**
   * the exterior color name for the configuration in the carstore slot
   * @example "Aralblau Kristalleffekt"
   */
  exteriorColorName?: string;
  /**
   * the interior name for the configuration in the carstore slot
   * @example "Aralblau Kristalleffekt"
   */
  interiorName?: string;
  /**
   * the total price for the configuration in the carstore slot
   * @example "177.420,00 EUR"
   */
  totalPrice?: string;
  /**
   * the render image url for the configuration in the carstore slot
   * @example "https://pre-mediaservice.audi.com/media/live/50000/xm/4spree-0/2020/14+k6k6/aaue0a/abo6h6/aer0p9/ata1x1/bav1la/bbo6fj/eph7x2/fsp5l1/gra8t2/hes5j2/hsw8it/ksuka0/kzv6w1/lia8g0/rad45d/zie4za.png?alt=//pre-mediaservice.audi.com/media/b2c/v6-common/img-HEAD.zip/v6/de/no-exterior-de-160x90.jpg"
   */
  imageUrl?: string;
  equipmentNames?: string[];
  /**
   * the user comment for the configuration in the carstore slot if available
   * @example "Das ist ein Kommentar"
   */
  comment?: string;
}

export interface LastConfigurationSlot {
  /** @format date-time */
  lastAccessDate?: string;
  /**
   * the vehicle code for the configuration in the carstore slot
   * @example "ABCDEFGH"
   */
  vehicleCode?: string;
  /**
   * the model name for the configuration in the carstore slot
   * @example "Audi R8 Coupe V10 Quattro 419(570) kW(PS) S tronic"
   */
  modelName?: string;
  /**
   * the total price for the configuration in the carstore slot
   * @example "177.420,00 EUR"
   */
  totalPrice?: string;
  /**
   * the render image url for the configuration in the carstore slot
   * @example "https://pre-mediaservice.audi.com/media/live/50000/xm/4spree-0/2020/14+k6k6/aaue0a/abo6h6/aer0p9/ata1x1/bav1la/bbo6fj/eph7x2/fsp5l1/gra8t2/hes5j2/hsw8it/ksuka0/kzv6w1/lia8g0/rad45d/zie4za.png?alt=//pre-mediaservice.audi.com/media/b2c/v6-common/img-HEAD.zip/v6/de/no-exterior-de-160x90.jpg"
   */
  exteriorImageUrl?: string;
  /**
   * the render image url for the configuration in the carstore slot
   * @example "https://pre-mediaservice.audi.com/media/live/50000/ym/gabafg-1/2020/14+k6k6/56+zf/aib4uf/algqe0/ase6xc/ata1x0/bbo6fa/bfa0ua/cha2h0/chrqj0/dei3fa/dfo6f0/eih5ma/eph7x0/essue3/fsbuh1/gmotj1/gra8t9/gspg1c/him6nj/hkakh6/hudks0/insu5a/irs4l2/kasqk0/kds4h3/kov5zf/ksi3g0/lcpqq0/lra1mt/lse8re/lum7ha/mas6e0/mfa9s4/moaix2/nav7q0/raoi7y/rau9ja/sdh4e0/sibn1s/sie3l3/sih4a0/sna7l6/spu7y0/ssh4kc/tkv4i2/vosq4h/vtv9w0/zfm0td.png?alt=http://pre-mediaservice.audi.com/media/b2c/v6-common/img-HEAD.zip/v6/de/no-interior-de-160x90.jpg"
   */
  interiorImageUrl?: string;
}

export interface CarstoreComment {
  /** @example "ABCDEFGH" */
  vehicleCode?: string;
  /** @example "ich bin ein Kommentar" */
  comment?: string;
}

export interface ErrorResponse {
  /** @format int32 */
  code: number;
  message: string;
  description?: string;
  details?: object;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:8080/api/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title MICON BFF
 * @version 0.1
 * @termsOfService http://swagger.io/terms/
 * @baseUrl http://localhost:8080/api/v1
 * @contact <team-b2b@dasburo.com>
 *
 * This is the BFF (backend for frontend) for the MICON app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  entry = {
    /**
     * @description Init the user session and returns context specific settings and the generated session id
     *
     * @tags init
     * @name InitSession
     * @summary Init the user session
     * @request GET:/entry/{contextId}
     * @secure
     */
    initSession: (
      contextId: string,
      query?: {
        /** token for oath2 sso */
        token?: string;
        /** indicates whether incoming entry request is from zweit_pdw, required for checkout system */
        zweit_pdw?: boolean;
        /** provides the url for jumping back into AGIS system */
        return_url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InitSessionResponse, any>({
        path: `/entry/${contextId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  catalog = {
    /**
     * @description Returns a structure of carline-Groups and carlines for the required session id
     *
     * @tags catalog
     * @name GetCarlineGroupStructure
     * @summary Get structure of carline-groups and carlines
     * @request GET:/catalog/carlines
     * @secure
     */
    getCarlineGroupStructure: (params: RequestParams = {}) =>
      this.request<CarlinesResponse, any>({
        path: `/catalog/carlines`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a list of models for specific carline. The context id is required and the data version is optional.
     *
     * @tags catalog
     * @name GetModels
     * @summary List of models, by carline or all
     * @request GET:/catalog/models
     * @secure
     */
    getModels: (
      query: {
        /**
         * The id of the carline
         * @example "a4limo"
         */
        carlineId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModelsResponse, any>({
        path: `/catalog/models`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags catalog
     * @name GetEquipment
     * @summary returns all equipment or only updated equipment
     * @request GET:/catalog/equipment
     * @secure
     */
    getEquipment: (params: RequestParams = {}) =>
      this.request<EquipmentResponse, any>({
        path: `/catalog/equipment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  configuration = {
    /**
     * @description delivery current configuration if one exists
     *
     * @tags configuration
     * @name GetCurrentConfiguration
     * @summary get current configuration
     * @request GET:/configuration
     * @secure
     */
    getCurrentConfiguration: (params: RequestParams = {}) =>
      this.request<StateConfigurationResponse, any>({
        path: `/configuration`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description initialize new configuration and delete old configuration if exists
     *
     * @tags configuration
     * @name StartConfiguration
     * @summary init new configuration
     * @request POST:/configuration/start
     * @secure
     */
    startConfiguration: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/configuration/start`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags configuration
     * @name AddToConfiguration
     * @summary set a model or a equipment
     * @request PATCH:/configuration/add
     * @secure
     */
    addToConfiguration: (
      query?: {
        /**
         * the full equipment/model id or the eva id to set or remove
         * @example "WQS"
         */
        triggerId?: string;
        /**
         * used to change the model of an already existing configuration
         * @example "REJECT"
         */
        adopt?: "ACCEPT" | "REJECT" | "CANCEL";
      },
      params: RequestParams = {},
    ) =>
      this.request<StateConfigurationResponse, any>({
        path: `/configuration/add`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags configuration
     * @name RemoveFromConfiguration
     * @summary remove a equipment
     * @request PATCH:/configuration/remove
     * @secure
     */
    removeFromConfiguration: (
      query?: {
        /**
         * the full equipment/model id or the eva id to set or remove
         * @example "WQS"
         */
        triggerId?: string;
        /**
         * used to change the model of an already existing configuration
         * @example "REJECT"
         */
        adopt?: "ACCEPT" | "REJECT" | "CANCEL";
      },
      params: RequestParams = {},
    ) =>
      this.request<StateConfigurationResponse, any>({
        path: `/configuration/remove`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags configuration
     * @name GetConfiguration
     * @summary get a configuration without buildability check
     * @request GET:/configuration/{vehicleCode}
     * @secure
     */
    getConfiguration: (vehicleCode: string, params: RequestParams = {}) =>
      this.request<FullConfigurationResponse, any>({
        path: `/configuration/${vehicleCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags configuration
     * @name GetConfigurationSummary
     * @summary Get the informations of a configuration summary
     * @request GET:/configuration/summary
     * @secure
     */
    getConfigurationSummary: (params: RequestParams = {}) =>
      this.request<ConfigurationSummary, any>({
        path: `/configuration/summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags configuration
     * @name GetCheckoutConfiguration
     * @summary Get the checkout payload to redirect to checkout system
     * @request GET:/configuration/checkout
     * @secure
     */
    getCheckoutConfiguration: (params: RequestParams = {}) =>
      this.request<CheckoutPayload, any>({
        path: `/configuration/checkout`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description initialize new fast configuration
     *
     * @tags fast configuration
     * @name PutFastConfiguration
     * @summary init new fast configuration
     * @request PUT:/configuration/fast
     * @secure
     */
    putFastConfiguration: (data: FastConfigurationKey, params: RequestParams = {}) =>
      this.request<StateConfigurationResponse, any>({
        path: `/configuration/fast`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description initialize new fast configuration
     *
     * @tags fast configuration
     * @name PostFastConfiguration
     * @summary init new fast configuration
     * @request POST:/configuration/fast/{pr3}
     * @secure
     */
    postFastConfiguration: (pr3: string, params: RequestParams = {}) =>
      this.request<StateConfigurationResponse, any>({
        path: `/configuration/fast/${pr3}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  technicalData = {
    /**
     * @description tbd
     *
     * @tags technical and efficiency
     * @name GetTechnicalData
     * @summary return technical data for given configuration string
     * @request GET:/technicalData
     * @secure
     */
    getTechnicalData: (
      query?: {
        /**
         * The string of the current configuration
         * @example "4MGSU1\0|F14 2Y2Y|F56     EI|MCOCC45"
         */
        configurationString?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TechnicalDataResponse, any>({
        path: `/technicalData`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  efficiencyData = {
    /**
     * @description tbd
     *
     * @tags technical and efficiency
     * @name GetEfficiencyDataRaw
     * @summary return efficiency data for current configuration string
     * @request GET:/efficiencyData/raw
     * @secure
     */
    getEfficiencyDataRaw: (params: RequestParams = {}) =>
      this.request<EfficiencyDataResponseRaw, any>({
        path: `/efficiencyData/raw`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags technical and efficiency
     * @name GetEfficiencyDataFormatted
     * @summary return efficiency data for current configuration string in a formatted form
     * @request GET:/efficiencyData/formatted
     * @secure
     */
    getEfficiencyDataFormatted: (params: RequestParams = {}) =>
      this.request<EfficiencyDataResponseFormatted, any>({
        path: `/efficiencyData/formatted`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  cart = {
    /**
     * @description tbd
     *
     * @tags cart
     * @name GetCart
     * @summary response all information of the cart
     * @request GET:/cart
     * @secure
     */
    getCart: (params: RequestParams = {}) =>
      this.request<CartResponse, any>({
        path: `/cart`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags cart
     * @name SetCartViewFlag
     * @summary change the price view flag in state
     * @request PUT:/cart
     * @secure
     */
    setCartViewFlag: (
      query: {
        /**
         * The selected price view flag.
         * @example "PRICE_BRUTTO"
         */
        priceViewFlag: "PRICE_BRUTTO" | "RATE_BRUTTO" | "PRICE_NETTO" | "RATE_NETTO" | "PRICE_OTR" | "PRICE_WLTP";
      },
      params: RequestParams = {},
    ) =>
      this.request<CartResponse, any>({
        path: `/cart`,
        method: "PUT",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  visualization = {
    /**
     * @description tbd
     *
     * @tags visualization
     * @name GetRender
     * @summary response all render images and the ave string
     * @request GET:/visualization
     * @secure
     */
    getRender: (params: RequestParams = {}) =>
      this.request<RenderResponse, any>({
        path: `/visualization`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  vehiclecode = {
    /**
     * @description tbd
     *
     * @tags vehicle code
     * @name CreateVehicleCode
     * @summary create a vehicle code based on the buildable configuration in the session
     * @request POST:/vehiclecode
     * @secure
     */
    createVehicleCode: (params: RequestParams = {}) =>
      this.request<VehicleCode, any>({
        path: `/vehiclecode`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description tbd
     *
     * @tags vehicle code
     * @name GetVehicleCode
     * @summary load the configuration behind the given vehicle code and do the buildability check
     * @request POST:/vehiclecode/{vehicleCode}
     * @secure
     */
    getVehicleCode: (vehicleCode: string, params: RequestParams = {}) =>
      this.request<VehicleCodeResponse, any>({
        path: `/vehiclecode/${vehicleCode}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  carstore = {
    /**
     * No description
     *
     * @tags carstore
     * @name GetCarstoreSlots
     * @summary get all user configuration for carstore user id (stored in the session if init with carstore user id)
     * @request GET:/carstore
     * @secure
     */
    getCarstoreSlots: (params: RequestParams = {}) =>
      this.request<CarstoreResponse, any>({
        path: `/carstore`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carstore
     * @name SetCarstoreSlot
     * @summary stores the current buildable configuration to the user carstore if the maximum user carstore count have not been reached yet
     * @request POST:/carstore
     * @secure
     */
    setCarstoreSlot: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/carstore`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags carstore
     * @name UpdateCarstoreSlot
     * @summary add, remove or update a user comment for a given vehicle code
     * @request PATCH:/carstore/{vehicleCode}
     * @secure
     */
    updateCarstoreSlot: (vehicleCode: string, data: CarstoreComment, params: RequestParams = {}) =>
      this.request<FinancingObject, any>({
        path: `/carstore/${vehicleCode}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags carstore
     * @name DeleteCarstoreSlot
     * @summary delete the given vehicle code from the user carstore
     * @request DELETE:/carstore/{vehicleCode}
     * @secure
     */
    deleteCarstoreSlot: (vehicleCode: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/carstore/${vehicleCode}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  lastConfigurations = {
    /**
     * No description
     *
     * @tags carstore
     * @name GetLastConfigurations
     * @summary get all user configuration for a dealer (and optional salesman id) id within the last 14 days (stored in the session when init with dealer id)
     * @request GET:/lastConfigurations
     * @secure
     */
    getLastConfigurations: (params: RequestParams = {}) =>
      this.request<LastConfigurationSlot, any>({
        path: `/lastConfigurations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  leasing = {
    /**
     * No description
     *
     * @tags leasing matrix
     * @name GetLeasingMatrix
     * @summary gets the leasing matrix to the given model identifier
     * @request GET:/leasing
     * @secure
     */
    getLeasingMatrix: (
      query?: {
        /**
         * The id of the carline
         * @example "50254%5C2024"
         */
        carlineId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LeasingMatrixResponse, any>({
        path: `/leasing`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags leasing matrix
     * @name SelectLeasingRate
     * @summary creates a new rate object and stores it into the current session
     * @request POST:/leasing/{rateId}
     * @secure
     */
    selectLeasingRate: (rateId: string, params: RequestParams = {}) =>
      this.request<LeasingRate, any>({
        path: `/leasing/${rateId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
