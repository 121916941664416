import React, { FunctionComponent } from 'react'
import style from './root.css'
import Sidebar from '../sidebar'
import Navigation from '../navigation'
import Router from '../router'
import ConflictModal from '../conflict-modal'
import ModelConflictModal from '../model-conflict-modal'
import InfoModal from '../info-modal'
import CornerRibbon from '../corner-ribbon'
import Footer from '../footer'
import LeasingModal from '../leasing-modal'
import ForesightedBuildabilityModal from '../foresighted-buildability-modal'
import RescueVehicleCodeModal from '../rescue-vehicle-code-modal'

const PageContent: FunctionComponent = () => (
    <div className={style.pageContainer}>
        <CornerRibbon />
        <Sidebar>
            <Navigation />
        </Sidebar>
        <div className={style.pageContent}>
            <main>
                <Router />
                <ConflictModal />
                <ForesightedBuildabilityModal />
                <InfoModal />
                <ModelConflictModal />
                <RescueVehicleCodeModal />
                <LeasingModal />
            </main>
            <Footer />
        </div>
    </div>
)

export default PageContent
