import { Action, createReducer } from 'typesafe-actions'
import {
    addFailedRequestActionToState,
    removeFailedRequestActionFromState,
} from '../actions/app/failedRequests.actions'

export type FailedRequestsState = Action[]

export const defaultState: FailedRequestsState = []

const failedRequestsReducer = createReducer(defaultState)
    .handleAction(addFailedRequestActionToState, (state, action) => state.concat(action.payload))
    .handleAction(removeFailedRequestActionFromState, (state, action) => (
        state.filter((failedRequest) => failedRequest.type !== action.payload.type)
    ))

export default failedRequestsReducer
