import React from 'react'
import CartboxButton from '../cartbox-button'
import LeasingIcon from '../../assets/media/financial-services-small.svg'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    showLeasingModal: () => void
    isVisible: boolean
    loadLeasingMatrix: Function
}

const LeasingButton: React.FC<Props> = ({ showLeasingModal, isVisible, loadLeasingMatrix }) => {
    const { t } = useTranslation()

    if (!isVisible) {
        return null
    }

    const clickHandler = () => {
        loadLeasingMatrix()
        showLeasingModal()
    }

    return (
        <CartboxButton
            onClick={clickHandler}
            icon={<LeasingIcon />}
            label={t('leasing.buttonLabel')}
        />
    )
}

export default LeasingButton
