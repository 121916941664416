import { createSelector } from 'reselect'
import configurationDataSelector from './configurationDataSelector'

const conflictTotalSelector = createSelector(
    configurationDataSelector,
    (configurationData): string => {
        if (configurationData.conflictInformation === undefined) {
            return ''
        }

        return configurationData.conflictInformation.total
    },
)

export default conflictTotalSelector
