import { connect, MapStateToProps } from 'react-redux'
import { Props } from './LeasingMatrix'
import leasingMatrixSelector from '../../redux/selectors/leasing/leasingMatrixSelector'
import { fetchLeasingMatrixAsync, postLeasingRateAsync } from '../../redux/actions/app/leasing.actions'
import configuredLeasingRateIdSelector from '../../redux/selectors/leasing/configuredLeasingRateIdSelector'
import leasingRequestIsPendingSelector from '../../redux/selectors/pending-request/leasingRequestIsPendingSelector'
import leasingMatrixRowIdxOfConfiguredRateIdSelector from '../../redux/selectors/leasing/leasingMatrixRowIdxOfConfiguredRateIdSelector'

type StateProps = Pick<Props, 'leasingMatrix' | 'configuredLeasingRateId' | 'isLoading' | 'leasingMatrixRowIdxOfConfiguredRateId'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    leasingMatrix: leasingMatrixSelector(state),
    configuredLeasingRateId: configuredLeasingRateIdSelector(state),
    isLoading: leasingRequestIsPendingSelector(state),
    leasingMatrixRowIdxOfConfiguredRateId: leasingMatrixRowIdxOfConfiguredRateIdSelector(state),
})

type DispatchProps = Pick<Props, 'loadLeasingMatrix' | 'applyLeasingRate'>

const mapDispatchToProps: DispatchProps = {
    loadLeasingMatrix: fetchLeasingMatrixAsync.request,
    applyLeasingRate: postLeasingRateAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
