import { createSelector } from 'reselect'
import { ConflictItem } from '../../../entities/ConfigurationResponse'
import configurationDataSelector from './configurationDataSelector'

const foresightedBuildabilityItemsSelector = createSelector(
    configurationDataSelector,
    (configurationData): ConflictItem[][] => {
        if (configurationData.conflictInformation === undefined) {
            return []
        }

        return configurationData.conflictInformation.conflictSteps.map((
            (conflicStep) => conflicStep.conflictItems
        ))
    },
)

export default foresightedBuildabilityItemsSelector
