import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import {
    setEquipmentGroupsState,
    fetchEquipmentGroupsAsync,
    selectEquipment,
    deselectEquipment,
    setEquipmentInformationState,
} from '../../actions/app/equipmentGroups.actions'
import { getEquipmentApiUrl } from '../../../constants/apiUrls'
import { putConfigurationAsync } from '../../actions/app/configuration.actions'
import { openInfoModal } from '../../actions/app/infoModal.actions'

const equipmentMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchEquipmentGroupsAsync.request, action)) {
        dispatch(apiRequest({
            url: getEquipmentApiUrl(),
            method: 'GET',
            successAction: fetchEquipmentGroupsAsync.success,
            failureAction: fetchEquipmentGroupsAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchEquipmentGroupsAsync.success, action)) {
        const { equipmentGroups, equipmentInformation } = action.payload

        dispatch(setEquipmentGroupsState(equipmentGroups))
        dispatch(setEquipmentInformationState(equipmentInformation))
    }

    if (isActionOf(selectEquipment, action)) {
        const equipmentId = action.payload

        if (action.meta && action.meta.infoModal) {
            dispatch(openInfoModal({
                ...action.meta.infoModal,
                onClose: selectEquipment(equipmentId, undefined),
            }))
        } else {
            dispatch(putConfigurationAsync.request({
                id: equipmentId,
                method: 'add',
            }))
        }
    }

    if (isActionOf(deselectEquipment, action)) {
        dispatch(putConfigurationAsync.request({
            id: action.payload,
            method: 'remove',
        }))
    }
}

export default equipmentMiddleware
