import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { applyBrand } from '../../actions/app/brand.actions'
import brandSelector from '../../selectors/brandSelector'

const brandMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(applyBrand, action)) {
        const state = store.getState()
        const brand = brandSelector(state)

        document.body.classList.add(brand)
    }
}

export default brandMiddleware
