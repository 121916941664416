import { connect, MapStateToProps } from 'react-redux'
import { Props } from './LeasingModal'
import leasingModalIsActiveSelector from '../../redux/selectors/leasing/leasingModalIsActiveSelector'

type StateProps = Pick<Props, 'isActive'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    isActive: leasingModalIsActiveSelector(state),
})

export default connect(mapStateToProps)
