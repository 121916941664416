import { MapStateToProps, connect } from 'react-redux'
import logoIsHiddenSelector from '../../redux/selectors/feature-scope/logoIsHiddenSelector'
import { Props } from './Logo'
import brandSelector from '../../redux/selectors/brandSelector'

type StateProps = Pick<Props, 'isHidden' | 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isHidden: logoIsHiddenSelector(state),
    brand: brandSelector(state),
})

export default connect(mapStateToProps)
