import React, { FunctionComponent, ReactNode } from 'react'
import useTranslation from '../../hooks/useTranslation'
import CarlineGroup from '../../entities/CarlineGroup'
import Carline from '../../entities/Carline'
import style from './carlineListView.css'
import SelectCarlineButton from '../select-carline-button'
import CarlineFilters from '../carline-filters'
import CarlineListViewPlaceholder from './CarlineListViewPlaceholder'
import CarlineImage from '../carline-image'
import CarlineGroupPlaceholderImage from '../../assets/media/car-silhouette56.svg'

export type Props = {
    carlineGroups: CarlineGroup[]
    isLoading: boolean
}

const CarlineListView: FunctionComponent<Props> = (props) => {
    const { carlineGroups, isLoading } = props
    const { t } = useTranslation()

    const createCarlineElement = (carline: Carline): ReactNode => {
        const { id, media, name } = carline
        const imgElement = media.length > 0 ? (
            <CarlineImage
                alt={name}
                media={media}
                className={style.image}
                width={100}
                height={42}
                backgroundColor="ffffff"
            />
        ) : <CarlineGroupPlaceholderImage className={style.placeholderImage} />

        return (
            <li key={id} className={style.listItem}>
                <SelectCarlineButton id={id}>
                    <div className={style.carlineContainer}>
                        {imgElement}
                        <span className={style.text}>{name}</span>
                    </div>
                </SelectCarlineButton>
            </li>
        )
    }

    const groupElements = (carlineGroups.length === 0 && isLoading)
        ? <CarlineListViewPlaceholder />
        : carlineGroups.map((carlineGroup) => {
            const { id, name, carlines } = carlineGroup
            const listElements = carlines.map(createCarlineElement)

            if (carlines.length === 0) {
                return null
            }

            return (
                <section key={id} className={style.carlineGroupContainer}>
                    <h2>{name}</h2>
                    <ul className={style.list}>{listElements}</ul>
                </section>
            )
        })

    return (
        <div className={style.container}>
            <div>
                <h2>
                    {t('carlineFilter.title')}
                </h2>
                <CarlineFilters isLoading={isLoading} layout="vertical" />
            </div>
            <div className={style.carlineGroupsWrapper}>
                {groupElements}
            </div>
        </div>
    )
}

export default CarlineListView
