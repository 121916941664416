import { createAction, createAsyncAction } from 'typesafe-actions'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import { EquipmentId } from '../../../entities/Equipment'
import { InfoModalData } from '../../../entities/InfoModal'
import EquipmentInformation from '../../../entities/EquipmentInformation'

export const selectEquipment = createAction('CMD / EquipmentGroups / select equipment')<EquipmentId, { infoModal?: InfoModalData } | undefined>()
export const deselectEquipment = createAction('CMD / EquipmentGroups / deselect equipment')<EquipmentId>()

export const fetchEquipmentGroupsAsync = createAsyncAction(
    'CMD / EquipmentGroups / fetch request',
    'EVT / EquipmentGroups / fetch success',
    'EVT / EquipmentGroups / fetch failure',
)<undefined, { equipmentGroups: EquipmentGroup[]; equipmentInformation: EquipmentInformation }, Error>()

export const setEquipmentGroupsState = createAction('DOC / EquipmentGroups / set state')<EquipmentGroup[]>()

export const resetEquipmentGroupsState = createAction('DOC / EquipmentGroups / reset state')()

export const setEquipmentInformationState = createAction('DOC / EquipmentInformation / set state')<EquipmentInformation>()
export const resetEquipmentInformationState = createAction('DOC / EquipmentInformation / reset state')()
