import { createReducer } from 'typesafe-actions'
import { setActiveRenderImgIdxState } from '../actions/app/renderImages.actions'

export type ActiveRenderImgIdxState = number | null

export const defaultState = null

const activeRenderImgIdxReducer = createReducer(defaultState)
    .handleAction(setActiveRenderImgIdxState, (state, action) => action.payload)

export default activeRenderImgIdxReducer
