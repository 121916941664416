import React, { FunctionComponent, useState } from 'react'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import ForesightedBuildabilityConflictStepItem from './ForesightedBuildabilityConflictStepItem'
import useTranslation from '../../hooks/useTranslation'
import style from './foresightedBuildabilityConflictStep.css'
import ArrowUpIcon from '../../assets/media/arrow-up-small.svg'
import ArrowDownIcon from '../../assets/media/arrow-down-small.svg'
import EquipmentGroup from '../../entities/EquipmentGroup'
import { getFullEquipments } from '../../helpers/getFullEquipments'

export type Props = {
    id: string
    conflictStep: ConflictItem[]
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
    equipmentGroups: EquipmentGroup[]
}

const ForesightedBuildabilityConflictStep: FunctionComponent<Props> = (props) => {
    const {
        id,
        conflictStep,
        openInfoModal,
        deselectEquipment,
        selectEquipment,
        equipmentGroups,
    } = props
    const {t} = useTranslation()
    const [alternativesAreVisible, setAlternativesAreVisible] = useState(false)
    const fullEquipments = getFullEquipments(equipmentGroups, conflictStep)
    const visibleItems = alternativesAreVisible ? (
        conflictStep.map((item) => {
            return (
                <ForesightedBuildabilityConflictStepItem
                    key={item.equipment.id}
                    item={item}
                    fullEquipment={fullEquipments.find(equipment => equipment.id === item.equipment.id)}
                    checkboxIsChecked={item.selected}
                    checkboxIsVisible
                    checkboxIsDisabled={false}
                    openInfoModal={openInfoModal}
                    selectEquipment={selectEquipment}
                    deselectEquipment={deselectEquipment}
                />
            )
        })
    ) : (
        <ForesightedBuildabilityConflictStepItem
            item={conflictStep.find((item) => item.selected)}
            fullEquipment={fullEquipments.find(equipment => equipment.id ===
                conflictStep.find((item) => item.selected).equipment.id)}
            checkboxIsChecked
            checkboxIsVisible={alternativesAreVisible}
            checkboxIsDisabled
            openInfoModal={openInfoModal}
            selectEquipment={selectEquipment}
            deselectEquipment={deselectEquipment}
        />
    )

    const toggleButtonLabel = t(alternativesAreVisible
        ? 'foresightedBuildabilityModal.button.hideAlt'
        : 'foresightedBuildabilityModal.button.showAlt')
    const toggleButtonIcon = alternativesAreVisible
        ? <ArrowUpIcon className={style.icon}/>
        : <ArrowDownIcon className={style.icon}/>
    const toggleButton = conflictStep.length > 1 ? (
        <div>
            <button
                type="button"
                data-cy={`vbbk-toggle-${id}`}
                className={style.toggleButton}
                onClick={() => setAlternativesAreVisible(!alternativesAreVisible)}
            >
                {toggleButtonIcon}
                {toggleButtonLabel}
            </button>
        </div>
    ) : null

    return (
        <div className={style.container}>
            {visibleItems}
            {toggleButton}
        </div>
    )
}

export default ForesightedBuildabilityConflictStep
