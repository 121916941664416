import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetVehicleCodeStatus } from '../../../redux/actions/app/vehicleCode.actions'
import vehicleCodeStatusSelector from '../../../redux/selectors/vehicleCodeStatusSelector'
import { resetConfigurationAsync } from '../../../redux/actions/app/configuration.actions'
import modeSelector from '../../../redux/selectors/modeSelector'

export const useModalState = () => {
    const vehicleCodeState = useSelector(vehicleCodeStatusSelector)
    const mode = useSelector(modeSelector)
    const dispatch = useDispatch()
    const priceChanges = vehicleCodeState?.rescueInfo?.priceChanged
    const isRescue = vehicleCodeState?.status === 'RESCUE'

    const close = useCallback(() => {
        dispatch(resetVehicleCodeStatus())
        dispatch(resetConfigurationAsync.request(undefined, undefined))
    }, [dispatch])

    return { isRescue, close, isRepairable: vehicleCodeState?.rescueInfo?.repairable, mode, priceChanges }
}
