import { createReducer } from 'typesafe-actions'
import VehicleCodeStatus from '../../entities/VehicleCodeStatus'
import {
    resetVehicleCodeStatus,
    setVehicleCodeStatus,
} from '../actions/app/vehicleCode.actions'

export type VehicleCodeStatusState = (VehicleCodeStatus & { code: string }) | {}

export const defaultState: VehicleCodeStatusState = {}

const vehicleCodeStatusReducer = createReducer(defaultState)
    .handleAction(setVehicleCodeStatus, (state, action) => action.payload)
    .handleAction(resetVehicleCodeStatus, () => defaultState)

export default vehicleCodeStatusReducer
