import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import activeRenderImageIdxReducer from './activeRenderImgIdxReducer'
import vehicleCodeReducer from './vehicleCodeReducer'
import carlineDataReducer from './carlineDataReducer'
import carlineSelectionReducer from './carlineSelectionReducer'
import cartReducer from './cartReducer'
import configurationDataReducer from './configurationDataReducer'
import entryDataReducer from './entryDataReducer'
import equipmentFilterReducer from './equipmentFilterReducer'
import equipmentGroupsReducer from './equipmentGroupsReducer'
import filtersReducer from './filtersReducer'
import history from '../../history'
import infoModalReducer from './infoModalReducer'
import modelDataReducer from './modelDataReducer'
import notificationsReducer from './notificationsReducer'
import summaryReducer from './summaryReducer'
import pendingRequestsReducer from './pendingRequestsReducer'
import purchaseModeReducer from './purchaseModeReducer'
import technicalDataReducer from './technicalDataReducer'
import trackingReducer from './trackingReducer'
import trackingIsReadyReducer from './trackingIsReadyReducer'
import uiReducer from './uiReducer'
import visualizationReducer from './visualizationReducer'
import initParametersReducer from './initParametersReducer'
import efficiencyDataReducer from './efficiencyDataReducer'
import languageIsLoadedReducer from './languageIsLoadedReducer'
import failedRequestsReducer from './failedRequestsReducer'
import leasingReducer from './leasingReducer'
import checkoutPayloadReducer from './checkoutPayloadReducer'
import vehicleCodeStatusReducer from './vehicleCodeStatusReducer'
import equipmentInformationReducer from './equipmentInformationReducer'

const rootReducer = combineReducers({
    router: connectRouter(history),
    activeRenderImageIdx: activeRenderImageIdxReducer,
    carlineData: carlineDataReducer,
    carlineSelection: carlineSelectionReducer,
    cart: cartReducer,
    checkoutPayload: checkoutPayloadReducer,
    configurationData: configurationDataReducer,
    efficiencyData: efficiencyDataReducer,
    entryData: entryDataReducer,
    equipmentFilter: equipmentFilterReducer,
    equipmentGroups: equipmentGroupsReducer,
    failedRequests: failedRequestsReducer,
    filters: filtersReducer,
    infoModal: infoModalReducer,
    initParameters: initParametersReducer,
    languageIsLoaded: languageIsLoadedReducer,
    leasing: leasingReducer,
    modelData: modelDataReducer,
    notifications: notificationsReducer,
    summary: summaryReducer,
    pendingRequests: pendingRequestsReducer,
    purchaseMode: purchaseModeReducer,
    technicalData: technicalDataReducer,
    tracking: trackingReducer,
    trackingIsReady: trackingIsReadyReducer,
    ui: uiReducer,
    vehicleCode: vehicleCodeReducer,
    visualization: visualizationReducer,
    vehicleCodeStatus: vehicleCodeStatusReducer,
    equipmentInformation: equipmentInformationReducer,
})

export type State = ReturnType<typeof rootReducer>

export default rootReducer
