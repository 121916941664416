import { createSelector } from 'reselect'
import availablePriceViewsSelector from './availablePriceViewsSelector'
import { PriceMode } from '../actions/app/priceMode.actions'

const availablePriceModesSelector = createSelector(
    availablePriceViewsSelector,
    (availablePriceViewFlags): PriceMode[] => {
        const priceModes: PriceMode[] = []

        if (
            availablePriceViewFlags.includes('PRICE_BRUTTO')
            || availablePriceViewFlags.includes('PRICE_NETTO')
        ) {
            priceModes.push('full')
        }

        if (
            availablePriceViewFlags.includes('RATE_BRUTTO')
            || availablePriceViewFlags.includes('RATE_NETTO')
        ) {
            priceModes.push('rate')
        }

        return priceModes
    },
)

export default availablePriceModesSelector
