import React, {
    PropsWithChildren,
    useState,
    KeyboardEventHandler,
    FunctionComponent,
} from 'react'
import { createPortal } from 'react-dom'
import style from './modal.css'
import useOnMount from '../../hooks/useOnMount'

export type Props = PropsWithChildren<{
    isVisible?: boolean
    containerClassName?: string
    layerClassName?: string
    contentClassName?: string
    onClose?: Function
    isFixed?: boolean
}>

const Modal: FunctionComponent<Props> = (props) => {
    const {
        isVisible = true,
        children,
        containerClassName,
        layerClassName,
        contentClassName,
        onClose,
        isFixed,
    } = props

    useOnMount(() => {
        if (isFixed === false || !isVisible) {
            return () => undefined
        }

        document.documentElement.classList.add(style.modalOpen)

        return () => {
            document.documentElement.classList.remove(style.modalOpen)
        }
    })

    const [containerElement] = useState<HTMLElement | null>(document.getElementById('micon-modal'))

    if (!containerElement) {
        console.error('Could not open React.Portal target element')

        return null
    }

    const handleClose = (): void => {
        if (onClose) {
            onClose()
        }
    }

    const onKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === 'Escape') {
            handleClose()
        }
    }

    return createPortal(
        isVisible ? (<div className={`${style.container} ${containerClassName}`}>
            <div
                className={`${style.layer} ${layerClassName}`}
                onClick={handleClose}
                role="button"
                tabIndex={0}
                onKeyDown={onKeyDown}
                aria-label="close modal"
            />
            <div className={`${style.content} ${contentClassName}`}>
                {children}
            </div>
        </div>) : null,
        containerElement,
    )
}

Modal.defaultProps = {
    containerClassName: '',
    layerClassName: '',
    contentClassName: '',
    onClose: () => undefined,
    isFixed: true,
}

export default Modal
