import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest, apiSuccess, apiFailure } from '../../actions/core/api.actions'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'

const apiUiMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(apiRequest, action)) {
        const { causedBy } = action.meta
        const { url } = action.payload

        dispatch(addPendingRequest(url, { causedBy }))
    }

    if (isActionOf(apiSuccess, action)) {
        const { causedBy } = action.meta
        const { url } = action.payload

        dispatch(removePendingRequest(url, { causedBy }))
    }

    if (isActionOf(apiFailure, action)) {
        const { causedBy } = action.meta
        const { url } = action.payload

        dispatch(removePendingRequest(url, { causedBy }))
    }
}

export default apiUiMiddleware
