import { createReducer } from 'typesafe-actions'
import CheckoutPayload from '../../entities/CheckoutPayload'
import { setCheckoutPayloadState } from '../actions/app/checkoutPayload.actions'

export type CheckoutState = CheckoutPayload

export const defaultState: CheckoutState = {
    targetUrl: '',
    checkoutBody: {
        ziel_app: '',
        zielgr: '',
        context: '',
        weitervermarktung: false,
        kd_vorhanden: false,
        cus_mandatory: false,
        zweit_pdw: false,
        bestellt_fuer: '',
        erwerbsart: '',
        car_group: 0,
        return_url_mfc: '',
        return_url_mfc_mot1: '',
        return_url_mfc_mot2: '',
        return_url_mfc_conx: '',
        keep_alive_url_mfc: '',
        cus_type: 0,
        kommissionsnummer: '',
        kommissionsjahr: '',
        token: '',
        tokenSignature: '',
        'portal-id': '',
        'app-id': '',
        audicode: '',
        quell_app: '',
        keep_alive_url_cc: '',
        keep_alive_url_cars: '',
        return_url_cc: '',
        return_url_cars: '',
        d_trace: 0,
    },
}

const checkoutPayloadReducer = createReducer(defaultState)
    .handleAction(setCheckoutPayloadState, (state, action) => action.payload)

export default checkoutPayloadReducer
