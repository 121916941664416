import { createAction, createAsyncAction } from 'typesafe-actions'
import Visualization from '../../../entities/Visualization'

export const fetchVisualizationAsync = createAsyncAction(
    'CMD / Visualization / fetch request',
    'EVT / Visualization / fetch success',
    'EVT / Visualization / fetch failure',
)<undefined, Visualization, Error>()

export const setVisualizationState = createAction('DOC / Visualization / set state')<Visualization>()
