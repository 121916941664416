import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import InfoI from '../info-i'
import style from './wheelEfficiencyLabel.css'

export type Props = {
    wheelEfficiencyInfoLabels: {
        imgUrl: string
        dataSheetUrl: string
    }[]
}

const WheelEfficiencyLabel: FunctionComponent<Props> = (props) => {
    const { wheelEfficiencyInfoLabels } = props
    const { t } = useTranslation()

    const multipleLabelDisclaimer = wheelEfficiencyInfoLabels.length > 1 ? (
        <p className={style.disclaimer}>
            <InfoI isForced={false} />
            {t('wheelEfficiencyLabel.multipleLabelDisclaimer')}
        </p>
    ) : null

    const labelElements = wheelEfficiencyInfoLabels.map((infoLabel) => (
        <div key={infoLabel.imgUrl}>
            <img src={infoLabel.imgUrl} alt="Wheel Efficiency Label" />
            <p>
                <a
                    href={infoLabel.dataSheetUrl}
                    className={style.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('wheelEfficiencyLabel.datasheet')}
                </a>
            </p>
        </div>
    ))

    return (
        <article>
            <h1>{t('wheelEfficiencyLabel.title')}</h1>
            <p>
                <a
                    href={t('wheelEfficiencyLabel.infoLinkUrl')}
                    className={style.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('wheelEfficiencyLabel.infoLinkLabel')}
                </a>
            </p>
            {multipleLabelDisclaimer}
            {labelElements}
        </article>
    )
}

export default WheelEfficiencyLabel
