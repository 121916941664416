import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { setCheckoutPayloadState, fetchCheckoutPayloadAsync } from '../../actions/app/checkoutPayload.actions'
import { apiRequest } from '../../actions/core/api.actions'
import { getCheckoutApiUrl } from '../../../constants/apiUrls'

const checkoutPayloadMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchCheckoutPayloadAsync.request, action)) {
        dispatch(apiRequest({
            url: getCheckoutApiUrl(),
            method: 'GET',
            successAction: fetchCheckoutPayloadAsync.success,
            failureAction: fetchCheckoutPayloadAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchCheckoutPayloadAsync.success, action)) {
        dispatch(setCheckoutPayloadState(action.payload))
    }
}

export default checkoutPayloadMiddleware
