import { createReducer } from 'typesafe-actions'
import Notification from '../../entities/Notification'
import {
    addNotificationToState,
    removeNotificationFromState,
    resetNotificationsState,
    setClosingOnNotification,
} from '../actions/app/notification.actions'

export type NotificationsState = Notification[]

export const defaultState: NotificationsState = []

const notificationsReducer = createReducer(defaultState)
    .handleAction(addNotificationToState, (state, action) => {
        const notificationToAdd = action.payload
        const notificationAlreadySet = state.some((notificationOnState) => (
            notificationOnState.message === notificationToAdd.message
        ))

        return notificationAlreadySet ? state : state.concat(notificationToAdd)
    })
    .handleAction(removeNotificationFromState, (state, action) => {
        const notificationToRemove = action.payload

        return state.filter((notificationOnState) => (
            notificationOnState.message !== notificationToRemove.message
        ))
    })
    .handleAction(setClosingOnNotification, (state, action) => state.map((notificationOnState) => {
        const notificationToSetClosing = action.payload

        return {
            ...notificationOnState,
            closing: notificationOnState.message === notificationToSetClosing.message,
        }
    }))
    .handleAction(resetNotificationsState, () => defaultState)

export default notificationsReducer
