import { createSelector } from 'reselect'
import sessionIdIsSetSelector from './sessionIdIsSetSelector'
import currentPathnameSelector from './router/currentPathnameSelector'
import { ROUTE_CHECK, ROUTE_ENTRY } from '../../constants/routes'
import languageIsLoadedSelector from './i18n/languageIsLoadedSelector'

const shallAppRenderSelector = createSelector(
    sessionIdIsSetSelector,
    currentPathnameSelector,
    languageIsLoadedSelector,
    (
        sessionIdSet,
        currentPathname,
        languageIsLoaded,
    ): boolean => (
        (sessionIdSet && languageIsLoaded)
        || currentPathname === ROUTE_CHECK
        || currentPathname.includes(ROUTE_ENTRY)
    ),
)

export default shallAppRenderSelector
