import { createSelector } from 'reselect'
import { parse } from 'query-string'
import routerSelector from './router/routerSelector'

export type Params = {
    /* eslint-disable camelcase */
    context: string
    id_token: string
    zweit_pdf?: string
    features?: string
    vehicle_code?: string
    betrnr?: string
    pr3?: string
    agis_fnr?: string
    agis_return_url?: string
    return_url?: string
    zweit_pdw?: boolean
    mandant?: string
    /* eslint-enable */
}

const initRouterParamsSelector = createSelector(
    routerSelector,
    (router): Params => {
        const { hash, query } = router.location
        const hashToQuery = hash.replace('#', '?')
        const parsedHashToQuery = parse(hashToQuery)

        return {
            ...parsedHashToQuery,
            ...query,
        } as Params
    },
)

export default initRouterParamsSelector
