import { createSelector } from 'reselect'
import carlinesSelector from './carlinesSelector'
import configuredCarlineSymbolicIdSelector from './configuredCarlineSymbolicIdSelector'

const configuredCarlineNameSelector = createSelector(
    carlinesSelector,
    configuredCarlineSymbolicIdSelector,
    (carlines, configuredCarlineId): string | null => {
        const configuredCarline = carlines.find(({ id }) => id === configuredCarlineId)

        if (!configuredCarline) {
            return null
        }

        return configuredCarline.name
    },
)

export default configuredCarlineNameSelector
