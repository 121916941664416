import { connect, MapStateToProps } from 'react-redux'
import { Props } from './VehicleCodeButton'
import { createVehicleCodeAsync } from '../../redux/actions/app/vehicleCode.actions'
import configurationIsBuildableSelector from '../../redux/selectors/configuration/configurationIsBuildableSelector'
import brandSelector from '../../redux/selectors/brandSelector'

type StateProps = Pick<Props, 'configurationIsBuildable' | 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    configurationIsBuildable: configurationIsBuildableSelector(state),
    brand: brandSelector(state),
})

type DispatchProps = Pick<Props, 'createVehicleCode'>

const mapDispatchToProps: DispatchProps = {
    createVehicleCode: createVehicleCodeAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
