import React, { FunctionComponent, useEffect, useState } from 'react'
import useOnMount from '../../hooks/useOnMount'
import LeasingMatrix from '../../entities/LeasingMatrix'
import { Checkbox } from '../form'
import style from './leasingMatrix.css'
import ArrowDownIcon from '../../assets/media/arrow-down.svg'
import ArrowUpIcon from '../../assets/media/arrow-up.svg'
import useTranslation from '../../hooks/useTranslation'
import { Spinner } from '../../base-components/spinner'

export type Props = {
    leasingMatrix: LeasingMatrix
    loadLeasingMatrix: Function
    applyLeasingRate: (rateId: string) => void
    configuredLeasingRateId: string
    leasingMatrixRowIdxOfConfiguredRateId: number
    isLoading: boolean
}

const LeasingMatrix: FunctionComponent<Props> = (props) => {
    const {
        loadLeasingMatrix,
        leasingMatrix,
        applyLeasingRate,
        configuredLeasingRateId,
        leasingMatrixRowIdxOfConfiguredRateId,
        isLoading,
    } = props

    const { t } = useTranslation()
    const [activeRowIdx, setActiveRowIdx] = useState(-1)
    const [selectedRate, setSelectedRate] = useState('')

    const loadMatrixIfEmpty = (): void => {
        if (!leasingMatrix.length && !isLoading) {
            loadLeasingMatrix()
        }
    }

    useEffect(loadMatrixIfEmpty, [leasingMatrix, isLoading, loadLeasingMatrix])

    useEffect(() => {
        if (leasingMatrix.length && leasingMatrixRowIdxOfConfiguredRateId !== -1) {
            setActiveRowIdx(leasingMatrixRowIdxOfConfiguredRateId)
            setSelectedRate(configuredLeasingRateId)
        }
    }, [leasingMatrix, leasingMatrixRowIdxOfConfiguredRateId, configuredLeasingRateId])

    useOnMount(loadMatrixIfEmpty)

    const rowElements = leasingMatrix.map((row, rowIdx) => {
        const { duration, rates } = row
        const rowIsActive = rowIdx === activeRowIdx

        const rateElements = rates.map((rate) => {
            if (!rowIsActive) {
                return null
            }

            const {
                id, rateAmount, mileage, mileagePerDuration,
            } = rate
            const isChecked = selectedRate === id

            return (
                <div key={id}>
                    <label className={style.rateButton}>
                        <div>
                            <Checkbox
                                checked={isChecked}
                                onChange={() => {
                                    setSelectedRate(id)
                                }}
                            />
                        </div>
                        <div>
                            <div>{rateAmount}</div>
                            <div>
                                {mileage}
                                {' '}
                                (
                                {mileagePerDuration}
                                /
                                {t('leasing.month')}
                                )
                            </div>
                        </div>
                    </label>
                </div>
            )
        })

        const toggleButtonClassName = [
            style.toggleButton,
            rowIsActive ? style.toggleButtonActive : '',
        ].join(' ')

        const iconElement = rowIsActive ? <ArrowUpIcon /> : <ArrowDownIcon />

        return (
            <div key={duration}>
                <button
                    className={toggleButtonClassName}
                    onClick={() => {
                        setActiveRowIdx(rowIdx)
                    }}
                    type="button"
                >
                    <span>{t('leasing.durationLabel', { duration })}</span>
                    {iconElement}
                </button>
                <div className={style.ratesContainer}>{rateElements}</div>
            </div>
        )
    })

    const submitButtonIsDisabled = selectedRate === ''

    return isLoading ? (
        <div className={style.spinnerWrapper}>
            <Spinner />
        </div>
    ) : (
        <div>
            <div className={style.matrixContainer}>{rowElements}</div>
            <div className={style.submitButtonWrapper}>
                <button
                    disabled={submitButtonIsDisabled}
                    className={style.submitButton}
                    onClick={() => {
                        applyLeasingRate(selectedRate)
                    }}
                    type="button"
                >
                    {t('leasing.submitButtonLabel')}
                </button>
            </div>
        </div>
    )
}

export default LeasingMatrix
