import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import { fetchCarlineDataAsync, setCarineDataState } from '../../actions/app/carlineData.actions'
import { getCarlinesApiUrl } from '../../../constants/apiUrls'

const carlineDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchCarlineDataAsync.request, action)) {
        dispatch(apiRequest({
            url: getCarlinesApiUrl(),
            method: 'GET',
            successAction: fetchCarlineDataAsync.success,
            failureAction: fetchCarlineDataAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchCarlineDataAsync.success, action)) {
        const carlineData = action.payload

        dispatch(setCarineDataState(carlineData))
    }
}

export default carlineDataMiddleware
