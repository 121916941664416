import React, { FunctionComponent } from 'react'
import style from './root.css'

const Error: FunctionComponent = () => (
    <div className={style.pageContainer}>
        <div className={style.pageContent}>
            <article className={style.content}>
                <h1>Server Error</h1>
                <p>We are sorry, the Configurator is currently not available.</p>
            </article>
        </div>
    </div>
)

export default Error
