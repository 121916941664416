import { connect, MapStateToProps } from 'react-redux'
import cartNameSelector from '../../redux/selectors/cart/cartNameSelector'
import cartNavLinkSelector from '../../redux/selectors/cart/cartNavLinkSelector'
import cartIsVisibleSelector from '../../redux/selectors/cart/cartIsVisibleSelector'
import { Props } from './Cartbox'
import cartIsLoadingSelector from '../../redux/selectors/is-loading/cartIsLoadingSelector'

type StateProps = Pick<Props, 'cartIsVisible' | 'cartName' | 'cartNavLink' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    cartIsVisible: cartIsVisibleSelector(state),
    cartName: cartNameSelector(state),
    cartNavLink: cartNavLinkSelector(state),
    isLoading: cartIsLoadingSelector(state),
})

export default connect(mapStateToProps)
