export const ROUTE_CARLINES = '/'

export const ROUTE_MODELS = '/models'

export const ROUTE_OVERVIEW = '/overview'

export const ROUTE_TECHNICAL_DATA = '/technical-data'

export const ROUTE_CHECKOUT = '/checkout'

export const ROUTE_CHECK = '/check'

export const ROUTE_ENTRY = '/entry'
