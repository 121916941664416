import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import { getEfficiencyDataApiUrl } from '../../../constants/apiUrls'
import {
    fetchEfficiencyDataAsync,
    setEfficiencyDataState,
} from '../../actions/app/efficiencyData.actions'

const efficiencyDataMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchEfficiencyDataAsync.request, action)) {
        dispatch(apiRequest({
            url: getEfficiencyDataApiUrl(),
            method: 'GET',
            successAction: fetchEfficiencyDataAsync.success,
            failureAction: fetchEfficiencyDataAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchEfficiencyDataAsync.success, action)) {
        const efficiencyData = action.payload

        dispatch(setEfficiencyDataState(efficiencyData))
    }
}

export default efficiencyDataMiddleware
