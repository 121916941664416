import { createAction, createAsyncAction } from 'typesafe-actions'
import SummaryData from '../../../entities/SummaryData'

export const fetchSummaryAsync = createAsyncAction(
    'CMD / Summary / fetch request',
    'EVT / Summary / fetch success',
    'EVT / Summary / fetch failure',
)<undefined, SummaryData, Error>()

export const setSummaryState = createAction('DOC / Summary / set state')<SummaryData>()
export const resetSummaryState = createAction('DOC / Summary / reset state')()
