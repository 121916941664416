import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import PdfIcon from '../../assets/media/pdf.svg'
import CartboxButton from '../cartbox-button'
import config from '../../config'

export type Props = {
    createPdf: Function
    requestIsPending: boolean
    configurationIsBuildable: boolean
}

const PdfButton: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation()

    const {
        createPdf,
        configurationIsBuildable,
        requestIsPending,
    } = props

    if (!config.PDF_SERVICE_URL) {
        return null
    }

    const pdfButtonClicked = (): void => {
        createPdf()
    }

    return (
        <CartboxButton
            onClick={pdfButtonClicked}
            disabled={!configurationIsBuildable || requestIsPending}
            icon={<PdfIcon />}
            label={t('pdf.buttonLabel')}
        />
    )
}

export default PdfButton
