import { createAction, createAsyncAction } from 'typesafe-actions'
import ConfigurationResponse from '../../../entities/ConfigurationResponse'
import VehicleCode from '../../../entities/VehicleCode'
import MetaAsyncHandler from '../../../entities/MetaAsyncHandler'
import FastConfigurationKey from '../../../entities/FastConfigurationKey'

export const fetchConfigurationAsync = createAsyncAction(
    'CMD / Configuration / fetch request',
    'EVT / Configuration / fetch success',
    'EVT / Configuration / fetch failure',
)<undefined, ConfigurationResponse, Error>()

export const getConfigurationByVehicleCode = createAsyncAction(
    'CMD / Configuration / get by vehicle code request',
    'EVT / Configuration / get by vehicle code success',
    'EVT / Configuration / get by vehicle code failure',
)<[VehicleCode, MetaAsyncHandler], ConfigurationResponse, Error>()

export const putConfigurationAsync = createAsyncAction(
    'CMD / Configuration / put request',
    'EVT / Configuration / put success',
    'EVT / Configuration / put failure',
)<{ id: string; method: 'add' | 'remove' }, ConfigurationResponse, Error>()

export const postFastConfigurationWithOrderKeyAsync = createAsyncAction(
    'CMD / Configuration  / post with order key request',
    'EVT / Configuration  / post with order key success',
    'EVT / Configuration  / post with order key failure',
)<undefined, ConfigurationResponse, Error>()

export const putFastConfigurationAsync = createAsyncAction(
    'CMD / Configuration  / put fast configuration request',
    'EVT / Configuration  / put fast configuration success',
    'EVT / Configuration  / put fast configuration failure',
)<FastConfigurationKey, ConfigurationResponse, Error>()

export const setConfigurationDataState = createAction('DOC / Configuration / set state')<ConfigurationResponse>()

export const resetSummaryState = createAction('DOC / Summary / reset state')<ConfigurationResponse>()

export const resetConfigurationAsync = createAsyncAction(
    'CMD / Configuration / reset request',
    'EVT / Configuration / reset success',
    'EVT / Configuration / reset failure',
)<[undefined, MetaAsyncHandler]>()

export const resetConfigurationDataState = createAction('DOC / Configuration / reset state')()
export const setConfigurationRescueConflictAccepted = createAction('DOC / Configuration / set rescue conflict accepted')<boolean>()
