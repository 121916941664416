import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import CarlineGroupPlaceholderImage from '../../assets/media/car-silhouette56.svg'
import style from './carlineListView.css'

const CarlineListViewPlaceholder: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <section className={style.carlineGroupContainer}>
            <h2>{t('loading')}</h2>
            <ul className={style.list}>
                {[...Array(12)].map((element, index) => (
                    <li className={style.listItem} key={`carline-groups-list-placeholder-${index}`}>
                        <div className={style.carlineContainer}>
                            <CarlineGroupPlaceholderImage />
                            <span className={style.text}>{t('loading')}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default CarlineListViewPlaceholder
