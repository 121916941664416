import React, { FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import EnterVehicleCodeModal from '../enter-vehicle-code-modal'
import CodeIcon from '../../assets/media/code.svg'
import style from './enterVehicleCodeButton.css'

export type Props = {
    brand: string
}

const EnterVehicleCodeButton: FunctionComponent<Props> = (props) => {
    const { brand } = props
    const modalActiveState = useState(false)
    const { t } = useTranslation()

    const clickHandler = (): void => {
        const setModalIsActive = modalActiveState[1]

        setModalIsActive(true)
    }

    return (
        <>
            <button
                onClick={clickHandler}
                className={style.button}
                type="button"
                data-cy="enter-vehicle-code-button"
            >
                {t(`vehicleCode.${brand}.enter.modalButtonLabel`)}
                <CodeIcon className={style.icon} />
            </button>
            <EnterVehicleCodeModal activeState={modalActiveState} />
        </>
    )
}

export default EnterVehicleCodeButton
