import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import NavLink from '../../entities/NavLink'
import { getLoadingClassName } from '../loading-indicator-wrapper'
import CartboxPrices from '../cartbox-prices'
import RenderImagesPreview from '../render-images-preview'
import VehicleCodeButton from '../vehicle-code-button'
import CartboxEmissionConsumption from '../cartbox-emission-consumption'
import ResetConfigurationButton from '../reset-configuration-button'
import style from './cartbox.css'
import PdfButton from '../pdf-button'
import LeasingButton from '../leasing-button'

export type Props = {
    cartIsVisible: boolean
    cartName: string
    cartNavLink: NavLink
    isLoading: boolean
}

const Cartbox: FunctionComponent<Props> = (props) => {
    const {
        cartIsVisible,
        cartName,
        cartNavLink,
        isLoading,
    } = props
    const { t, i18n } = useTranslation()

    if (!cartIsVisible) {
        return null
    }

    const buttonLabel = i18n.exists(`cartNavigation.${cartNavLink.id}`)
        ? t(`cartNavigation.${cartNavLink.id}`)
        : t('cartNavigation.goTo', { label: t(cartNavLink.navLabel) })

    return (
        <section className={`${style.container} ${getLoadingClassName(isLoading)}`}>
            <h2 className={style.title} data-cy="cart-title">
                {cartName || t('loading')}
            </h2>
            <div className={style.imagesContainer}>
                <RenderImagesPreview />
            </div>
            <div className={style.pricesContainer}>
                <CartboxPrices />
                <CartboxEmissionConsumption
                    /* TODO set correct emissionConsumption */
                    emissionConsumption={[]}
                />
            </div>
            <div className={style.navButtonContainer}>
                <Link
                    to={cartNavLink.url}
                    className={style.navButton}
                    data-cy={`cart-button-continue-${cartNavLink.id.replace(/\\/g, '.')}`}
                >
                    {buttonLabel || t('loading')}
                </Link>
            </div>
            <ul className={style.linkList}>
                <li>
                    <ResetConfigurationButton />
                </li>
                <li>
                    <PdfButton />
                </li>
                <li>
                    <VehicleCodeButton />
                </li>
                <li>
                    <LeasingButton />
                </li>
            </ul>
        </section>
    )
}

export default Cartbox
