import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import Equipment from '../../entities/Equipment'
import useTranslation from '../../hooks/useTranslation'
import Modal from '../modal'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import ForesightedBuildabilityConflictStep from './ForesightedBuildabilityConflictStep'
import style from './foresightedBuildabilityModal.css'
import ForesightedBuildabilityTriggerEquipment from './ForesightedBuildabilityTriggerEquipment'
import EquipmentGroup from '../../entities/EquipmentGroup'

export type Props = {
    conflictSteps: ConflictItem[][]
    isResolvable: boolean
    total: string
    isLoading: boolean
    adoptConflictSolution: () => void
    cancelConflictSolution: () => void
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
    isDisabled: boolean
    isCancelable: boolean
    triggerEquipment: Equipment | null
    mode: string
    equipmentGroups: EquipmentGroup[]
}

const ForesightedBuildabilityModal: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation()
    const {
        conflictSteps,
        isResolvable,
        total,
        isLoading,
        adoptConflictSolution,
        cancelConflictSolution,
        openInfoModal,
        selectEquipment,
        deselectEquipment,
        isDisabled,
        isCancelable,
        triggerEquipment,
        mode,
        equipmentGroups,
    } = props

    if (isDisabled) {
        return null
    }

    const conflictStepElements = conflictSteps.map((conflictStep) => {
        const id = conflictStep.map((conflictItem) => conflictItem.equipment.id).join()

        return (
            <ForesightedBuildabilityConflictStep
                id={id}
                key={id}
                conflictStep={conflictStep}
                openInfoModal={openInfoModal}
                selectEquipment={selectEquipment}
                deselectEquipment={deselectEquipment}
                equipmentGroups={equipmentGroups}
            />
        )
    })

    const notResolvableDisclaimerElement = !isResolvable ? (
        <p className={style.notResolvableDisclaimer}>
            {t('foresightedBuildabilityModal.notBuildableMsg')}
        </p>
    ) : null

    const applyButtonElement = isResolvable ? (
        <li className={style.buttonListItem}>
            <button
                type="button"
                data-cy="resolve-conflict-button"
                className={style.button}
                onClick={() => adoptConflictSolution()}
            >
                {t('foresightedBuildabilityModal.apply')}
            </button>
        </li>
    ) : null

    const cancelButtonElement = isCancelable ? (
        <li className={style.buttonListItem}>
            <button
                type="button"
                data-cy="cancel-conflict-button"
                className={style.button}
                onClick={() => cancelConflictSolution()}
            >
                {t('foresightedBuildabilityModal.cancel')}
            </button>
        </li>
    ) : null

    const message1TextTransKey = `foresightedBuildabilityModal.message1.${mode}`
    const message1Text = i18n.exists(message1TextTransKey)
        ? t(message1TextTransKey)
        : t('foresightedBuildabilityModal.message1.kauf')

    return (
        <Modal>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <section className={style.container}>
                    <h2>{t('foresightedBuildabilityModal.headline')}</h2>
                    <Markup tagName="div" content={message1Text} />
                    <ForesightedBuildabilityTriggerEquipment equipment={triggerEquipment} />
                    <p>
                        {t('foresightedBuildabilityModal.message2')}
                    </p>
                    {conflictStepElements}
                    <p className={style.sum}>
                        {t('foresightedBuildabilityModal.total')}
                        {' '}
                        {total}
                    </p>
                    {notResolvableDisclaimerElement}
                    <ul className={style.buttonList}>
                        {cancelButtonElement}
                        {applyButtonElement}
                    </ul>
                </section>
            </LoadingIndicatorWrapper>
        </Modal>
    )
}

export default ForesightedBuildabilityModal
