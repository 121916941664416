import { createSelector } from 'reselect'
import equipmentGroupsSelector from './equipmentGroupsSelector'
import equipmentInformationSelector from './equipmentInformationSelector'
import urlifyGroupName from '../../../helpers/urlifyGroupName'

const winterTyreFamilyPathSelector = createSelector(
    equipmentGroupsSelector,
    equipmentInformationSelector,
    (equipmentGroups, equipmentInformation): string => {
        const winterTyreGroupId = equipmentInformation?.winterTyreInfo?.groupId
        const winterTyreFamilyId = equipmentInformation?.winterTyreInfo?.familyId

        if (winterTyreGroupId && winterTyreFamilyId) {

            const tyreEquipmentGroup = equipmentGroups.find(equipmentGroup => equipmentGroup.id === winterTyreGroupId)
            if (tyreEquipmentGroup) {
                return `${urlifyGroupName(tyreEquipmentGroup.name)}?#${winterTyreFamilyId}`
            }
        }
        return null
    },
)

export default winterTyreFamilyPathSelector
