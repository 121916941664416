import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import CarlineGroup from '../../entities/CarlineGroup'
import CarlineGridGroup from '../carline-grid-group'
import style from './carlineGridView.css'
import CarlineGridViewPlaceholder from './CarlineGridViewPlaceholder'
import CarlineFilters from '../carline-filters'
import FilterIcon from '../../assets/media/filter.svg'

export type Props = {
    carlineGroups: CarlineGroup[]
    isLoading: boolean
    filtersAreVisible: boolean
    toggleFiltersAreVisible: () => void
}

const CarlineGridView: FunctionComponent<Props> = (props) => {
    const {
        carlineGroups,
        isLoading,
        filtersAreVisible,
        toggleFiltersAreVisible,
    } = props
    const { t } = useTranslation()

    const placeHolder = (carlineGroups.length === 0 && isLoading)
        ? <CarlineGridViewPlaceholder />
        : null

    const carlineGroupToggleElements = carlineGroups.map((carlineGroup) => (
        <CarlineGridGroup
            key={carlineGroup.id}
            carlineGroup={carlineGroup}
        />
    ))

    const toggleFilters = (): void => {
        toggleFiltersAreVisible()
    }

    const toggleButtonLabel = filtersAreVisible
        ? t('carlineSelection.hideFilters')
        : t('carlineSelection.showFilters')

    return (
        <>
            <div className={style.toggleButtonWrapper}>
                <button className={style.toggleButton} onClick={toggleFilters} type="button" data-cy="filter-toggle">
                    {toggleButtonLabel}
                    <FilterIcon className={style.icon} />
                </button>
            </div>
            <div hidden={!filtersAreVisible}>
                <CarlineFilters isLoading={isLoading} layout="horizontal" />
            </div>
            <div className={style.list}>
                {placeHolder}
                {carlineGroupToggleElements}
            </div>
        </>
    )
}

export default CarlineGridView
