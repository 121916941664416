import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Modal from '../modal'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import EquipmentTable from '../equipment-table'
import style from './conflictModal.css'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'

export type Props = {
    conflictItems: ConflictItem[]
    isLoading: boolean
    isDisabled: boolean
}

const ConflictModal: FunctionComponent<Props> = (props) => {
    const { isDisabled, conflictItems, isLoading } = props
    const { t } = useTranslation()

    if (isDisabled) {
        return null
    }

    const equipments = conflictItems.map((conflictItem) => conflictItem.equipment)

    return (
        <Modal>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <section className={style.container}>
                    <h2>{t('conflictModal.title')}</h2>
                    <p>{t('conflictModal.subtitle')}</p>
                    <EquipmentTable
                        equipments={equipments}
                        viewVariant="conflict"
                    />
                </section>
            </LoadingIndicatorWrapper>
        </Modal>
    )
}

export default ConflictModal
