import React, { FunctionComponent } from 'react'
import { useCurrentBrand } from '../../hooks/useCurrentBrand'
import useTranslation from '../../hooks/useTranslation'
import Modal, { ModalClosingButton } from '../modal'
import { useMappedConfiguration } from './hooks/useMappedConfiguration'
import { useModalState } from './hooks/useModalState'
import {
    ChangedPriceContainer, ChangedPriceSelection,
    Footer,
    FooterButtonsContainer,
    InfoRow,
    InfoText, NewPrice,
    PriceTitle,
    PriceValue,
    Subtitle,
    Title,
} from './rescueVehicleCodeModal.styles'
import { NewConfigurationButton, AcceptButton, ExportPdfButton } from './RescueVehicleCodeModal'

export type Props = {}

const RescueVehicleCodePriceModal: FunctionComponent<Props> = () => {
    const brand = useCurrentBrand()
    const {isRescue, close, priceChanges} = useModalState()
    const priceChanged = priceChanges != null
    const {modelName} = useMappedConfiguration()
    const {t} = useTranslation()

    return (
        <Modal isVisible={isRescue && priceChanged}>
            <ModalClosingButton onClick={close}/>
            <ChangedPriceContainer>
                <Title>{t(`vehicleCode.${brand}.rescue.title`)}</Title>
                <Subtitle>{modelName}</Subtitle>
                <InfoRow>
                    <ChangedPriceSelection>
                            <InfoRow>
                                <PriceTitle>{t(`vehicleCode.${brand}.rescue.oldPrice`)}</PriceTitle>
                                <PriceValue>{priceChanges.oldPrice}</PriceValue>
                             </InfoRow>
                            <NewPrice>
                                <PriceTitle>{t(`vehicleCode.${brand}.rescue.newPrice`)}</PriceTitle>
                                <PriceValue>{priceChanges.newPrice}</PriceValue>
                            </NewPrice>
                    </ChangedPriceSelection>
                </InfoRow>
                <InfoText>{t(`vehicleCode.${brand}.rescue.priceChanged`)}</InfoText>
                <Footer>
                    <FooterButtonsContainer>
                        <ExportPdfButton />
                        <NewConfigurationButton/>
                        {<AcceptButton/>}
                    </FooterButtonsContainer>
                </Footer>
            </ChangedPriceContainer>
        </Modal>
    )
}

export default RescueVehicleCodePriceModal
