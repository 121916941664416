import { createAction, createAsyncAction } from 'typesafe-actions'
import LeasingMatrix from '../../../entities/LeasingMatrix'
import LeasingRate from '../../../entities/LeasingRate'

export const showLeasingModal = createAction('CMD / Leasing / show modal')()

export const hideLeasingModal = createAction('CMD / Leasing / hide modal')()

export const setLeasingModalIsActiveState = createAction('DOC / Leasing / set modal is active')<boolean>()

export const setLeasingModalShouldBeShownState = createAction('DOC / Leasing / set flag to open modal if no conflict occurred state')<boolean>()

export const fetchLeasingMatrixAsync = createAsyncAction(
    'CMD / Leasing / fetch request',
    'EVT / Leasing / fetch success',
    'EVT / Leasing / fetch failure',
)<undefined, LeasingMatrix, Error>()

export const setLeasingMatrixState = createAction('DOC / Leasing / set matrix')<LeasingMatrix>()

export const postLeasingRateAsync = createAsyncAction(
    'CMD / Leasing / post request',
    'EVT / Leasing / post success',
    'EVT / Leasing / post failure',
)<LeasingRate['id'], LeasingRate, Error>()

export const setConfiguredLeasingRate = createAction('DOC / Leasing / set configured rate')<LeasingRate>()

export const resetLeasingState = createAction('DOC / Leasing / reset state')()
