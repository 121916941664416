import { createReducer } from 'typesafe-actions'
import {
    resetEquipmentInformationState,
    setEquipmentInformationState,
} from '../actions/app/equipmentGroups.actions'
import EquipmentInformation from '../../entities/EquipmentInformation'

export type EquipmentInformationState = EquipmentInformation

export const defaultState: EquipmentInformationState = {
    recommendations: {
        required: false,
        selected: false,
    },
}

const equipmentInformationReducer = createReducer(defaultState)
    .handleAction(setEquipmentInformationState, (state, action) => action.payload ?? null)
    .handleAction(resetEquipmentInformationState, () => defaultState)

export default equipmentInformationReducer
