import { createSelector } from 'reselect'
import leasingSelector from './leasingSelector'
import LeasingRate from '../../../entities/LeasingRate'

const configuredLeasingRateSelector = createSelector(
    leasingSelector,
    (leasing): LeasingRate | null => leasing.configuredRate,
)

export default configuredLeasingRateSelector
