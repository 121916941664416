import React, { FunctionComponent } from 'react'
import Modal from '../modal'
import style from './leasingModal.css'
import LeasingMatrix from '../leasing-matrix'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    isActive: boolean
}

const LeasingModal: FunctionComponent<Props> = (props) => {
    const { isActive } = props
    const { t } = useTranslation()

    if (!isActive) {
        return null
    }

    return (
        <Modal>
            <section className={style.container}>
                <h2>{t('leasing.title')}</h2>
                <h3>
                    {t('leasing.subtitle')}
                    :
                </h3>
                <p>{t('leasing.infoLabel')}</p>
                <LeasingMatrix />
            </section>
        </Modal>
    )
}

export default LeasingModal
