import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import Model, { ModelAttributeId } from '../../entities/Model'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import { Checkbox } from '../form'
import style from './modelsTable.css'
import { Td, Tr } from '../table'
import ModelTableSubAttr from './ModelTableSubAttr'
import { extractEngineAttributes } from '../../redux/middleware/app/helpers/enkvAttributesExtractor'

export type Props = {
    model: Model
    viewAttributes: ModelAttributeId[]
    selectModel: Function
    deselectModel: Function
    openInfoModal: Function
    remarkLabels: Record<string, string>
}

const ModelsTableRow: FunctionComponent<Props> = (props) => {
    const {
        model,
        viewAttributes,
        selectModel,
        deselectModel,
        openInfoModal,
        remarkLabels,
    } = props

    const { t, i18n } = useTranslation()

    const {
        id,
        name,
        price,
        attributes,
        media,
        mbvId,
        selected,
    } = model

    const infoIsForced = media.some((mediaItem) => mediaItem.forced)

    const infoButtonClicked = (): void => {
        openInfoModal({
            title: name,
            media,
            technicalDataConfigurationString: mbvId,
        })
    }

    const changeHandler = (): void => {
        if (selected) {
            deselectModel(id)
        } else {
            const infoModal = infoIsForced ? {
                title: name,
                media,
                technicalDataConfigurationString: mbvId,
            } : undefined

            selectModel(id, { infoModal })
        }
    }

    const attributeCells = viewAttributes ? viewAttributes.map((attributeViewId) => {
        const attributeForView = attributes.find((attribute) => attribute.id === attributeViewId)

        if (!attributeForView) {
            return <Td key={attributeViewId} className={style.attributeCol} />
        }

        const { value, formattedValue, id: attributeId } = attributeForView
        const languageKeyForAttributeValue = `attributes.valueLabels.${attributeId}.${value}`
        const translatedValue = i18n.exists(languageKeyForAttributeValue)
            ? t(languageKeyForAttributeValue)
            : value

        return (
            <Td key={attributeViewId} className={style.attributeCol}>
                {formattedValue || translatedValue}
                {remarkLabels[attributeForView.valueRemarkRefId]}
            </Td>
        )
    }) : []

    const checkboxId = `checkbox-${id}`

    const engineTypeAttribute = attributes.find((attr) => attr.id === 'engine-type')
    const engineAttributes = extractEngineAttributes(engineTypeAttribute?.value as string, attributes)

    return (
        <Tr key={id} className={style.row}>
            <Td className={style.nameCol}>
                <div className={style.nameColInner}>
                    <span>
                        <Checkbox
                            id={checkboxId}
                            name="model"
                            onChange={changeHandler}
                            checked={selected}
                            className={style.checkbox}
                            data-cy={`model-select-${id.replace(/\\/g, '.')}`}
                        />
                    </span>
                    <span>
                        <label className={style.label} htmlFor={checkboxId}>
                            <span>
                                {name}
                            </span>
                            <span className={style.mbvId} title={mbvId}>
                                {mbvId.length > 8 ? mbvId.split('\\').slice(0, 2).join('\\') + '\\' : mbvId}
                            </span>
                        </label>
                        <p className={style.subtext}>
                            {engineAttributes.map((attribute, index) =>
                                <ModelTableSubAttr
                                    attribute={attribute}
                                    engineType={engineTypeAttribute?.value as string}
                                    remarkLabels={remarkLabels}
                                    key={index}/>)}
                        </p>
                    </span>
                </div>
            </Td>
            {attributeCells}
            <Td className={style.priceCol}>
                <div className={style.priceContainer}>
                    <Markup content={price} noWrap />
                </div>
                <InfoButton openModal={infoButtonClicked} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                    <InfoI isForced={infoIsForced} />
                </InfoButton>
            </Td>
        </Tr>
    )
}

export default ModelsTableRow
