import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchLeasingMatrixAsync,
    hideLeasingModal,
    postLeasingRateAsync,
    setConfiguredLeasingRate,
    setLeasingMatrixState,
    setLeasingModalIsActiveState,
    showLeasingModal,
} from '../../actions/app/leasing.actions'
import { apiRequest } from '../../actions/core/api.actions'
import { getChooseLeasingRateUrl, getLeasingMatrixUrl } from '../../../constants/apiUrls'
import { fetchModelsAsync } from '../../actions/app/models.actions'
import configuredCarlineIdSelector from '../../selectors/carlines/configuredCarlineIdSelector'
import selectedCarlineIdSelector from '../../selectors/carlines/selectedCarlineIdSelector'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import equipmentGroupsSelector from '../../selectors/equipments/equipmentGroupsSelector'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import { stringify } from 'query-string'
import carlineSelectConfigMismatchSelector
    from '../../selectors/carlines/carlineSelectConfigMismatchSelector'
import configuredModelIdSelector from '../../selectors/models/configuredModelIdSelector'

const leasingMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(showLeasingModal, action)) {
        dispatch(setLeasingModalIsActiveState(true))
    }

    if (isActionOf(hideLeasingModal, action)) {
        dispatch(setLeasingModalIsActiveState(false))
    }

    if (isActionOf(fetchLeasingMatrixAsync.request, action)) {
        const state = getState()
        const carlineId = selectedCarlineIdSelector(state)
        let modelId: string

        const carlineMismatch = carlineSelectConfigMismatchSelector(state)

        if (!carlineMismatch) {
            modelId = configuredModelIdSelector(state)?.split('-')[0]
        }

        const requestParams = stringify({carlineId, modelId}, {
            skipNull: true,
            encode: false,
        })

        dispatch(apiRequest({
            url: getLeasingMatrixUrl(requestParams),
            method: 'GET',
            successAction: fetchLeasingMatrixAsync.success,
            failureAction: fetchLeasingMatrixAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchLeasingMatrixAsync.success, action)) {
        dispatch(setLeasingMatrixState(action.payload))
    }

    if (isActionOf(fetchLeasingMatrixAsync.failure, action)) {
        dispatch(hideLeasingModal())
    }

    if (isActionOf(postLeasingRateAsync.request, action)) {
        dispatch(apiRequest({
            url: getChooseLeasingRateUrl(action.payload),
            method: 'POST',
            successAction: postLeasingRateAsync.success,
            failureAction: postLeasingRateAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(postLeasingRateAsync.success, action)) {
        const equipmentGroups = equipmentGroupsSelector(getState())
        const carlineId = configuredCarlineIdSelector(getState())

        dispatch(setConfiguredLeasingRate(action.payload))
        dispatch(hideLeasingModal())

        if (equipmentGroups && equipmentGroups.length > 0) {
            dispatch(fetchCartAsync.request(undefined, undefined))
            dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
        }

        dispatch(fetchModelsAsync.request(carlineId))
    }
}

export default leasingMiddleware
