import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { apiRequest } from '../../actions/core/api.actions'
import { fetchCartAsync, setCartState, putCartAsync } from '../../actions/app/cart.actions'
import { getFetchCartApiUrl, getPutCartApiUrl } from '../../../constants/apiUrls'
import configuredCarlineIdSelector from '../../selectors/carlines/configuredCarlineIdSelector'
import { fetchModelsAsync } from '../../actions/app/models.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'

const cartMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchCartAsync.request, action)) {
        dispatch(apiRequest({
            url: getFetchCartApiUrl(),
            method: 'GET',
            successAction: fetchCartAsync.success,
            failureAction: fetchCartAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(fetchCartAsync.success, action)) {
        const cart = action.payload

        dispatch(setCartState(cart))
    }

    if (isActionOf(putCartAsync.request, action)) {
        const priceViewFlag = action.payload

        dispatch(apiRequest({
            url: getPutCartApiUrl(priceViewFlag),
            method: 'PUT',
            successAction: putCartAsync.success,
            failureAction: putCartAsync.failure,
        }, {
            causedBy: action,
        }))
    }

    if (isActionOf(putCartAsync.success, action)) {
        const cart = action.payload
        const carlineId = configuredCarlineIdSelector(getState())

        dispatch(setCartState(cart))
        dispatch(fetchModelsAsync.request(carlineId))
        dispatch(fetchEquipmentGroupsAsync.request(undefined, undefined))
    }
}

export default cartMiddleware
