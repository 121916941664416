import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import TechnicalData from '../../entities/TechnicalData'
import { Table, Td, Tr } from '../table'
import DisclaimerText, { getRemarkLabels } from '../disclaimer-text'
import style from './technicalDataTable.css'
import InfoButton from '../info-button'
import InfoI from '../info-i'

export type Props = {
    technicalData: TechnicalData
    openInfoModal: Function
}

const TechnicalDataTable: FunctionComponent<Props> = (props) => {
    const { technicalData: data, openInfoModal } = props
    const { technicalData, remarks } = data

    if (technicalData.length === 0) {
        return null
    }

    const remarkLabels = getRemarkLabels(remarks)

    const tableElements = technicalData.map((technicalDate) => {
        const dataEntryElements = technicalDate.entry.map((entry) => {
            const { media, name } = entry

            const openModal = (activeMedia: string|null = null): Function => () => openInfoModal({
                title: name,
                activeMedia,
                media,
            })

            const infoIsForced = media && media.find((mediaItem) => mediaItem.forced) !== undefined
            const hasInfo = !!entry.media
            const infoButtonElement = hasInfo ? (
                <InfoButton openModal={openModal()} dataCy={`info-button-${entry.id}`}>
                    <InfoI isForced={infoIsForced} />
                </InfoButton>
            ) : null

            return (
                <Tr key={entry.id}>
                    <Td className={style.nameCol}>
                        <div className={style.nameContainer}>
                            <Markup content={entry.name} tagName="span" />
                            {' '}
                            {remarkLabels[entry.nameRemarkRefId]}
                            {' '}
                            <span className={style.infoButtonWrapper}>
                                {infoButtonElement}
                            </span>
                        </div>
                    </Td>
                    <Td className={style.valueCol}>
                        <Markup content={entry.value} />
                        {' '}
                        {remarkLabels[entry.valueRemarkRefId]}
                    </Td>
                </Tr>
            )
        })

        return (
            <section key={technicalDate.id}>
                <div className={style.headingWrapper}>
                    <h3 className={style.title}>
                        {technicalDate.headline}
                    </h3>
                </div>
                <Table className={style.table}>
                    <tbody>
                        {dataEntryElements}
                    </tbody>
                </Table>
            </section>
        )
    })

    return (
        <>
            {tableElements}
            <DisclaimerText remarks={remarks} />
        </>
    )
}

export default TechnicalDataTable
