import config from '../config'

import { Api } from './Api'

export * from './Api'

const { API_URL } = config

export const apiClient = new Api({
    baseApiParams: { credentials: 'include' },
    baseUrl: `${API_URL}/api/v1`,
})
